


















interface BlockDef {
  width: string;
  color: string;
  text: string;
  textColor: string;
  unit?: string;
  tooltip?: string;
}

import { roundNumber } from "@/DataHelper";
import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import { Globals } from "@/Globals";
@Component
export default class RelativeDestinationChart extends Vue {
  @Prop({ required: true }) public value!: number;
  @Prop({ required: true }) public destination!: number;
  @Prop({ default: 30 }) public height!: number;
  @Prop({ default: "red" }) public overColor!: string;
  @Prop({ default: "grey" }) public underColor!: string;
  @Prop({ default: "lightgrey" }) public remainingColor!: string;
  @Prop({ default: "white" }) public textColor!: string;
  @Prop({ default: "black" }) public maxTextColor!: string;
  @Prop({ default: "h" }) public unit!: string;
  @Prop({ default: "" }) public tooltip!: string;
  @Prop({ default: "transparent" }) public maxTextBackground!: string;
  @Prop({ default: 20 }) public textWithPercent!: number;
  @Prop({ default: "" }) public textValue!: string;
  @Prop({ default: "" }) public textDest!: string;
  @Prop({ default: "" }) public textDiff!: string;

  private blocks: BlockDef[] = [];

  private percentValue: number = 30;
  private text: string = "100";

  private oldValue = 0;
  private oldDest = 0;
  private textIst: string = this.$globalsBezeichnung(Globals.Begriff, "Ist", "Ist");
  private textSoll: string = this.$globalsBezeichnung(Globals.Begriff, "Soll", "Soll");
  private textMehr: string = this.$globalsBezeichnung(
    Globals.Begriff,
    "Web_Mehrleistung",
    "Mehrleistung"
  );
  private textoffen: string = this.$globalsBezeichnung(
    Globals.Begriff,
    "Web_offen",
    "offen"
  );

  public mounted() {
    this.refreshValues();
  }

  public getStyle(block: BlockDef) {
    return {
      width: block.width,
      background: this.getBackground(block),
      height: this.height,
      color: block.textColor,
    };
  }

  public getBackground(block: BlockDef) {
    if (block.color === this.maxTextBackground) {
      return block.color;
    }
    return (
      // "repeating-linear-gradient(45deg,rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2) 10px,
      // rgba(0, 0, 0, 0.3) 10px,rgba(0, 0, 0, 0.3) 20px)," +
      block.color
    );
  }

  @Watch("value")
  @Watch("destination")
  public refreshValues() {
    // nur bei Veränderung verwenden
    if (
      this.value === this.oldValue &&
      this.destination === this.oldDest &&
      this.blocks.length > 0
    ) {
      return;
    }
    this.oldValue = this.value;
    this.oldDest = this.destination;
    let val = this.value;
    let dest = this.destination;
    if (!val) {
      val = 0;
    }
    if (!dest) {
      const text = this.$begriffBezeichnung(
        "Web_keineDaten_gefunden",
        "keine Daten gefunden"
      );
      this.blocks = [
        {
          width: "100%",
          text: "..." + text + "...",
          color: this.maxTextBackground,
          textColor: this.maxTextColor,
        },
      ];
      return;
    }
    if (this.textDiff) {
      this.textMehr = this.textDiff;
    }
    if (this.textValue) {
      this.textIst = this.textValue;
    }
    if (this.textDest) {
      this.textSoll = this.textDest;
    }
    // bindings kommen oft trotzdem als string daher !!!
    if (typeof val === "string") {
      val = parseFloat(val);
    }
    if (typeof dest === "string") {
      dest = parseFloat(dest);
    } else {
      dest = roundNumber(dest, 2);
    }
    if (val >= dest) {
      const delta = roundNumber(val - dest, 2);
      const maxVal = val;
      // val = val - delta;
      const maxPercent = 100 - this.textWithPercent;
      let percent1 = (dest / maxVal) * maxPercent;
      percent1 = Math.round(percent1);
      let percent2 = (delta / maxVal) * maxPercent;
      percent2 = Math.round(percent2);
      if (percent2 < 8) {
        if (percent2 !== 0) {
          percent2 = 8;
          percent1 = percent1 - 8;
        }
      } else if (percent1 === 0 && dest > 0) {
        percent1 = 10;
        percent2 = percent2 - 10;
      }
      if (val === dest) {
        this.blocks = [
          {
            width: percent1 + "%",
            text: "" + roundNumber(val, 2),
            color: this.underColor,
            textColor: this.textColor,
            unit: this.unit,
            tooltip: this.textIst,
          },
          {
            width: this.textWithPercent + "%",
            text: "" + roundNumber(dest, 2),
            color: this.maxTextBackground,
            textColor: this.maxTextColor,
            unit: this.unit,
            tooltip: this.textSoll,
          },
        ];
      } else {
        this.blocks = [
          {
            width: percent1 + "%",
            text: "" + roundNumber(val, 2),
            color: this.underColor,
            textColor: this.textColor,
            unit: this.unit,
            tooltip: this.textIst,
          },
          {
            width: percent2 + "%",
            text: "" + roundNumber(delta, 2),
            color: this.overColor,
            textColor: this.textColor,
            unit: this.unit,
            tooltip: this.textMehr,
          },
          {
            width: this.textWithPercent + "%",
            text: "" + roundNumber(dest, 2),
            color: this.maxTextBackground,
            textColor: this.maxTextColor,
            unit: this.unit,
            tooltip: this.textSoll,
          },
        ];
      }
    } else {
      const delta = dest - val;
      const maxVal = dest;
      const maxPercent = 100 - this.textWithPercent;

      let percent1 = (val / maxVal) * maxPercent;
      percent1 = Math.round(percent1);
      let percent2 = (delta / maxVal) * maxPercent;
      percent2 = Math.round(percent2);
      if (percent2 < 8) {
        if (percent2 !== 0) {
          percent2 = 8;
          percent1 = percent1 - 8;
        }
      }
      if (percent1 < 8) {
        if (percent1 !== 0) {
          percent1 = 8;
          percent2 = percent2 - 8;
        }
      }
      if (!percent1) {
        // ist der Wert leer oder undefined - dann auch den Block nicht setzen
        // da sonst die Höhe falsch ist!
        this.blocks = [
          {
            width: percent2 + "%",
            text: "" + roundNumber(delta, 2),
            color: this.remainingColor,
            textColor: this.textColor,
            unit: this.unit,
            tooltip: this.textoffen,
          },
          {
            width: this.textWithPercent + "%",
            text: "" + roundNumber(dest, 2),
            color: this.maxTextBackground,
            textColor: this.maxTextColor,
            unit: this.unit,
            tooltip: this.textSoll,
          },
        ];
      } else {
        this.blocks = [
          {
            width: percent1 + "%",
            text: "" + roundNumber(val, 2),
            color: this.underColor,
            textColor: this.textColor,
            unit: this.unit,
            tooltip: this.textIst,
          },
          {
            width: percent2 + "%",
            text: "" + roundNumber(delta, 2),
            color: this.remainingColor,
            textColor: this.textColor,
            unit: this.unit,
            tooltip: this.textoffen,
          },
          {
            width: this.textWithPercent + "%",
            text: "" + roundNumber(dest, 2),
            color: this.maxTextBackground,
            textColor: this.maxTextColor,
            unit: this.unit,
            tooltip: this.textSoll,
          },
        ];
      }
    }
  }
}
