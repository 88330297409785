























































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";

import { getKennungValueDisplay } from "@/DataHelper";

@Component
export default class WorkflowSaldenAntrag extends EditPage {
  @Prop({ default: null }) protected zeitbereichUebergabe!: APIZeitbereich | null;
  // protected zeitbereichUebergabe: any;
  private dateMitarbeiter: Date | null = null;
  private dateVorgesetzter: Date | null = null;
  private textVorgesetzter: string = "";
  private iconAmpel: string = "";
  constructor() {
    super();

    this.query = "WEB_ErfassungMemo_Freigabe";
    this.showLoading = true;
  }
  public created() {
    this.zeitbereich = this.zeitbereichUebergabe;
  }
  protected initialize() {
    this.dateMitarbeiter = getKennungValueDisplay(
      this.data,
      "ZE_FREIGABE_MA",
      this.columns
    );
    this.dateVorgesetzter = getKennungValueDisplay(
      this.data,
      "ZE_FREIGABE_ABLEHNUN",
      this.columns
    );

    this.textVorgesetzter = getKennungValueDisplay(
      this.data,
      "MEMO_VORGESETZTER",
      this.columns
    );
    this.iconAmpel = getKennungValueDisplay(this.data, "ampel", this.columns);
  }
}
