





import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";

@Component
export default class WebPZEalleWarnungen extends EditPage {
  // @Prop({ default: null }) public zeitbereich!: APIZeitbereich | null;
  public mounted() {
    this.mountedBase();
    console.log("showing zeitbereich:" + JSON.stringify(this.zeitbereich));
  }
}
