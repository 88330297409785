<template>
  <div v-if="showMutable" class="wrap">
    <label>{{description}}</label>
    <color-picker v-model="colors" @input="updateValue"/>
  </div>
</template>
<script>
import { Chrome } from "vue-color";
export default {
  components: {
    // 'material-picker':  material,
    // 'compact-picker':   compact,
    // 'swatches-picker':  swatches,
    // 'slider-picker':    slider,
    // 'sketch-picker':    sketch,
    // 'chrome-picker':    chrome,
    "color-picker": Chrome
  },
  props: ["initValue", "column", "description"],

  data() {
    return {
      data: this.initValue,
      colors: {
        hex: "#194d33",
        hsl: { h: 150, s: 0.5, l: 0.2, a: 1 },
        hsv: { h: 150, s: 0.66, v: 0.3, a: 1 },
        rgba: { r: 25, g: 77, b: 51, a: 1 },
        a: 1
      }
    };
  },
  methods: {
    updateValue(c) {
      try {
        const e = c.hex;
        this.$emit("change", e);
        this.$emit("input", e);
        this.$emit("seteditvalue", { value: e, field: this.column });
      } catch (e) {
        console.error(
          "Error in field '" + this.description + "' on valueChanged:" + e
        );
      }
    }
  }
};
</script>
<style scoped>
</style>
