// base query component zum standardisierten
import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import AUApi from "@/api";
import ChartBase from "./ChartBase";

export default abstract class BaseQueryChart extends ChartBase {
    @Prop() public siteData: any;
    protected showLoading: boolean = true;

    protected refreshing = false;
    public async refresh() {
        this.showLoading = true;
        if (this.refreshing) { return; }
        this.refreshing = true;
        let currentZeitbereich = this.$api.zeitbereich;
        const zeitbereichVon = this.$api.zeitbereich.von;
        const zeitbereichBis = this.$api.zeitbereich.bis;
        let bereich = "Monat";
        let vonDate = new Date(zeitbereichVon);
        let bisDate = new Date(new Date(zeitbereichBis).lastInMonth());
        currentZeitbereich = {
            bereich,
            von: vonDate,
            bis: bisDate,
        };
        if (this.params.abwZeitbereich) {
            if (bereich === "Woche") {
                bereich = this.params.abwZeitbereich;
                const heute = new Date();
                vonDate = new Date(heute.firstOfWeek());
                bisDate = vonDate.addDays(+7);
            }
        }
        currentZeitbereich = {
            bereich,
            von: vonDate,
            bis: bisDate,
        };
        try {
            if (this.query) {
                const r = await AUApi.Current.getQuery(
                    this.query,
                    undefined,
                    this.aic ?? null,
                    currentZeitbereich ?? null,
                    0,
                    false,
                    this.bewegungsdaten
                );
                this.dataLoaded(r.data);
            } else if (this.queryData) {
                this.dataLoaded(this.queryData);
            } else if (this.siteData) {
                if (this.siteData.queryData) {
                    this.dataLoaded(this.siteData.queryData);
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.refreshing = false;
            this.showLoading = false;
        }
    }

    protected dataLoadedBase(data: QueryResponse) {
        this.lastQueryResponse = data;
        this.dataLoaded(data);
    }

    // implement this to show the data in the UI...
    protected abstract dataLoaded(data: QueryResponse): void;

    protected QvAICsChanged(): void {
        // nothing to do here
    }
}
