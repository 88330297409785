







import { Vue, Component, Prop, Provide } from "vue-property-decorator";
@Component({
  provide: {}
})
export default class StammAuftrag extends Vue {
  public query: string | undefined = "Web_SttAuftrag_Hauot"; // per default nicht initialisiert ;)
}
