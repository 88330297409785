














































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import {
  getKennungValueDisplay,
  getKennungTitle,
  getKennungValue
} from "@/DataHelper";

@Component
export default class TagesSaldenTimer extends EditPage {
  @Prop({}) protected zeitbereichUebergabe: any;
  @Prop({ default: false }) public showClose: boolean = false;
  constructor() {
    super();
    this.query = "ZE_ErfassungTimer_sortiert";
    this.showLoading = true;
  }
  private deleteVisible: boolean = false;
  private saveVisible: boolean = true;
  private showCloseMutable: boolean = false;
  private noData: boolean = false;

  public created() {
    this.zeitbereich = this.zeitbereichUebergabe;
    this.showCloseMutable = this.showClose;
    if (!this.data) {
      this.noData = true;
    }
  }
  public bewDelete() {
    if (this.data && this.data.aic_Bew_pool) {
      this.onDelete(this.data.aic_Bew_pool, "", this.zeitbereich);
      this.$emit("back");
    }
  }
  public save() {
    this.onSave(this.data, "");
  }
  protected initialize() {
    if (this.data && this.data.aic_Bew_pool) {
      // this.deleteVisible = true;
    }
  }
}
