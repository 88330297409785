

















































































































import { Component, Prop } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { getMonthNames } from "@/editor/CalenderHelper";
import {
  getKZValue,
  getKZValueDisplay,
  getKZTitle,
  getKennungValue,
  getKZColumn,
} from "@/DataHelper";

@Component
export default class DialogDienstplanVG extends EditPage {
  public allColumns: DataColumn[] = [];
  private saveVisible: boolean = true;
  private showGanztag: boolean = false;
  private showAbwesenheit: boolean = false;
  private disableSwitch: boolean = false;
  private value1?: Date;
  private value2?: Date;
  private tag!: number;
  private monat: string = "-";
  constructor() {
    super();
    this.query = "Web_TE_TGM_Editierbar";
    this.showLoading = true;
  }
  public created() {
    if (this.siteData.abwesenheit) {
      this.showAbwesenheit = true;
    }
    if (this.siteData.selectedTGM) {
      this.selected = this.siteData.selectedTGM;
    }
    if (this.siteData.readOnlyMutable) {
      this.saveVisible = false;
      this.showGanztag = false;
    }
    if (this.siteData.tag) {
      this.zeitbereich = {
        von: this.siteData.tag,
        bis: this.siteData.tag,
        bereich: "Tag",
      };
    } else {
      if (this.siteData.zeitbereich?.von) {
        const von = this.siteData.zeitbereich.von;
        this.tag = von.getDate();
        this.monat = getMonthNames()[von.getMonth()];
      }
    }
  }
  public get showDelete() {
    return (
      this.data &&
      this.data.aic_Bew_pool &&
      this.data.aic_Bew_pool > 0 &&
      !this.siteData.readOnlyMutable
    );
  }
  public save() {
    const me = this;
    this.onSave(this.data, "")?.then(() => {
      this.$nextTick(() => me.$emit("save"));
    });
  }
  public onDelete() {
    if (this.data && this.data.aic_Bew_pool) {
      this.$emit("delete", this.data.aic_Bew_pool);
    }
  }

  private setVonBis(selected: any) {
    if (selected) {
      this.initialized = false;
      const kennung = "vb";
      const col = this.columns.find((c: any) => c.kennung === kennung);
      const val = getKennungValue(selected.row, kennung, selected.columns);
      this.value1 = val;
      if (col) {
        this.data[col.name] = this.value1;
      }
      const kennung1 = "vb1";
      const col1 = this.columns.find((c: any) => c.kennung === kennung1);
      const val1 = getKennungValue(selected.row, kennung1, selected.columns);
      this.value2 = val1;
      if (col) {
        this.data[col.name] = this.value1;
      }
      this.$emit("setTGM", selected);
      this.$nextTick(() => (this.initialized = true));
      this.$forceUpdate();
    }
  }
}
