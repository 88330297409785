



import AUApi from "../api";
import { getConfigDataString, readConfigDataString } from "@/ConfigQRCodeHelper";
import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { scanBarcode, BarcodeScanResult } from "@/BarcodeScanWrapper";

import { Capacitor } from "@capacitor/core";

declare var ALLConfig: ALLConfigType;

@Component
export default class ALLConfigurator extends Vue {
  private callback: any;

  private showToast(msg: string) {
    Capacitor.Plugins?.Toast.show({ text: msg });
  }
  private showError(error: string) {
    this.$emit("error", "Error: " + error);
  }

  public async scanQrCode() {
    try {
      const data = await scanBarcode(); // start scanning and wait for a result
      this.$emit("error", ""); // remove old errors

      if (data.cancelled) {
        this.showError("cancelled");
        return;
      }

      if (data.content) {
        const newConfig: ALLConfigType = this.readQrData(data);

        if (!newConfig || !newConfig.rootUrl || (!newConfig.dB && !newConfig.DBs)) {
          this.showError("Error: invalid code" + newConfig);
        } else {
          this.$emit("success", "Scan erfolgreich/ Scan successful!");
          // Meldung ausgeben das es geklappt hat...
        }
        newConfig.initialized = true;
        const me = this;
        this.$api
          .saveAllConfig(newConfig)
          .then(() => {
            me.$emit("newConfig");
          })
          .catch(me.showError);
      }
    } catch (error: any) {
      this.showError(error);
    }
  }

  private readQrData(data: BarcodeScanResult) {
    let newConfig: ALLConfigType;
    if (data.content!.startsWith("{") && data.content!.endsWith("}")) {
      newConfig = JSON.parse(data.content!);
    } else {
      newConfig = readConfigDataString(data.content!);
    }
    return newConfig;
  }
}
