









































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditDatePicker from "@/editor/EditDatePicker.vue";
import EditComponent from "@/editor/EditComponent";

@Component
export default class EditVonBis extends EditDatePicker {
  protected data: any = {
    dauer: 0,
    faktor: 3600,
    kz: "h",
    von: null,
    bis: null
  };
  protected buttonText: string = "--";
  protected von: any = null;
  protected bis: any = null;
  protected initialized: any = false;

  public mounted() {
    if (this.data.von) {
      this.von = this.data.von;
    }
    if (this.data.bis) {
      this.bis = this.data.bis;
    }
    // this.buttonText = this.getDisplayValue();
    this.initialized = true;
  }

  // protected getDisplayValue() {
  //     return Common.getDisplayValue(this.data, this.column);
  //   }
  protected onChangeVon(e: any) {
    this.data.von = e;
    this.onChange(this.data);
  }
  protected onChangeBis(e: any) {
    this.data.bis = e;
    this.onChange(this.data);
  }

  protected onChange(e: any) {

    if (this.data.von === "1970-01-01T00:00:00+01:00") {
      this.data.von = null;
      this.von = null;
    }
    if (this.data.bis === "1970-01-01T00:00:00+01:00") {
      this.data.bis = null;
      this.bis = null;
    }
    const data = this.data;
    if (data.von && data.bis) {
      const dauerms: any = new Date(); // new Date(data.bis) - new Date(data.von); // milisekunden ausrichten...
      data.dauer = dauerms / 1000; // in sekunden rechnen ma ;)
    } else {
      data.dauer = null; // da den "nullwert setzen..."
    }

    if (!this.data.factor && !this.data.kz) {
      this.data.factor = 3600;
      this.data.kz = "h";
    }
  }
}
