/* tslint:disable:no-bitwise */
function stringToUtf8ByteArray(str: string) {
    // TODO(user): Use native implementations if/when available
    const out = [];
    let p = 0;
    for (let i = 0; i < str.length; i++) {
        let c = str.charCodeAt(i);
        if (c < 128) {
            out[p++] = c;
        } else if (c < 2048) {
            out[p++] = (c >> 6) | 192;
            out[p++] = (c & 63) | 128;
        } else if (
            ((c & 0xFC00) === 0xD800) && (i + 1) < str.length &&
            ((str.charCodeAt(i + 1) & 0xFC00) === 0xDC00)) {
            // Surrogate Pair
            c = 0x10000 + ((c & 0x03FF) << 10) + (str.charCodeAt(++i) & 0x03FF);
            out[p++] = (c >> 18) | 240;
            out[p++] = ((c >> 12) & 63) | 128;
            out[p++] = ((c >> 6) & 63) | 128;
            out[p++] = (c & 63) | 128;
        } else {
            out[p++] = (c >> 12) | 224;
            out[p++] = ((c >> 6) & 63) | 128;
            out[p++] = (c & 63) | 128;
        }
    }
    return out;
}

/*
sample:
byte[] b = s.getBytes();
byte[] b2 = "Zweistein".getBytes();
for (int i = 0; i < b.length; i++)
{
    int i2 = b[i] ^ b2[i % 9];
    sNeu += new String(new byte[] {(byte)(i2 / 16 + 65), (byte)(i2 % 16 + 75)});
}
*/

export function encryptForAD(pwd: string) {
    let sNeu = "";
    const b = stringToUtf8ByteArray(pwd);
    const b2 = stringToUtf8ByteArray("Zweistein");

    for (let i = 0; i < b.length; i++) {
        const i2 = b[i] ^ b2[i % 9];
        const char = String.fromCharCode((i2 / 16 + 65));
        const char2 = String.fromCharCode((i2 % 16 + 75));
        sNeu += char + '' + char2;
    }
    return sNeu;
}

/* tslint:enable:no-bitwise */
