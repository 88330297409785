









































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { AxiosResponse } from "axios";
import { createDownload, query2CSV } from "@/DataHelper";
import { showDialog } from "@/UIHelper";
import { tab } from "@syncfusion/ej2-vue-grids";

@Component
export default class ApiAufruf extends EditPage {
  private apiAbfragen: any[] = [];
  private separators: Array<{ label: string; sep: string }> = [
    { label: "; Strichpunkt", sep: ";" },
    { label: ", Komma", sep: "," },
    { label: "Tabstopp", sep: "\t" },
  ];
  private separator: string = ";";
  @Prop() protected title: string = "API - Connect";
  private selectedAPI: any = {
    aic: null,
    bezeichnung: this.$globalsBezeichnung(
      this.Globals.Begriff,
      "Web_Bitte_waehlen",
      "Bitte wählen"
    ),
  };
  private date: string = new Date().toISOString().substr(0, 7);
  private pickerMulti: string[] = [new Date().toISOString().substr(0, 7)];
  private landscape: boolean = true;
  private startVal: any = this.$api.zeitbereich.von;
  private endVal: any = this.$api.zeitbereich.bis;
  private maxDate: string = "";
  private multipleSelect: boolean = false;
  @Prop() protected type: string = "date";
  private file: File | null = null;
  @Prop() protected exportAbfragen!: AbfrageItems[];
  public mounted() {
    const me = this;
    this.showLoading = true;
    if (this.exportAbfragen?.length > 0) {
      me.apiAbfragen = this.exportAbfragen;
      this.showLoading = false;
    } else {
      me.$api.getapiAbfragen(0).then((x: any) => {
        if (x.data) {
          me.apiAbfragen = x.data;
        }
        this.showLoading = false;
      });
    }
  }
  private downloadJson(fromDialog: boolean = false, tabMDialog?: any[]) {
    // wie speicher ich eine JSON datei?
    const me = this;
    let currentZeitbereich = this.$api.zeitbereich;
    if (this.pickerMulti?.length > 0) {
      const vals = this.pickerMulti.map((d) => new Date(d).valueOf());
      const von = new Date(Math.min(...vals));
      const bis = new Date(Math.max(...vals)).lastInMonth();
      let bereich = "Tag";
      if (this.type === "month") {
        bereich = "Monat";
      } else if (this.type === "year") {
        bereich = "Jahr";
      }
      currentZeitbereich = {
        bereich,
        von,
        bis,
      };
    }

    if (!currentZeitbereich) {
      const zeitbereichVon = this.$api.zeitbereich.von;
      const zeitbereichBis = this.$api.zeitbereich.bis;
      let bereich = "Tag";
      if (this.type === "month") {
        bereich = "Monat";
      } else if (this.type === "year") {
        bereich = "Jahr";
      }
      const vonDate = new Date(zeitbereichVon);
      const bisDate = new Date(new Date(zeitbereichBis).lastInMonth());
      currentZeitbereich = {
        bereich,
        von: vonDate,
        bis: bisDate,
      };
    }
    const modell: ALLModell = this.selectedAPI.modell;
    let tabUbergabe: any[] = [];
    if (tabMDialog) {
      tabUbergabe = tabMDialog;
    }
    if (modell && modell.abfrage && modell.MDialog && fromDialog !== true) {
      showDialog({
        title: "Modelldialog",
        width: 250,
        site: "ModellDialog",
        titleColor: "dvhBackground",
        titleClass: "white--text",
        data: {
          currentZeitbereich,
          query: modell.abfrage,
        },
        okButton: true,
        onClose: (x: any) => {
          if (x.returnedData.length > 0) {
            tabUbergabe = x.returnedData;
          }
          if (x.dialogResult === true) {
            // daten holen aufrufen!
            this.getJSON(currentZeitbereich, tabUbergabe);
          }
          return true;
        },
      });
      return;
    } else {
      this.getJSON(currentZeitbereich);
    }
    this.getJSON(currentZeitbereich);
    // this.$api.getQuery(
    //   this.selectedAPI.kennung,
    //   // Done function
    //   (data: any) => me.dataLoaded(data),
    //   this.queryAIC,
    //   currentZeitbereich,
    //   0,
    //   false,
    //   false,
    //   "0",
    //   0,
    //   [],
    //   "",
    //   false,
    //   this.selectedAPI.aic
    // );
  }
  private getJSON(currentZeitbereich: any, tabUebergabe?: any) {
    const me = this;
    // ModellDialog Übergabe einbauen....
    this.$api.getQuery(
      this.selectedAPI.kennung,
      // Done function
      (data: any) => me.dataLoaded(data),
      this.queryAIC,
      currentZeitbereich,
      0,
      false,
      false,
      "0",
      0,
      this.vecStamm,
      "",
      false,
      this.selectedAPI.aic,
      tabUebergabe
    );
  }
  protected dataLoaded(data: AxiosResponse<QueryResponse>) {
    if (data.data.error !== undefined) {
      this.$api.onfail(data.data.error);
      console.log(data.data.error);
      alert("ACHTUNG: " + data.data.error);
      this.$emit("back");
      return;
    }

    const queryResponse = data.data;
    const csv = query2CSV(queryResponse, this.separator);
    createDownload(csv, "text/csv", queryResponse.header.bezeichnung ?? "download.csv");
  }
  private selectedAPIChanged(args: any) {
    const selectAPI: any = this.apiAbfragen.find((m: any) => m.bezeichnung === args);
    this.selectedAPI = selectAPI;
  }
  private valueChanged(args: any) {
    if (!args) {
      return;
    }
    let bereich = "Tag";
    if (this.type === "month") {
      bereich = "Monat";
    } else if (this.type === "year") {
      bereich = "Jahr";
    }
    const vonDate = new Date(args);
    const bisDate = new Date(new Date(vonDate).lastInMonth());

    this.zeitbereich = {
      bereich,
      von: vonDate,
      bis: bisDate,
    };
  }
}
