


















import { Vue, Component, Prop } from "vue-property-decorator";
import BaseSyncSchedule from "../baseSyncSchedule.vue";
import Constants from "@/Constants";

@Component
export default class CellTemplate extends Vue {
  @Prop() public row!: any;
  @Prop() public schedule!: BaseSyncSchedule;
  @Prop() public color: string = "black";
  private colorSet: string = this.color;
  private dienstplan: boolean = false;
  private text: string = "";
  private memo: string = "";

  public mounted() {
    this.colorSet = this.$data.PrimaryColor;
    this.dienstplan = this.$data.data.dienstplan;
    if (this.dienstplan) {
      if (this.$data.data.Location && !this.$data.data.abwesenheit) {
        this.text = this.$data.data.LocationText + ": " + this.$data.data.Location;
      }
      // this.memo = this.$data.data.Description;
    }
    // this.colorSet = this.$data.PrimaryColor;
  }

  public created() {
    // fix initialisation if icons
    const app = Constants.App;
    if (app) {
      (this.$vuetify as any).icons = (app as any).$vuetify.icons;
    }
  }
}
