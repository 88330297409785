import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { Globals } from "@/Globals";

@Component
export default class ContainerPage extends Vue {
  protected query!: string | undefined;
  @Prop({ default: null }) protected lastValues: any;
  @Prop() protected aic!: number;
  @Prop({ default: null }) protected zeitbereich!: APIZeitbereich | null;
  @Prop({ default: true }) protected backOnSave!: boolean;
  @Prop({ default: null }) public siteData: any;
}
