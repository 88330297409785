
















import { Vue, Component, Prop, Provide } from "vue-property-decorator";
@Component
export default class Buchungen extends Vue {
  @Prop() public aic: any;
  @Prop() public goDate!: Date;
  @Prop() public siteData: any;
  private initalized: boolean = false;

  public mounted() {
    if (this.siteData) {
      if (this.siteData.goDate) {
        this.goDate = this.siteData.goDate;
      }
    }
    this.initalized = true;
  }
  public onRefresh() {
    (this.$refs.timelines as any).onRefresh();
  }
}
