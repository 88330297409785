













































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { getDisplayValue } from "@/DataHelper";
import { showSnackbar } from "@/UIHelper";

@Component
export default class VCardDialog extends Vue {
  @Prop() protected query!: string; // Abfrage für Dialog Darstellung
  @Prop() private text!: string;
  @Prop() private text1!: string;
  @Prop() private textQuery!: string; // um einen Wert aus der DB an den text anzuhängen
  @Prop() private textKennung!: string; // Kennung aus Query result zum anzeigen
  @Prop() private text1Kennung!: string;
  @Prop() private icon!: string; // Bildübergabe
  @Prop() private textIcon!: string; // Text zum Button für DialogMaske
  @Prop() private template!: string;
  @Prop() private useTemplate!: boolean;
  @Prop() private useList!: boolean;
  @Prop({ default: false }) private useTimeLine!: boolean;
  @Prop({ default: null }) protected zeitbereich!: APIZeitbereich | null;
  @Prop() protected aic!: number;
  private showLoading: boolean = false;

  private textValue: string = "";
  private textValue1: string = "";

  private days: Date[] = [];
  private daysinPage = 3;
  private pages = 1;

  private dialog: boolean = false;
  public mounted() {
    try {
      this.showLoading = true;
      if (this.textQuery && this.textQuery.trim() !== "") {
        const vecStamm: any[] = [];
        this.$api.getQuery(
          this.textQuery,
          this.dataLoaded,
          this.aic,
          this.zeitbereich,
          0,
          false,
          false,
          "",
          0,
          vecStamm
        );
      }
      this.showLoading = false;
    } catch (e: any) {
      console.error("mounterror: " + e, e?.stack);
    }
  }

  public onRefresh() {
    const comp = this.$refs.contentComponent as any;
    if (comp.onRefresh) {
      comp.onRefresh();
    }
  }

  public dataLoaded(resp: any) {
    if (resp.data.error) {
      showSnackbar({
        text: "Error: " + resp.data.error,
        color: "error",
      });
      return;
    }

    const column = resp.data.columns.find(
      (c: any) => c.kennung === this.textKennung
    );
    const data = resp.data.data;
    if (data.length > 0) {
      const value = resp.data.data[0][column.name];
      if (value) {
        this.textValue = getDisplayValue(value, column);
      } else {
        this.textValue = "-";
      }
      if (this.text1Kennung) {
        const column1 = resp.data.columns.find(
          (c: any) => c.kennung === this.text1Kennung
        );
        const value1 = resp.data.data[0][column1.name];
        if (value1) {
          this.textValue1 = getDisplayValue(value1, column1);
        } else {
          this.textValue1 = "-";
        }
      }
    }
  }
  public back() {
    this.$emit("back");
  }
}
