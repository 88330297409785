
















































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import AUApi from "../api";
import QRCode from "QRCode.vue";
import { getConfigDataString } from "../ConfigQRCodeHelper";
import Constants from "../Constants";
import { downloadNativeUpdate } from "@/NativeUpdateProvider";

@Component
export default class AppConnect extends Vue {
  private android: boolean = false;
  private ios: boolean = false;
  public get qrCodeContent() {
    return getConfigDataString();
  }
  public mounted() {
    if (Constants.isMobile && Constants.platformAndroid) {
      this.android = true;
    }
  }
  private onOpen() {
    const url = this.$api.user.url + "/downloads/app-release.apk";
    window.open(url, "_blank");
  }
  // private onOpenIOS() {
  //   const url = this.$api.user.url + "/downloads/App.ipa";
  //   window.open(url, "_blank");
  // }
  // private startUpdate() {
  //   downloadNativeUpdate("./update.zip");
  // }
}
