
































































































































































































































































































import EditPage from "@/components/EditPage";
import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
@Component({
  provide: {},
})
export default class StammZeitmodell extends EditPage {
  constructor() {
    super();
    this.query = "Web_StammdatenZeitmodell";
    this.bewegungsdaten = false;
    this.showLoading = true;
  }
  private showBackdrop: boolean = true;
  private showDialog: boolean = false;
  private initalized: boolean = false;
  private items: any[] = [];
  private sttVon: number = 0;
  private sttBis: number = 0;
  private aicRolle: number = 0;
  public mounted() {
    if (this.siteData && this.siteData?.aic !== 0) {
      this.selected = this.siteData.aic;
      this.siteData.aic = 0;
    }
    this.dialogStt.query = "Web_StammdatenZeitmodell";
    this.items.push({
      text: "Allgemein",
      icon: "supervised_user_circle",
      color: "primary",
      step: 1,
    });
    if (this.checkBegriff("Zeitrahmen_Arbeitszeit")) {
      this.items.push({
        text: "Schicht",
        icon: "home",
        color: "success",
        step: 3,
      });
    }
    if (this.checkBegriff("Lizenz_Fehlzeiten")) {
      this.items.push({
        text: "Fehlzeiten",
        icon: "savings",
        color: "primary",
        step: 4,
      });
    }
    if (this.checkBegriff("Web_StammMA_PZE_Parameter")) {
      this.items.push({
        text: "Zeiterfassung",
        icon: "schedule",
        color: "warning",
        step: 5,
        subsites: [
          {
            text: "vor. Austritt",
            icon: "free_cancellation",
            color: "warning",
            step: 6,
          },
        ],
      });
    }
  }
  public initialize() {
    this.sttVon = this.header.aicStt;
    this.sttBis = this.header.aicStt;
    this.aicRolle = this.header.aicRolle;
    this.initalized = true;
  }
  private group: any = null;
  @Watch("group")
  private groupWatch(val: boolean) {
    if (!val) {
      this.drawer = false;
    }
  }
  protected backdrop() {
    const backdropBtn = this.$refs.backdropBtn as any;
    if (backdropBtn.$el.classList.contains("e-active")) {
      backdropBtn.$el.textContent = "True";
      // enable the backdrop property
      this.showBackdrop = false;
    } else {
      backdropBtn.textContent = "False";
      // disable the backdrop property
      this.showBackdrop = true;
    }
  }
  private onBack() {
    this.dialogOpen = false;
  }
  private onDialogClose() {
    this.reloadData();
    this.dialogOpen = false;
  }
  private valueChangedHierarchie(x: any) {
    this.edit = false;
    this.showData = true;
    this.queryAIC = x[0];
    this.selected = x[0];
    if (this.siteData && this.siteData?.aic !== 0) {
      this.siteData.aic = 0;
    }
    this.mountedBase();
  }
}
