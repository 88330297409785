

































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import EditComponent from "@/editor/EditComponent";
import { TimeEntry } from "./TimePicker.vue";
import { RenderDayCellEventArgs } from "@syncfusion/ej2-calendars";
import {
  getDateFromInput,
  getMask,
  highlightDays,
  valueToFormatStr,
  getHolidayColorVuetify,
} from "./CalenderHelper";
import { findSperrDate } from "@/SperreHelper";

interface PickerValues {
  entry?: TimeEntry;
  buttonText: string;
  selectedText: string;
  showPicker: boolean;
  left: number;
  top: number;
}

@Component
export default class EditVonBis extends EditComponent {
  public von?: Date;
  public bis?: Date;

  private pickerDates: string[] = [];
  private pickerVon: PickerValues = {
    showPicker: false,
    buttonText: "---",
    selectedText: "00:00",
    left: 0,
    top: 0,
  };
  private pickerBis: PickerValues = {
    showPicker: false,
    buttonText: "---",
    selectedText: "23:30",
    left: 0,
    top: 0,
  };
  private showPicker = false;

  private vonStr: string = ""; // String Anzeige
  private bisStr: string = "";

  private minDate: Date | null = null;

  private vonError: string = "";
  private bisError: string = "";

  public IsAllDay: boolean = true;
  private allDayVisible: boolean = true;
  private showFields: boolean = true;
  private mask: string = "D1.M1.1111";

  private calendarVal: string = "";
  private dateFormat: string = "dd-MM-yyyy";
  private format: string = "dd.MM.yyyy";

  private allDayFormat: string = "dd.MM.yyyy";
  private notAllDayFormat: string = "HH:mm dd.MM.yyyy";
  @Prop({ default: null }) public sperrBegriff!: any | null;
  @Prop({ default: true }) public showGanztag!: boolean;

  private getFromTimeDate() {
    return this.pickerDates.length > 0 ? this.pickerDates[0] : new Date();
  }
  private getToTimeDate() {
    return this.pickerDates.length > 1 ? this.pickerDates[1] : new Date();
  }

  /* presets */
  private weekStart: any = new Date(
    new Date(
      new Date().setDate(new Date().getDate() - ((new Date().getDay() + 7) % 7))
    ).toDateString()
  );
  private weekEnd: any = new Date(
    new Date(
      new Date().setDate(
        new Date(
          new Date().setDate(new Date().getDate() - ((new Date().getDay() + 7) % 7))
        ).getDate() + 6
      )
    ).toDateString()
  );

  public get selectedDatesText() {
    if (this.pickerDates.length > 1) {
      const args = this.pickerDates;
      let bis = new Date(args[1]);
      let von = new Date(args[0]);
      if (von && bis && von > bis) {
        const x = von;
        von = bis;
        bis = x;
      }
      const diff = von.diffInDays(bis);
      const text = this.$begriffBezeichnung("Tage");
      return diff + " " + text;
    }
    return "--";
  }

  public mounted() {
    this.changing = true;
    this.mountedBase();

    this.pickerVon = {
      showPicker: false,
      buttonText: "---",
      selectedText: "00:00",
      left: 0,
      top: 0,
    };
    this.pickerBis = {
      showPicker: false,
      buttonText: "---",
      selectedText: "23:30",
      left: 0,
      top: 0,
    };

    if (this.sperrBegriff) {
      const sperrDate = this.getSperrDate(this.sperrBegriff);
      if (sperrDate) {
        this.minDate = new Date(sperrDate);
      }
    }
    // im data steht es richtig drinenen - auch wenn NEU Zustand ist!
    if (!this.data?.IsAllDay) {
      this.IsAllDay = false;
    }
    if (
      this.column.meta.format === this.allDayFormat ||
      this.column.meta.format === "MM.yyyy"
    ) {
      this.IsAllDay = true;
    }

    if (this.IsAllDay) {
      this.format = this.allDayFormat;
    } else {
      this.format = this.notAllDayFormat;
    }
    if (this.column?.meta?.format) {
      this.format = this.column.meta.format;
    }
    this.mask = getMask(this.format);
    const val = this.getInitValue();
    if (val) {
      if (val.IsAllDay === true) {
        if (val.von) {
          val.von = new Date(
            new Date(new Date(val.von).setHours(0)).setMinutes(0)
          ).toISOStringWithTZGMT();
        }
        if (val.bis) {
          val.bis = new Date(
            new Date(new Date(val.bis).setHours(0)).setMinutes(0)
          ).toISOStringWithTZGMT();
        }
      }
      if (val.von) {
        this.von = new Date(val.von);
        this.vonStr = valueToFormatStr(this.von, this.format);

        if (!this.IsAllDay) {
          this.pickerVon.buttonText = this.pickerVon.selectedText = valueToFormatStr(
            this.von,
            "HH:mm"
          );
        }
      }
      if (val.bis) {
        this.bis = new Date(val.bis);
        let bisDisplay;
        if (val.von !== val.bis) {
          if (this.IsAllDay) {
            bisDisplay = this.bis = new Date(val.bis).addDays(-1);
          } else {
            bisDisplay = new Date(val.bis);
          }
        } else {
          bisDisplay = this.bis;
        }

        this.bisStr = valueToFormatStr(bisDisplay, this.format);
      }
    }

    this.setPickerDates();
    this.$nextTick(() => (this.changing = false));
  }

  private toggleTimePickerButton(fuerVon: number) {
    const entry = fuerVon ? this.pickerVon : this.pickerBis;
    const btn: Vue = (fuerVon
      ? this.$refs.timePickerButtonVon
      : this.$refs.timePickerButtonBis) as Vue;

    entry.showPicker = !entry.showPicker;
    try {
      const rect = (btn.$el as HTMLElement).getBoundingClientRect();

      entry.left = rect.left;
      if (rect.width + rect.width > window.innerWidth) {
        entry.left -= rect.width;
      }
      entry.top = rect.top;
    } catch (e) {
      console.error(e);
    }
  }

  private setPickedTimeVon(item: TimeEntry) {
    this.pickerVon.entry = item;
    this.pickerVon.buttonText = item.text;
    this.pickerVon.showPicker = false;
  }
  private setPickedTimeBis(item: TimeEntry) {
    this.pickerBis.entry = item;
    this.pickerBis.buttonText = item.text;
    this.pickerBis.showPicker = false;
  }

  private setPickerDates(removeFromBis: boolean = false) {
    if (this.von && this.bis) {
      const bis = removeFromBis ? this.bis.addDays(-1) : this.bis;
      this.pickerDates = [
        this.von.toISOStringWithTZ().substr(0, 10),
        bis.toISOStringWithTZ().substr(0, 10),
      ];
    } else if (this.von) {
      this.pickerDates = [
        this.von.toISOStringWithTZ().substr(0, 10),
        this.von.toISOStringWithTZ().substr(0, 10),
      ];
    }
    if (!this.IsAllDay) {
      if (this.von) {
        const time = valueToFormatStr(this.von, "HH:mm");
        this.pickerVon.buttonText = this.pickerVon.selectedText = time;
      }
      if (this.bis) {
        let time = valueToFormatStr(this.bis, "HH:mm");
        if (time === "00:00") {
          time = "23:30";
        }

        this.pickerBis.buttonText = this.pickerBis.selectedText = time;
      }
    }
  }

  // hier berechnen wir die Zeit korrekt mit dem DateTime Offset vom dem Datum wo wir losstarten
  private calcDateTimeForTargetTimeZone(date: Date, delta: number) {
    const dateWithoutTZ = new Date(date.toISOStringWithTZ().substr(0, 10) + "T00:00");
    return dateWithoutTZ.addMinutes(delta);
  }

  private closePicker(saveValue: boolean) {
    this.pickerVon.showPicker = this.pickerBis.showPicker = this.showPicker = false;
    if (saveValue) {
      let von: Date | undefined;
      let bis: Date | undefined;
      if (this.pickerDates.length > 0) {
        von = this.calcDateTimeForTargetTimeZone(
          new Date(this.pickerDates[0]),
          this.pickerVon.entry?.delta ?? 0
        );
      }
      if (this.pickerDates.length > 1) {
        bis = this.calcDateTimeForTargetTimeZone(
          new Date(this.pickerDates[1]),
          this.pickerBis.entry?.delta ?? 0
        );
      } else {
        bis = this.calcDateTimeForTargetTimeZone(
          new Date(this.pickerDates[0]),
          this.pickerBis.entry?.delta ?? 0
        );
      }

      this.setVonBis(von, bis);
    }
  }

  private allDayChanged(args: any) {
    try {
      // console.log("allDayChanged " + JSON.stringify(args));
      const von = getDateFromInput(this.vonStr, this.format);
      const bis = getDateFromInput(this.bisStr, this.format);

      this.showFields = false;
      this.IsAllDay = args;
      if (this.IsAllDay) {
        this.format = this.allDayFormat;
      } else {
        this.format = this.notAllDayFormat;
      }
      this.mask = getMask(this.format);
      this.vonStr = "";
      this.bisStr = "";
      this.$nextTick(() => {
        this.showFields = true;
        if (
          von.date &&
          von.date.toISOStringWithTZ().substr(0, 16) !== "1970-01-01T00:00"
        ) {
          if (this.IsAllDay) {
            // wurde von Stunden wieder auf TAG umgestellt,
            // dann muss ich die Stunden rausschmeissen & auch ValueChanged setzen!
            this.von = new Date(new Date(new Date(von.date).setHours(0)).setMinutes(0));
            von.date = this.von;
            this.bis = new Date(new Date(new Date(von.date).setHours(0)).setMinutes(0));
            bis.date = this.bis;
          }
          this.vonStr = valueToFormatStr(von.date, this.format);
        }
        if (
          bis.date &&
          bis.date.toISOStringWithTZ().substr(0, 16) !== "1970-01-01T00:00"
        ) {
          this.bisStr = valueToFormatStr(bis.date, this.format);
        }
      });
      this.rangeChanged();
    } catch (e) {
      console.error(e);
    }
  }

  private iconCss = "";

  private vonChanged(x: any, fire: boolean = true) {
    console.log("von...");
    try {
      const val = getDateFromInput(x, this.format);
      // console.log(x + " -> " + val);
      if (val) {
        if (val.date) {
          this.von = val.date;
          if (this.minDate) {
            if (!this.von || this.von < this.minDate) {
              const minDateStr = this.minDate.toISOStringWithTZ().substring(0, 10);
              val.error = "ab" + " " + minDateStr;
            }
          }
        }
        if (val.error) {
          this.vonError = val.error;
          fire = false; // nicht event feuern weil ungültig...
        } else {
          this.vonError = "";
        }
      }
      if (fire) {
        this.rangeChanged();
      }
    } catch (e) {
      console.error(e);
    }
  }
  private bisChanged(x: any, fire: boolean = true) {
    console.log("bis...");
    try {
      const val = getDateFromInput(x, this.format);
      // console.log(x + " -> " + val);
      if (val) {
        if (val.date) {
          this.bis = val.date;
          //  if (this.maxDate) {
          //   if (this.bis > this.maxDate) {
          //     const maxDateStr = this.maxDate
          //       .toISOStringWithTZ()
          //       .substring(0, 10);
          //     val.error = "bis" + " " + maxDateStr;
          //   }
          // }
        }
        if (val.error) {
          this.bisError = val.error;
          fire = false; // nicht event feuern weil ungültig...
        } else {
          this.bisError = "";
        }
      }

      if (fire) {
        this.rangeChanged();
      }
    } catch (e) {
      console.error(e);
    }
  }

  public validate(): boolean {
    try {
      if (
        (this.vonError && this.vonError.length > 0) ||
        (this.bisError && this.bisError.length > 0)
      ) {
        return false;
      }
      return true;
    } catch (error) {
      console.log(error);
    }
    return false;
  }

  private rangeChanged() {
    let von = null;
    let bis = null;
    if (this.von) {
      von = this.von.toISOStringWithTZ();
    }
    // if (!this.IsAllDay && this.von) {
    //   // bis = von;
    //   bis = this.von.toISOStringWithTZ();
    //   this.vonPicker = this.von;
    //   // this.bisPicker = this.von;
    // }
    if (this.bis) {
      bis = this.bis.toISOStringWithTZ();
    }
    if (this.von && this.bis && this.von > this.bis) {
      this.bisError = "von > bis !";
      // ups abbrechen
      return;
    } else {
      this.bisError = "";
    }

    this.setPickerDates();

    const val = {
      von,
      bis,
      IsAllDay: this.IsAllDay,
    };
    // 13.7.2020 das darf ich nicht machen - sonst hab ich bei MANUELLER eingabe einen Tag zu viel!
    // if (this.IsAllDay && bis) {
    //   val.bis = new Date(bis).addDays(1).toISOStringWithTZ();
    // }

    console.log(JSON.stringify(val));
    this.valueChangedRange(val);
  }

  protected valueChangedRange(val: any) {
    if (val && val.IsAllDay) {
      if (val.von) {
        val.von = new Date(val.von).asLocalDateString();
      }
      if (val.bis) {
        val.bis = new Date(val.bis).addDays(1);
        val.bis = new Date(val.bis).asLocalDateString();
      }
    }
    this.valueChanged(val);
  }

  private pickertitle: string = "Wählen sie ein Start-Datum:";
  private selectinVon: boolean = true;
  public changing = false;

  private setVonBis(von?: Date, bis?: Date) {
    this.showFields = false;
    if (von && bis && von > bis) {
      const x = von;
      von = bis;
      bis = x;
    }

    let isodate = "";
    if (this.von) {
      isodate += this.von.toISOStringWithTZ().substring(0, 10); // nur das datum ;)
    }
    if (this.bis) {
      isodate += this.bis.toISOStringWithTZ().substring(0, 10);
    }

    // falls vorhanden Uhrzeit und von geschriebenem übertragen
    if (von && this.IsAllDay) {
      if (this.von) {
        von.setHours(this.von.getHours());
        von.setMinutes(this.von.getMinutes());
      }
    }
    if (bis && this.IsAllDay) {
      if (this.bis) {
        bis.setHours(this.bis.getHours());
        bis.setMinutes(this.bis.getMinutes());
      }
    }

    let isodateval = "";
    if (von) {
      isodateval += von.toISOStringWithTZ();
    }
    if (bis) {
      isodateval += bis.toISOStringWithTZ();
    }
    //    this.showFields = false;
    if (isodate !== isodateval) {
      this.vonStr = von ? valueToFormatStr(von, this.format) : "";
      this.von = von;
      this.bisStr = bis ? valueToFormatStr(bis, this.format) : "";
      this.bis = bis;
    }

    (this.$refs.von as any).$forceUpdate();
    (this.$refs.bis as any).$forceUpdate();

    this.$nextTick(() => {
      this.showFields = true;
      if (this.von && this.bis) {
        this.valueChangedRange({
          von: this.von.toISOStringWithTZ(),
          bis: this.bis.toISOStringWithTZ(),
          IsAllDay: this.IsAllDay,
        });
      } else if (this.von) {
        this.valueChangedRange({
          von: this.von.toISOStringWithTZ(),
          bis: null,
          IsAllDay: this.IsAllDay,
        });
      } else if (this.bis) {
        this.valueChangedRange(null);
      }
      this.changing = false; // muss zurück gesetzt werden! sonst geht keine Änderung über den DatePicker mehr!
    });
  }

  private getHolidayColorVuetify(args: string) {
    try {
      return getHolidayColorVuetify(new Date(args)) ?? "";
    } catch (err) {
      console.error(err);
    }
  }

  private showTooltip = false;
  private tooltipText = "";
  private onDayHover(day: string) {
    const feiertag = this.$api.getHoliday(day);
    if (feiertag) {
      this.showTooltip = true;
      this.tooltipText = feiertag.bezeichnung;
    }
  }
  private onDayLeave(args: any) {
    this.showTooltip = false;
  }

  private highlightDays(args: RenderDayCellEventArgs): void {
    try {
      return highlightDays(args, this);
    } catch (err) {
      console.error(err);
    }
  }
  protected getSperrDate(begriff: any) {
    const me = this;
    const aic = me.$api.user.aic;
    if (this.query) {
      const t = findSperrDate(this.query, aic, me);
      if (t) {
        const sperrDate = t.sperrDate;
        return new Date(sperrDate);
      }
    }
  }
}
