























import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditComponent from "@/editor/EditComponent";

@Component
export default class EditSwitchButton extends EditComponent {
  // props: ["initValue", "column", "description"],
  constructor() {
    super();
  }
  private icon = "";
  private myData: boolean = false;

  public mounted() {
    this.data = this.myData = this.getInitValue();
    if (this.column.meta.icon) {
      this.icon = this.column.meta.icon;
    }
  }
  protected checkedChanged(txt: any) {
    this.data = this.myData;
    this.valueChanged(this.data); // zum event triggern
  }
}
