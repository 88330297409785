































































































































































































































import EditPage from "@/components/EditPage";
import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
// import {  onShowSite } from "../../NavigationHelper";

@Component({
  provide: {},
})
export default class StammBetriebsstaette extends EditPage {
  constructor() {
    super();
    this.query = "Web_StammdatenBtst";
    this.bewegungsdaten = false;
    this.showLoading = true;
    this.backOnSave = false; // @isabelle: props kann man nicht modifien
  }
  private showDialog: boolean = false;
  private initalized: boolean = false;
  private items: any[] = [];
  public showZeitraumInHeader: boolean = true;
  private selecteditem: number = 0;
  public mounted() {
    if (this.siteData && this.siteData?.aic !== 0) {
      this.selected = this.siteData.aic;
      this.siteData.aic = 0;
    }
    this.dialogStt.query = "Web_StammdatenBtst";
    this.selecteditem = 0;
    this.items.push({
      text: "Allgemein",
      icon: "supervised_user_circle",
      color: "primary",
      step: 1,
    });
    this.items.push({
      text: "Adresse",
      icon: "home",
      color: "success",
      step: 2,
    });

    if (this.checkBegriff("Lizenz_ZeiterfassungAdmin")) {
      this.items.push({
        text: "Zeiterfassung",
        icon: "schedule",
        color: "warning",
        step: 3,
      });
    }
  }
  public initialize() {
    this.stammBezeichnung = this.getKennungValueDisplay(
      this.data,
      "AUFTRAGSNAME",
      this.columns
    );
    this.stammBezeichnung =
      this.stammBezeichnung +
      " " +
      this.getKennungValueDisplay(this.data, "AUFTRAGSNUMMER", this.columns);
    if (this.stammKennung === "" || !this.stammKennung) {
      this.stammKennung = this.stammBezeichnung.replace(/\s+/g, "");
    }
    this.initalized = true;
  }
  private group: any = null;
  @Watch("group")
  private groupWatch(val: boolean) {
    if (!val) {
      this.drawer = false;
    }
  }
  @Watch("drawer")
  private drawerWatch(val: boolean, oldval: boolean) {
    if (oldval !== val) {
      this.drawer = val;
    }
  }
  @Watch("mini")
  private miniWatch(val: boolean, oldval: boolean) {
    if (oldval !== val) {
      this.mini = val;
    }
  }
  private async deleteSttPerson(selected: number) {
    this.onDeleteAsync(selected).then(() => {
      this.$nextTick(() => this.reloadList());
    });
  }
  private reloadList() {
    this.dialogDelete = false;
    this.$forceUpdate();
    const comp = this.$refs.comboList as any;
    if (comp.refreshData) {
      comp.refreshData();
      setTimeout(() => this.mountedBase(), 100);
    }
  }
  private onBack(updatedata: any) {
    if (updatedata) {
      this.$forceUpdate();
      const comp = this.$refs.comboList as any;
      if (comp.refreshData) {
        comp.refreshData().then((loadedData: QueryResponse) => {
          comp.selectByAic(updatedata[0].aic_Stamm);
        });
      }
      this.$nextTick(() => {
        setTimeout(() => this.mountedBase(), 100);
      });
    }
    this.dialogOpen = false;
  }
  public onClose() {
    this.siteData.reload = true;
    this.$emit("back");
  }
  // private checkSite(item: any) {
  //   if (this.saveFrage) {
  //     if (
  //       confirm(
  //         "Wollen Sie die Seite wirklich verlassen - es sind nicht alle Daten gespeichert?"
  //       )
  //     ) {
  //       this.saveFrage = false;
  //       this.step = item.step;
  //       this.mini = !this.mini;
  //     }
  //   } else {
  //     this.step = item.step;
  //     this.mini = !this.mini;
  //   }
  // }
}
