import AUApi from "./api";

export function saveLocalData<T>(key: string, data: T, userData: boolean = true) {
    if (userData) { key = genUserKey(key); }
    if (typeof Storage !== "undefined") {
        localStorage.setItem(key, JSON.stringify(data));
        return true;
    }
    return false;
}

export function loadLocalData<T>(key: string, defaultValue: T, userData: boolean = true) {
    if (userData) { key = genUserKey(key); }
    if (typeof Storage !== "undefined") {
        const json = localStorage.getItem(key);
        if (!json) { return defaultValue; }
        return JSON.parse(json) as T;
    }
    return defaultValue;
}

function genUserKey(key: string) {
    return AUApi.Current.user?.aic + "_" + key;
}
