import { registerRequestValidator, RequestConfig } from "./RequestHandler";
import { showSnackbar } from "./UIHelper";
let app: Vue;
let tokenTimer: number = 0;
let tokenRefreshCounter: number = 5;
let tokenEndeDate: Date | undefined;

export function initializeTokenHelper(newApp: Vue) {
  if (!newApp) { throw new Error("app nicht initialisiert"); }
  app = newApp;
  registerRequestValidator(o => {
    if (typeof o === "string" && o.indexOf("logout") > 0) {
      return true;
    } else if (typeof o === "string" && o.indexOf("ver2") > 0) {
      return true;
    } else if (typeof o === "string" && o.indexOf("user") > 0) {
      return true;
    } else if ((o as RequestConfig).url.indexOf("logout") > 0) {
      return true;
    } else if ((o as RequestConfig).url.indexOf("ver2") > 0) {
      return true;
    }
    return checkToken();
  });
}

export function setTokenRefreshTimer(timeout: number | null = null) {
  if (!app) { throw new Error("app nicht initialisiert"); }
  if (tokenTimer !== 0) {
    clearTimeout(tokenTimer);
  }
  if (!timeout) {
    timeout = (app.$api.user.tt / 2) * 60 * 1000; // ab 21.1.2021 kommt der Wert in Minuten
  }
  if (app.$api.user && app.$api.user.tt) {
    tokenTimer = setTimeout(checkTokenRefresh, timeout);
  }
}
export function checkTokenRefresh() {
  let tokenEnde: string = "";
  if (!app) { throw new Error("app nicht initialisiert"); }
  app.$api
    .refreshToken()
    .then((r: any) => {
      if (!r || !r.data || !r.data.token) {
        console.log("no token received...");
        checkTokenRefreshRetry(tokenRefreshCounter, false);
      }
      app.$api.user.msg = r.data.msg;
      // es kommt beim Token refresh nun auch die Info mit ob es neue Nachrichten gibt..
      app.$api.user.id = r.data.token;
      app.$api.user.time = r.data.time; // neues Token Ende setzen
      const tokenAblauf = r.data.time;
      tokenEndeDate = new Date(tokenAblauf);
      tokenEnde = tokenEndeDate.toLocaleTimeString();
      app.$api.setLocalData(app.$api.user.tt); // ab 22.1.2021 kommt der Wert in Minuten
      showSnackbar({
        text: app.$globalsBezeichnung(
          app.Globals.Begriff,
          "Web_Token_Refresh",
          "Token wurde neu geladen"
        ),
        duration: 2000,
        color: "blue"
      });
      tokenRefreshCounter = 5;
      setTokenRefreshTimer();
    })
    .catch((e: any) => {
      console.log("error in token refresh", e);
      showSnackbar({
        text: app.$globalsBegriffMemo(
          app.Globals.Begriff,
          "Web_KeinToken",
          "Token konnte nicht refresht werden! Sie werden abgemeldet..."
        ),
        duration: 6000,
        color: "error"
      });
      setTimeout(() => handleLogout(), 6000);
      // checkTokenRefreshRetry(tokenRefreshCounter, true);
    });
  return tokenEnde;
}

export function checkTokenRefreshRetry(counter: number, error: boolean) {
  if (!app) { throw new Error("app nicht initialisiert"); }
  if (counter > 0) {
    counter--;
    setTokenRefreshTimer(30 * 1000);
    showSnackbar({
      text: app.$globalsBegriffMemo(
        app.Globals.Begriff,
        "Web_keinToken",
        "Token konnte nicht geladen werden, Neuer Versuch in 30 sek."
      ),
      duration: 6000,
      color: "orange"
    });
    return;
  }
  if (app.$api.user.rechte !== "Def") {
    showSnackbar({
      text: app.$globalsBegriffMemo(
        app.Globals.Begriff,
        "Web_KeinToken",
        "Token konnte nicht refresht werden! Sie werden abgemeldet..."
      ),
      duration: 6000,
      color: "error"
    });
    setTimeout(() => handleLogout(), 6000);
  } else {
    showSnackbar({
      text:
        "Token konnte nicht refresht werden! DEF wird nicht abgemeldet...",
      duration: 6000,
      color: "error"
    });
  }
}
export function handleLogout() {
  if (!app) { throw new Error("app nicht initialisiert"); }
  app.$api.logout();
  return;
}
export function setTokenEnd(tokenAblauf: Date) {
  let tokenEnde: string = "";
  tokenEndeDate = new Date(tokenAblauf);
  tokenEnde = tokenEndeDate.toLocaleTimeString();
  return tokenEnde;
}
export function checkToken(logout: boolean = true) {
  // wenn ein tokenende errechnet wurde (also angemeldet) und tokenende > jetzt dann abmelden
  if (tokenEndeDate && tokenEndeDate < new Date()) {
    handleLogout();
    return false;
  }
  return true;
}
