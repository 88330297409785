<template>
  <v-card raised width="90%">
    <baseSyncGrid query="ZE_Aufteilung_Projektstunden" />
    <baseSyncGrid :pagZeilen="20" query="Web_pers_Stempelungen" />
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      aic: 0
    };
  }
};
</script>

<style scoped></style>
