import { BarcodeScanner, BarcodeScannerPlugin } from "@capacitor-community/barcode-scanner";
import { Capacitor } from "@capacitor/core";

import { getDeviceInfo } from "./DeviceInfoPlugin";

import {
    BarcodeScanner as BarcodescannerOld,
    BarcodeScannerOriginal,
    BarcodeScanResult as BarcodeScanResultOld,
} from "@ionic-native/barcode-scanner";

export interface BarcodeScanResult {
    content?: string;
    type?: string;
    cancelled: boolean;
}

const barcodeScannerOld: BarcodeScannerOriginal = BarcodescannerOld;
let useOld = false;

let hideApp: () => Promise<void>;
let showApp: () => Promise<void>;
export function initializeBarcodeWrapper(
    hideAppCallback: () => Promise<void>,
    showAppCallback: () => Promise<void>) {
    hideApp = hideAppCallback;
    showApp = showAppCallback;

    getDeviceInfo().then(x => {
        useOld = !x.useNewBarcodeScanner ?? false;
    });

    (window as any).testBarocdeAppHide = hideApp;
    (window as any).testBarocdeAppShow = showApp;
}

export function scanBarcode(callback?: (content: BarcodeScanResult) => void): Promise<BarcodeScanResult> {
    if (useOld) {
        return scanBarcodeOld(callback);
    } else {
        return scanBarcodeNew(callback);
    }
}

const barcodeScanner: BarcodeScannerPlugin = Capacitor.Plugins.BarcodeScanner as BarcodeScannerPlugin;
let prepared = false;
async function scanBarcodeNew(callback?: (content: BarcodeScanResult) => void): Promise<BarcodeScanResult> {
    if (!prepared) {
        const status = await barcodeScanner.checkPermission({ force: true });
        barcodeScanner.prepare();

        if (!status.granted) {
            return { cancelled: true };
            // the user granted permission
        }
        prepared = true;
    }
    barcodeScanner.hideBackground(); // make background of WebView transparent
    await hideApp();
    const result = await barcodeScanner.startScan(); // start scanning and wait for a result
    await showApp();
    barcodeScanner.disableTorch().catch(() => console.log("cannot disable torch"));
    await barcodeScanner.showBackground();
    const res: BarcodeScanResult = {
        content: result.content,
        type: result.format,
        cancelled: false
    };
    if (!res.content) {
        res.cancelled = true;
    }
    // if the result has content
    if (result.hasContent && callback) {
        callback(res);
    }
    return res;
}

async function scanBarcodeOld(callback?: (content: BarcodeScanResult) => void): Promise<BarcodeScanResult> {

    const result = await barcodeScannerOld.scan(); // start scanning and wait for a result
    const res: BarcodeScanResult = {
        content: result.text,
        type: result.format,
        cancelled: false
    };
    if (!res.content) {
        res.cancelled = true;
    }
    // if the result has content
    if (result.text && callback) {
        callback(res);
    }
    return res;
}

export async function cancelScan() {
    await showApp();
    await barcodeScanner.showBackground();
    BarcodeScanner.stopScan();
}

export async function toggleBarcodeScannerTorch() {
    if (!useOld) {
        barcodeScanner.toggleTorch();
    }
}
