import { Capacitor, registerPlugin, Plugin } from '@capacitor/core';

export interface DeviceInfoData {
    size: number;
    type: string;
    isTablet: boolean;
    useNewBarcodeScanner?: boolean;
    useNativeLogin?: boolean;
}

export interface LoginData {
    success: boolean;
    user?: string;
    pwd?: string;
}

interface DeviceInfoPlugin {
    getInfo(): Promise<DeviceInfoData>;
    showLoginScreen(): Promise<LoginData>;
}
const DeviceInfo = registerPlugin<DeviceInfoPlugin>('AllDeviceInfo');

export default DeviceInfo;

let devInfo: DeviceInfoData | undefined;
let devInfoCb: (di: DeviceInfoData) => void | undefined;

export function setDeviceInfoCallback(devInfoCallback: (di: DeviceInfoData) => void) {
 devInfoCb = devInfoCallback;
}
const displayTabletThreshold = 7;

export function getDeviceInfo(refresh: boolean = false): Promise<DeviceInfoData> {
    if (!devInfo || refresh) {
        if (!DeviceInfo || Capacitor.getPlatform() === "web") {
            if (refresh) {
                if (!DeviceInfo && Capacitor.getPlatform() !== "web") {
                    throw new Error("no device plugin available");
                }
            }
            devInfo = {
                size: -1,
                type: "web",
                isTablet: false,
            };
        } else {
            return (DeviceInfo.getInfo() as Promise<DeviceInfoData>).then(t => {
                devInfo = t;
                t.isTablet = t.size >= displayTabletThreshold;
                if (devInfoCb) { devInfoCb(t); }
                return devInfo;
            }).catch(e => {
                return devInfo = {
                    size: -1,
                    type: "Error: " + e,
                    isTablet: false,
                };
            });
        }
    }
    return Promise.resolve(devInfo);
}


export function isNativeLoginAvailable() {
    return devInfo?.useNativeLogin === true;
}
export async function showNativeLoginDialog() {
    if (!isNativeLoginAvailable()) { return; }

    return await DeviceInfo.showLoginScreen();
}

export function isTablet() {
    // if (!devInfo) { throw new Error("invoke getDeviceInfo first!"); }
    return devInfo?.type !== "web" && devInfo?.isTablet;
}
export function isPhone() {
    // if (!devInfo) { throw new Error("invoke getDeviceInfo first!"); }
    return devInfo?.type !== "web" && !devInfo?.isTablet;
}
export function isWeb() {
    // if (!devInfo) { throw new Error("invoke getDeviceInfo first!"); }
    return devInfo?.type === "web";
}

export function getDeviceType() {
    return devInfo?.type ?? "web";
}
