import { Capacitor, Plugins } from "@capacitor/core";
// import { App } from "@capacitor/app";
// import { Directory, WriteFileOptions, Encoding } from "@capacitor/filesystem";
const { AppUpdatePlugin } = Plugins;
import Vue from "vue";
import AUApi from "./api";
import Constants from "./Constants";

interface AppUpdateResult {
    available: boolean;
    index?: string;
    info?: string;
}

const CapacitorUrlPart = "/_capacitor_file_/";

let updateRedirectPending = false;

function mapToLocalUrl(url: string) {
    url = Capacitor.convertFileSrc(url);
    url = url.replace("file:/", CapacitorUrlPart);
    return url;
}
export async function checkNativeUpdateAvailable() {
    if (Capacitor.getPlatform() !== "web" && AppUpdatePlugin) {
        if (window.location.href.indexOf(CapacitorUrlPart) >= 0) {
            console.log("already running in local copy...");
            return false;
        }

        const result = await AppUpdatePlugin.checkNativeCopyAvailable();
        console.log('result of appPlugin' + JSON.stringify(result));

        if (!result.available || !result.info || !result.index) {
            console.log("no local copy available");
            return false;
        }

        const index = mapToLocalUrl(result.index);
        console.log("navigating to local copy...", result);
        Constants.indexFile = index;
        updateRedirectPending = true;
        // window.location.href = index;
        return true;
    }
    if (Capacitor.getPlatform() === "web") {
        console.log("platform = web - daher kein Update");
    } else if (!AppUpdatePlugin) {
        console.log("kein UpdatePugin verfügbar!");
    }

    return false;
}

export async function downloadNativeUpdate(downloadUrl: string) {

    if (Capacitor.getPlatform() !== "web") {
        console.log("checking native update");
        // if (Capacitor.getPlatform() === "ios") {
        //     console.warn("skipping local-update till implemented");
        //     return;
        // }
        // console.log("checking native update");
        const result: AppUpdateResult =
            await AppUpdatePlugin.downloadAndInstallUpdate(
                {
                    message: "update wird heruntergeladen!",
                    downloadUrl,
                    config: "var ALLConfig = " + JSON.stringify(AUApi.Current.getCurrentConfig()) + ";"
                });

        if (!result.available || !result.info || !result.index) {
            console.log("no update available");
            return;
        }
        const url = mapToLocalUrl(result.info);
        const index = mapToLocalUrl(result.index);
        // Constants.indexFile = index;
        console.log('updating from url:' + url);
        reloadApp();

        // axios.get(url).then(r => {
        //     console.log("navigating to new local copy...", r.data);
        //     Constants.indexFile = index;
        //     // refresh everything
        //     reloadApp();
        // }).catch(e => {
        //     console.error("error on checking local updateing...", e);
        //     alert(JSON.stringify(e));
        // });
    }
}

// registers unbefore handler which triggers a dialog of the browser to check if the user really wants to leave
// if the app itself wants to reload window.reloadingApp is set to prevent the dialog.
export function initAppUnloadHandler() {
    (document.body as any).onbeforeunload = (e: any) => {
        if (!((window as any).reloadingApp) && AUApi.Current.user && !AUApi.Current.getCurrentConfig().debug) {
            e.preventDefault();
            return e.returnValue = "do you really want to leave?";
        }
    };

    (document.body as any).onunload = (e: any) => {
        if (!((window as any).reloadingApp) && !AUApi.Current.getCurrentConfig().debug) {
            // AUApi.Current.logout(); // perhaps telling the server we are out...
            AUApi.Current.logoutByBeacon();
            AUApi.Current.eraseLocalData(); // killing me hardly
        }
    };
}

export function reloadApp(eraseLocalData: boolean = false) {
    if (eraseLocalData) {
        AUApi.Current.eraseLocalData();
    }
    // path suche da sonst subverzeichnisse nicht klappen!
    let rootUrl = window.origin;
    if (rootUrl !== "http://localhost:8080") {
        rootUrl = AUApi.Current.nativeRoot;
    }

    // if (configPath !== "/ALL/UNLIMITED/") {
    //     pathALL = configPath;
    // }
    (window as any).reloadingApp = true;
    if ((window as any).reloadApp) {
        (window as any).reloadApp();
        // window Location zurück setzen
        if (window.location.href !== window.location.origin) {
            window.location.href = rootUrl;
        } else {
            window.location.reload();
        }
    } else {
        if (window.location.href !== rootUrl) {
            window.location.href = rootUrl;
        } else {
            window.location.reload();
        }
    }
}

