/*
Helper functions for modifiying Files and Images in Typescript
*/

export function resizeImage(file: File, maxWidth: number, maxHeight: number, quality: number = 0.8, fileType: string = 'image/jpeg'): Promise<Blob> {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = () => {
            const width = image.width;
            const height = image.height;

            if (width <= maxWidth && height <= maxHeight) {
                resolve(file);
            }

            let newWidth;
            let newHeight;

            if (width > height) {
                newHeight = height * (maxWidth / width);
                newWidth = maxWidth;
            } else {
                newWidth = width * (maxHeight / height);
                newHeight = maxHeight;
            }

            const canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;

            const context = canvas.getContext('2d');
            if (context == null) { throw new Error("canvas not supported!"); }
            context.drawImage(image, 0, 0, newWidth, newHeight);

            canvas.toBlob(b => {
                if (b != null) {
                    resolve(b);
                } else { throw new Error("Image not readable!"); }
            }, "image/jpeg", quality);
        };
        image.onerror = reject;
    });
}

export function createBase64Image(fileObject: File | Blob) {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            if (e.target) {
                resolve(e.target.result as string);
            }
        };
        reader.readAsDataURL(fileObject);
    });
}
