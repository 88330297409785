






















import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { Globals } from "@/Globals";
import EditPage from "@/components/EditPage";
import BaseEditor from './BaseEditor.vue';

@Component
export default class ModellDialog extends EditPage {
  // @Prop({ default: null }) protected zeitbereich!: APIZeitbereich | null;
  public mounted() {
    this.mountedBase();
  }

  protected initialize() {
    if (this.siteData && this.$refs.editor) {
      this.siteData.getReturnData = (this.$refs.editor as BaseEditor).getDialogData;
    }
  }
}
