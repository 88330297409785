












































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";

Vue.use(DateRangePickerPlugin);

@Component
export default class BaseKleinZeitraum extends Vue {
  @Prop() protected month!: boolean;
  private menu: boolean = false;
  private classVal: string = "customCSS";
  private dateFormat: string = "dd-MM-yyyy";
  private waterMark: string = "Selct a Range";
  private startVal: any = this.$api.zeitbereich.von;
  private endVal: any = this.$api.zeitbereich.bis;
  private date: string = new Date().toISOString().substr(0, 7);
  private weekStart: any = new Date(
    new Date(
      new Date().setDate(new Date().getDate() - ((new Date().getDay() + 7) % 7))
    ).toDateString()
  );
  private weekEnd: any = new Date(
    new Date(
      new Date().setDate(
        new Date(
          new Date().setDate(
            new Date().getDate() - ((new Date().getDay() + 7) % 7)
          )
        ).getDate() + 6
      )
    ).toDateString()
  );
  private monthStart: any = new Date(
    new Date(new Date().setDate(1)).toDateString()
  );
  private monthEnd: any = new Date(
    new Date(
      new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0)
    ).toDateString()
  );
  private lastStart: any = new Date(
    new Date(
      new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)
    ).toDateString()
  );
  private lastEnd: any = new Date(
    new Date(new Date().setDate(0)).toDateString()
  );
  private yearStart: any = new Date(
    new Date(new Date().getFullYear() - 1, 0, 1).toDateString()
  );
  private yearEnd: any = new Date(
    new Date(new Date().getFullYear() - 1, 11, 31).toDateString()
  );

  public valueChanged(args: any) {
    this.menu = false;
    this.date = args;
    if (!args) {
      return;
    }
    if (!args.startDate && !args.endDate) {
      this.startVal = this.lastStart;
      this.endVal = this.lastEnd;
      this.$api.zeitbereich.von = this.lastStart;
      this.$api.zeitbereich.bis = this.lastEnd;
    } else {
      this.startVal = args.startDate;
      this.endVal = args.endDate;
      this.$api.zeitbereich.von = args.startDate;
      this.$api.zeitbereich.bis = args.endDate;
    }

    console.log("neuer zeitbereich :" + args.startDate + " - " + args.endDate);

    this.$emit("zeitraumChanged", { zeitbereich: this.$api.zeitbereich });
  }
}
