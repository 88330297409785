














































































































































































































































































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import CreateList from "@/components/CreateList";
import { getMonthNames } from "@/editor/CalenderHelper";
import { getKennungValue, getKZValue, getKZValueDisplay } from "@/DataHelper";
import { showDialog } from "@/UIHelper";

@Component
export default class WFVorgesetzterFreigabe extends CreateList {
  constructor() {
    super();
    this.queryMutable = "Web_Freigabe_MA_VG";
    this.showLoading = true;
  }
  private actualday: Date = this.$api.zeitbereich.von;
  private contentHeight!: number;
  private contentHeight2!: number;
  private ueberschrift!: string;
  @Prop() public titel!: string;
  @Prop({ default: true }) protected expandPanel!: boolean;
  private timeLine: boolean = false;
  private editaic: any;
  private showDialog1: boolean = false;
  private zulagenBtnVisible: boolean = true;
  private showDef: boolean = false;

  private titleColor = "dvhBackground";
  private fab: boolean = false;
  private hover: boolean = false;
  private top: boolean = true;
  private right: boolean = true;
  private bottom: boolean = false;
  private left: boolean = false;
  private transition: string = "slide-y-reverse-transition";
  @Prop({ default: null }) private day!: Date | null;
  private monat: string = "-";
  protected createdBase() {
    const me = this;
    this.$root.$on("windowResize", (a: any) => me.onResize(a));
  }
  public onRefresh() {
    (this.$refs.monatlFreigabe as any).onRefresh();
  }
  public onResize(args: any) {
    this.$forceUpdate();
  }
  public mounted() {
    this.contentHeight = window.innerHeight - 150;
    this.contentHeight2 = this.contentHeight;
    this.initialized = false;
    try {
      const dayVon = this.actualday.firstOfMonth();
      const dayBis = this.actualday.lastInMonth();
      if (this.siteData) {
        this.ueberschrift = this.siteData.name;
        this.aic = this.siteData.aic;
      }
      this.editaic = this.aic;
      // if (this.aic) {
      //   this.vecStamm.push(this.aic);
      // }
      const me = this;
      this.editZeitbereich = {
        von: dayVon,
        bis: dayBis,
        bereich: "Monat",
      };
      if (!this.day) {
        this.day = this.$api.zeitbereich.von;
      }
      this.monat = getMonthNames()[this.day.getMonth()];
      if (this.$api.user.rechte === "Def") {
        this.showDef = true;
        this.zulagenBtnVisible = true;
      }

      // this.zulagenLoaded();
      this.statusZeitaufzeichnung();
      // this.addDruck();
      if (!this.mountedBase()) {
        return;
      }
    } catch (e: any) {
      console.error("mounterror: " + e, e?.stack);
    }
  }
  public reloadData() {
    this.showLoading = true;
    const me = this;
    if (this.queryMutable) {
      const vecStamm: any[] = [];
      this.$api.getQuery(
        this.queryMutable,
        (data: any) => me.dataLoaded(data),
        this.editaic,
        this.editZeitbereich,
        0,
        false,
        false,
        "",
        0,
        vecStamm
      );
    }
  }
  protected dataLoaded(data: any) {
    this.dataLoadedBase(data);
    if (this.showCalc) {
      this.contentHeight2 = window.innerHeight - 190;
    }
    this.initialized = true;
  }

  protected refreshItems() {
    this.items = []; // ausleeren für refresh
    if (this.data.length > 0) {
      this.timeLine = true;
    }
    for (let i = 0; i < this.data.length; i++) {
      const row = this.data[i];
      let dot = "mdi-dot";

      const antrag = getKZValueDisplay(row, "Antrag", this.columns);
      const day = getKZValue(row, "header", this.columns);
      let avatar = getKZValueDisplay(row, "avatar", this.columns);
      const header = getKZValueDisplay(row, "header", this.columns);
      const title = getKZValueDisplay(row, "titel", this.columns);
      const subtitel = getKZValueDisplay(row, "subtitel", this.columns);
      const soll = getKZValueDisplay(row, "Soll", this.columns);
      const ist = getKZValueDisplay(row, "Ist", this.columns);
      const differenz = getKZValueDisplay(row, "Differenz", this.columns);
      const timer = getKZValue(row, "Timer", this.columns);
      const differenzValue = getKennungValue(
        row,
        "BERECHNUNG_MASS_3",
        this.columns
      );
      const tagessaldoValue = getKennungValue(
        row,
        "BERECHNUNG_MASS",
        this.columns
      );
      const text = getKZValueDisplay(row, "text", this.columns);
      const icon = getKZValueDisplay(row, "icon", this.columns);
      let color = getKZValueDisplay(row, "color", this.columns);
      let showSaldo = true;
      if (antrag === "1.00") {
        dot = "verified_user";
      }
      if (!color) {
        color = "green";
      }
      if (avatar) {
        const start = avatar.indexOf('"') + 1;
        const len = avatar.lastIndexOf('"') - start;
        avatar = avatar.substr(start, len);
      } else if (!avatar) {
        avatar = "mdi-account-circle";
      }
      if ((soll === 0 && ist === 0) || (soll === "00:00" && ist === "00:00")) {
        showSaldo = false;
      }
      let expand = false;
      if (this.expandPanel) {
        if (text || tagessaldoValue || differenzValue || soll || timer) {
          expand = true;
        }
      }
      this.items.push({
        header,
        title,
        subtitel,
        text,
        soll,
        ist,
        differenz,
        differenzValue,
        tagessaldoValue,
        avatar,
        icon,
        divider: true,
        inset: true,
        color,
        aic: row.aic_Bew_pool,
        menu: false,
        dot,
        showSaldo,
        expand,
        day,
      });
    }
  }
  private onCalculated(args: any) {
    // nach der Berechnung alles neu laden!
    this.$nextTick(() => {
      this.loadAmpel("ZE_Ampel_Zeitaufzeichnung", false, true, this.editaic);
      this.loadAmpel("ZE_Ampel_Zeitaufzeichnung", true, false, this.editaic);
      this.reloadData();
    });
    // (this.$refs.monatlFreigabe as any).onRefresh();
  }

  private onDialogClose() {
    this.showDialog1 = false;
  }
  private statusZeitaufzeichnung() {
    this.loadAmpel("ZE_Ampel_Zeitaufzeichnung", false, true, this.editaic);
    this.loadAmpel("ZE_Ampel_Zeitaufzeichnung", true, false, this.editaic);
  }

  private openBuchungen(aic: string, day: any) {
    const me = this;
    if (!day) {
      day = this.$api.zeitbereich.von;
    }
    const dayIso = new Date(day);
    const dialogZeitbereich = {
      von: dayIso,
      bis: dayIso,
      bereich: "Tag",
    };
    this.$root.$emit("showSite", {
      site: "PzeWfVorgesetzterFreigabenTemplateStempelungen",
      data: {
        zeitbereich: dialogZeitbereich,
        title: this.titel,
        aic, // aic wird bei Aufruf mit übergeben
        name: me.ueberschrift,
      },
      aic: me.editaic,
      callOnLeave: () => {
        me.reloadData();
      },
    });
  }
}
