














import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
@Component
export default class WebTestSpeichern extends EditPage {
  constructor() {
    super();
    this.query = "Web_Test_Speichern";
  }
}
