




















































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AufgabeItemContainer,
  DashboardRow,
  getAufgabeDefinition,
  ItemContainer,
} from "./ChartTypesAndFunctions";
import { loadLocalData, saveLocalData } from "@/BrowserDataStore";
import { refreshFullPageContent } from "@/NavigationHelper";

@Component
export default class AufgabenBoard extends Vue {
  private currentDialogResolve?: (value: void) => void;
  private currentDialogReject?: (reason: string) => void;
  private itemToAdd?: AufgabeItemContainer;
  private selectedAufgabe: ALLAufgabe | null = null;

  @Prop({ default: false }) private persoenlicheAufgaben!: boolean;
  @Prop({ default: "_" }) private dashboardName!: string;
  @Prop() private dashboardTemplate!: DashboardRow[];
  private persAufgaben: string = "allgemein";
  @Prop() private setztVector!: boolean;
  public showZeitraumInHeader: boolean = true;

  private boardData?: DashboardRow[];
  private initialized = false;
  private dialog = false;
  private showLoading = false;
  private selectedOrientation: ItemOrientation = "vertical";
  private selectedSize: number | undefined;

  private aufgaben: ALLAufgabe[] = [];
  private aufgabenItems: Array<{ text: string; value: ALLAufgabe }> = [];

  private aufgabenDefinition?: QueryResponse;
  private vecStamm?: [];
  private readonly: boolean = true;

  public mounted() {
    this.refresh();
  }

  @Watch("persoenlicheAufgaben")
  @Watch("dashboardName")
  @Watch("dashboardTemplate")
  public refresh() {
    this.showLoading = true;
    this.initialized = false;
    // zuerst die aufgaben damit wir nachher sauber filtern können
    this.aufgaben = this.$globalsAufgabe(this.persoenlicheAufgaben).filter(
      (c: any) => c.detail !== true
    );
    // gibt es nur 1 Aufgabe ist diese Initalisiert - nur DEF User dürfen IMMMER!
    if (this.aufgaben.length > 1 || this.$api.user.rechte === "Def") {
      this.readonly = false;
    }
    // boarddaten holen (wird ggf ausgebessert)
    this.boardData = this.loadData(this.dashboardTemplate);
    // nur aus diesen soll der User auswählen
    this.aufgabenItems = this.aufgaben.map((v) => ({
      text: v.bezeichnung,
      value: v,
    }));
    this.aufgabenItems.sort(this.dynamicSort("text"));
    const me = this;
    getAufgabeDefinition().then((r) => {
      me.aufgabenDefinition = r;
      me.initialized = true;
      this.showLoading = false;
    });
  }
  private dynamicSort(property: any) {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return (a: any, b: any) => {
      const result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  public addChartItem(item: ItemContainer): Promise<void> {
    this.dialog = true;
    const me = this;
    return new Promise<void>((resolve, reject) => {
      me.currentDialogResolve = resolve;
      me.currentDialogReject = reject;
      this.itemToAdd = item as AufgabeItemContainer;
    });
  }

  private closeDialog(add: boolean) {
    if (add) {
      if (this.currentDialogResolve && this.itemToAdd && this.selectedAufgabe) {
        this.itemToAdd.aufgabe = this.selectedAufgabe;
        // this.itemToAdd.size = this.selectedSize;
        this.currentDialogResolve();
      }
    } else {
      if (this.currentDialogReject) {
        this.currentDialogReject("aborted");
        // this.selectedSize = undefined;
        this.itemToAdd = undefined;
        this.selectedAufgabe = null;
      }
    }
    this.dialog = false;
  }

  private async aufgabeSelected(aufgabe: ALLAufgabe) {
    this.selectedAufgabe = aufgabe;
  }

  private get saveKey() {
    const name = this.dashboardName.replaceAll(/(\s|üäöÜÄÖ)/g, "_");
    return "board" + name + (this.persoenlicheAufgaben ? "Pers" : "");
  }

  private loadData(template: DashboardRow[] = []): DashboardRow[] {
    let data = loadLocalData(this.saveKey, template);
    if (!data || data.length === 0) {
      data = [
        {
          groups: [{ items: [], id: 2 }],
          id: 1,
        },
      ];
    }
    let aicsChanged = false;
    data.forEach((r) => {
      r.groups.forEach((c) => {
        c.items = c.items.filter((i) => {
          const ia = i as AufgabeItemContainer;
          const aufgabe = this.aufgaben.find(
            (a) => a.aic === ia.aufgabe.aic || ia.aufgabe.kennung === a.kennung
          );
          if (!aufgabe) {
            // gibts nimma - raus damit
            return false;
          } else if (aufgabe.aic !== ia.aufgabe.aic) {
            // ausbessern
            ia.aufgabe = aufgabe;
            aicsChanged = true;
          }
          if (i.hideTitle === undefined) {
            i.hideTitle = false;
          }
          return true;
        });
      });
    });

    if (aicsChanged) {
      this.onSaveData(data);
    }

    return data;
  }

  private onSaveData(data: DashboardRow[]) {
    this.$emit("change", data);
    return saveLocalData(this.saveKey, data);
  }

  private hideAufgabeTitle(item: ItemContainer) {
    const wasVisible = item.hideTitle;
    item.hideTitle = true;
    if (item.hideTitle) {
      this.$forceUpdate();
    }
  }
}
