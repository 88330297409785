var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showMutable)?_c('div',{staticClass:"wrap"},[_c('v-tooltip',{attrs:{"disabled":!_vm.showtooltip,"bottom":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"e-float-input"},'div',attrs,false),on),[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":_vm.anzahlCols}},[(!_vm.showSelect && !_vm.useAutocomplete)?_c('v-combobox',{ref:"combobox",attrs:{"clearable":_vm.clearable,"dense":"","flat":"","label":_vm.description,"items":_vm.list,"disabled":!_vm.enabled,"item-text":"bezeichnung","item-value":"bezeichnung","loading":_vm.showLoading,"cacheItems":false,"no-data-text":_vm.$globalsBezeichnung(
                  _vm.Globals.Begriff,
                  'Web_keineDaten_gefunden',
                  'keine Daten gefunden'
                ),"dark":_vm.dark,"filter":_vm.filterFunction,"menu-props":"auto","hint":_vm.hintText,"persistent-hint":"","error-messages":_vm.errorText,"append-icon":_vm.showStichtag ? 'history' : '',"required":_vm.requiredRender()},on:{"change":_vm.selectedValueChanged,"focus":function($event){return _vm.scrollMeIntoView($event)},"click:append":_vm.loadStichtag},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('EditComboBoxItem',{style:('width:' + _vm.menuwith),attrs:{"item":item},on:{"changeFav":_vm.changeFav}})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e(),(_vm.showSelect && !_vm.useAutocomplete)?_c('v-select',{ref:"combobox",attrs:{"clearable":_vm.clearable,"dense":"","flat":"","label":_vm.description,"items":_vm.list,"disabled":!_vm.enabled,"item-text":"bezeichnung","item-value":"bezeichnung","loading":_vm.showLoading,"cacheItems":_vm.cacheItems,"no-data-text":_vm.$globalsBezeichnung(
                  _vm.Globals.Begriff,
                  'Web_keineDaten_gefunden',
                  'keine Daten gefunden'
                ),"filter":_vm.filterFunction,"menu-props":"auto","hint":_vm.hintText,"persistent-hint":"","error-messages":_vm.errorText,"append-icon":_vm.showStichtag ? 'history' : '',"required":_vm.requiredRender()},on:{"change":_vm.selectedValueChanged,"focus":function($event){return _vm.scrollMeIntoView($event)},"click:append":_vm.loadStichtag},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('EditComboBoxItem',{style:('width:' + _vm.menuwith),attrs:{"item":item},on:{"changeFav":_vm.changeFav}})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e(),(_vm.useAutocomplete)?_c('v-autocomplete',{attrs:{"items":_vm.list,"loading":_vm.showLoading,"search-input":_vm.search,"flat":"","dense":"","item-text":"bezeichnung","item-value":"bezeichnung","label":_vm.description,"placeholder":"Start typing to search","prepend-icon":"mdi-database-search","auto-select-first":"","hint":_vm.hintText,"persistent-hint":"","error-messages":_vm.errorText,"filter":_vm.filterFunction,"clearable":"","hide-no-data":"","append-icon":_vm.showStichtag ? 'history' : '',"required":_vm.requiredRender()},on:{"change":_vm.selectedValueChanged,"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"focus":function($event){return _vm.scrollMeIntoView($event)},"click:append":_vm.loadStichtag},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('EditComboBoxItem',{style:('width:' + _vm.menuwith),attrs:{"item":item},on:{"changeFav":_vm.changeFav}})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1),(_vm.barcodeButtonVisible)?_c('v-col',{staticClass:"noPadding",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"color":"green","text":""},on:{"click":_vm.scanBarcode}},[_vm._v("qr_code_scanner")])],1):_vm._e(),(_vm.showSettings)?_c('v-col',{staticClass:"pa-0",staticStyle:{"cursor":"hand"},attrs:{"cols":"1"},on:{"click":_vm.openSettings}},[_c('v-icon',{attrs:{"text":""}},[_vm._v("more_vert")])],1):_vm._e()],1)],1),_c('ValueChangeTracker')]}}],null,false,3093678680)},[_c('span',[_vm._v(_vm._s(_vm.tooltip))])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }