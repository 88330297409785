





























































import { getKZColumn, getValue } from "@/DataHelper";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AufgabeStatusContainer,
  getKZAufgabeDefiniton,
} from "./ChartTypesAndFunctions";

@Component
export default class AufgabeBoardItemCard extends Vue {
  @Prop() public as!: AufgabeStatusContainer;
  @Prop() public waitingForQVAics!: boolean;
  @Prop() public vcardClass!: string;
  @Prop() public QvAICs?: number[];
  @Prop({ default: false }) public QuickViewMode!: boolean;
  @Prop({ default: false }) private persoenlich!: boolean;
  @Prop() public aic!: number;
  private showTitle: boolean = true;
  private vector: number[] = [];

  private quickViewAicsLoaded(aics: number[]) {
    this.$emit("aicsLoaded", aics);
  }
  private mounted() {
    // @Stefan - kann man hier die Aufgabendefinition auch abfragen?
    if (this.as.params.aicStamm && this.as.params.aufgabenDefinition) {
      this.showTitle = !getKZAufgabeDefiniton(
        "hideTitelStatus",
        this.as.params.aicStamm,
        this.as.params.aufgabenDefinition,
        this.showTitle
      );
    }
    if (this.as.status.darstellungKennung === "KPI") {
      this.showTitle = false;
      this.$emit("hideAufgabeTitle");
    }
    if (!this.showTitle) {
      this.$emit("dashboardItemTitle", this.as.titel);
    }
  }
}
