



















































































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";

import EditPage from "@/components/EditPage";

import { Globals } from "@/Globals";
import CreateList from "@/components/CreateList";
import { getMonthNames } from "@/editor/CalenderHelper";
import {
  getValue,
  getKZTitle,
  getKZValueDisplay,
  getKennungValue,
  getColumnByKennung,
} from "@/DataHelper";
import { showSnackbar } from "@/UIHelper";
@Component
export default class MAUestAntrag extends CreateList {
  constructor() {
    super();
    this.queryMutable = "WebAnzeige_monatl_Zulagen";
  }
  // @Prop() public zeitbereich!: APIZeitbereich | null;
  private sperre: boolean = false;
  // @Prop() public aic: any;
  // @Prop({ default: 0 }) protected ampelZulageMutable!: number;
  public titleCard: string = "";
  protected myHeight: number = 900;
  private datenLeer: boolean = true;
  private iconAmpel: string = "";
  private aicBew: any = undefined;
  private modellAic: number = 0;
  private antrag!: Date | null;
  private showSave: boolean = false;

  private mounted() {
    try {
      const me = this;
      if (this.siteData.aic) {
        this.aic = this.siteData.aic;
      }
      this.modellAic = this.siteData.aic;
      if (this.zeitbereich) {
        this.editZeitbereich = {
          von: this.zeitbereich.von,
          bis: this.zeitbereich.bis,
          bereich: "Monat",
        };

        const day = this.editZeitbereich.von;
        this.titleCard = getMonthNames()[day.getMonth()];
      }
      const ampel = this.loadAmpel(
        "Web_ZE_Ampel_UestAntrag",
        false,
        true,
        this.siteData.aic
      );
      setTimeout(() => {
        this.setAmpel();
      }, 500);

      if (!this.mountedBase()) {
        return;
      }
    } catch (e: any) {
      console.error("mounterror: " + e, e?.stack);
    }
  }
  protected setValue(item: any, index: number, evt: any) {
    this.data[index][item.col.name] = evt;
  }
  private setAmpel() {
    if (this.ampelZulage === 1 || this.ampelZulage === 3) {
      this.sperre = true;
    }
    this.iconAmpel =
      "<img src='/" + this.ampelZulage + "_Ampel.png' alt='" + this.ampelZulage + "' />";
    this.showLoading = false;
  }
  protected onSave(item: any, index: number) {
    if (this.antrag === null) {
      const text = "bitte Daten vollständig erfassen";
      showSnackbar({
        text,
        duration: 4000,
        color: "error",
      });
      return;
    }
    this.showLoading = true;
    const me = this;
    this.aicBew = this.data[index].aic_Bew_pool;
    const x: any = this.data[index];
    const result = this.$api
      .SaveData(
        this.header,
        0,
        this.aicBew,
        x,
        this.columns,
        this.zeitbereich,
        null,
        (res: any) => me.saved(res),
        (ex: any) => {
          this.showLoading = false;
          let errorMessage = "";
          if (ex.response) {
            errorMessage = ex.response.data.error;
          } else {
            errorMessage = ex;
          }
          this.$root.$emit("alert", {
            text: errorMessage,
            type: "error",
          });
          console.error("ERROR: " + ex);
        },
        this.siteData.aic
      )
      .then(() => {
        this.reloadData();
      });
  }
  private updateDataForAsync: any = null;

  protected saved(res: any) {
    this.showLoading = false;
    this.showSave = false;
    if (!res.nothingHappend) {
      showSnackbar({
        text: this.$globalsBegriffMemo(
          Globals.Begriff,
          "Datensatz_gespeichert",
          "Daten wurden gespeichert"
        ),
        duration: 4000,
      });
    } else {
      showSnackbar(
        this.$globalsBegriffMemo(
          Globals.Begriff,
          "Datensatz_keine_Aenderung",
          "Daten wurden nicht verändert, kein Speichern durchgeführt!"
        )
      );
    }
  }
  private async onCalculated(args: any) {
    // debugger;
    this.showLoading = true;

    const ampel = await this.loadAmpel(
      "Web_ZE_Ampel_UestAntrag",
      false,
      true,
      this.siteData.aic
    );
    if (ampel) {
      this.setAmpel();
      this.reloadData();
      this.$forceUpdate();
    }
  }

  protected refreshItems() {
    if (this.data.length > 0) {
      this.datenLeer = false;
    }
    this.items = []; // ausleeren für refresh

    for (let i = 0; i < this.data.length; i++) {
      const row = this.data[i];
      let header = getKZValueDisplay(row, "header", this.columns);
      const title = getKZValueDisplay(row, "titel", this.columns);
      header = title + " h " + header;

      const col = getColumnByKennung("ZE_ANTRAG_ZULAGE", null, this.columns);
      const title2 = getValue(row, col);
      const subtitel = getKZValueDisplay(row, "subtitel", this.columns);
      let subtitelHeader = "";
      if (subtitel) {
        subtitelHeader = getKZTitle(row, "subtitel", this.columns);
      }

      const color = getKennungValue(row, "FARBE", this.columns);

      this.items.push({
        header,
        title,
        title2,
        subtitel,
        subtitelHeader,
        inset: true,
        aic: row.aic_Bew_pool,
        col,
        color,
      });
    }
  }
}
