import { render, staticRenderFns } from "./MAUest.vue?vue&type=template&id=49dcebaa&scoped=true&"
import script from "./MAUest.vue?vue&type=script&lang=ts&"
export * from "./MAUest.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49dcebaa",
  null
  
)

export default component.exports