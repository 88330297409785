














import CreateList from "../CreateList";

import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";

import { getKennungValueDisplay, getKZTitle, getKZValue } from "@/DataHelper";
import Constants from "@/Constants";
import { Globals } from "@/Globals";
@Component
export default class SparklineChart extends CreateList {
  @Prop({ default: false }) protected showTitle!: boolean;
  @Prop({ default: 400 }) protected fixHeight!: number;
  @Prop({ default: "white" }) public color!: string;
  private myHeight: number = 400;
  @Prop({}) protected chartDataMounted: any[] = [];
  public chartData: any = {};
  @Prop({ required: false }) public chartColumnsMounted: any = {};
  private chartColumns: any = {};
  private labels: any[] = [];
  private value: any[] = [];
  private title: string = "";
  private initalized: boolean = false;
  @Watch("chartDataMounted")
  private created() {
    if (this.fixHeight) {
      this.myHeight = this.fixHeight;
    } else {
      this.myHeight = window.innerHeight - 200;
    }
  }
  public mounted() {
    try {
      const me = this;
      if (this.chartDataMounted) {
        this.chartColumns = this.chartColumnsMounted;
        this.chartData = this.chartDataMounted;
      }
      if (!this.mountedBase()) {
        return;
      }
    } catch (e: any) {
      console.error("mounterror: " + e, e?.stack);
    }
  }
  protected refreshItems() {
    try {
      if (this.chartData.length > 0) {
        for (let i = 0; i < this.chartData.length; i++) {
          const row = this.data[i];
          const label = getKZTitle(row, "label", this.columns);
          const labelBez = getKennungValueDisplay(
            row,
            "labelBez",
            this.columns
          );
          // const image = getKZValueDisplay(row, "image", this.columns);
          const serie = getKZValue(row, "series", this.columns);
          const serie1 = getKZValue(row, "series1", this.columns);
          const serieText = getKZTitle(row, "series", this.columns);
          const serie1Text = getKZTitle(row, "series1", this.columns);
          if (labelBez) {
            this.labels.push(labelBez);
          } else if (label) {
            this.labels.push(label);
          }
          if (serieText) {
            this.labels.push(serieText);
          }
          if (serie1Text) {
            this.labels.push(serie1Text);
          }
          if (serie) {
            this.value.push(serie);
          }
          if (serie1) {
            this.value.push(serie1);
          }
        }
        this.initalized = true;
        this.showLoading = false;
      } else {
        this.showLoading = false;
      }
    } finally {
      this.$emit("dataloaded");
    }
  }
}
