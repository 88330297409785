















import { Vue, Component, Prop, Provide } from "vue-property-decorator";

@Component
export default class Security extends Vue {
  @Prop() public text!: string;
  private debug: number = 0;
  private abbruch: number = 0;
  private calcResult: string = "";
  private calcMessage: string = "";
  private calcInfo: string = "";
  private calcWarnung: string = "";
  private msgType: string = "";
  private header: string = "";
  private info: string = "";

  private success(res: any) {
    this.calcResult = res.data.ergebnis;
    res.data.text = this.text;
    this.$emit("calculated", res.data);
    // Meldung ausgeben die vom Modell geschickt werden
    this.calcMessage = res.data.error;
    this.msgType = res.data.msgType;
    this.header = res.data.header;
    this.info = res.data.info;

    if (this.msgType === "Error") {
      this.$root.$emit("alert", {
        text: this.info,
        type: "error"
      });
    } else if (this.msgType === "Info") {
      this.$root.$emit("info", {
        text: this.info,
        type: "error"
      });
    }
  }
  private calcFunction() {
    this.$api.postCalcDebug(this.abbruch, this.debug, this.success);
  }
  private noDebugFunction() {
    this.$api.postCalcNoDebug();
  }
}
