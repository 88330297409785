















































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import Constants from "@/Constants";
import { checkTokenRefresh } from "@/TokenHelper";
import { showDialog } from "@/UIHelper";
import { Globals } from "@/Globals";
import { onShowSite, showSite } from "@/NavigationHelper";
import { IsLoginHelperSet } from "@/LoginHelper/LoginHelper";
@Component
export default class UserMenu extends Vue {
  @Prop() protected tokenEnde!: any;
  private showToken: string = "";
  private printItems: PrintItems[] = [];
  private closeOnContentClick: boolean = true;

  private showLoading: boolean = false;

  private mounted() {
    this.showLoading = true;
    this.showToken = this.tokenEnde;
    this.addDruck();
  }
  private addDruck() {
    const stammTyp = this.$globalsStt(Globals.Stt, "", "ARBEITNEHMER");
    this.printItems = this.$globalsDruck("Begriff", true, stammTyp);
    this.printItems.sort(this.dynamicSort("bezeichnung"));
    this.showLoading = false;
  }
  private dynamicSort(property: any) {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return (a: any, b: any) => {
      const result =
        a[property].localeCompare < b[property].localeCompare
          ? -1
          : a[property].localeCompare > b[property].localeCompare
          ? 1
          : 0;
      return result * sortOrder;
    };
  }
  private openDruckVorschau(item: any) {
    const me = this;
    const printItem = item;
    let fullscreen = false;
    console.log("isPhone: " + this.$isPhone());
    if (Constants.isMobile && this.$isPhone()) {
      fullscreen = true;
    }

    showDialog({
      title: printItem.bezeichnung,
      site: "BaseDruck",
      fullscreen,
      width: 510,
      data: { zeitbereich: this.$api.zeitbereich, printItem, pers: true },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      onClose: () => {
        return true;
        /* nix tun*/
      },
    });
  }
  protected checkBegriff(kennung: string) {
    const x = this.$globalsKennung(this.Globals.Begriff, kennung, false);
    return x;
  }
  protected openSettings(formular: string, title: string) {
    onShowSite({
      site: formular,
      title,
    });
  }
  private loadToken() {
    const me = this;
    const res = checkTokenRefresh();
    this.$nextTick(() => {
      setTimeout(() => this.setToken(), 300);
    });
  }
  private setToken() {
    const res = this.$api.user.time;
    if (res) {
      const tokenEndeDate = new Date(res);
      const tokenEnde = tokenEndeDate.toLocaleTimeString();
      this.showToken = tokenEnde;
    }
  }
  private get isLoginHelperInUse() {
    return IsLoginHelperSet();
  }
}
