











































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditComponent from "@/editor/EditComponent";
import { NumericTextBoxComponent } from "@syncfusion/ej2-vue-inputs";

@Component
export default class EditZahl extends EditComponent {
  protected number: any = "0.00";
  protected format: string = "n2";
  protected decimals: number = 2;
  private ohneNachkomma: boolean = false;
  protected min: any = "";
  protected max: any = "";
  protected text: string = "";
  protected minmaxText: string = "";
  private customClass: string = "";
  // protected description: string = "00,00";

  public mounted() {
    const value = this.getInitValue();
    if (value && typeof value === "object") {
      this.number = value.value;
      if (value.faktor > 0) {
        this.number = value.value / value.faktor;
      }
    } else {
      this.number = this.getInitValue();
    }
    // darf ich nicht machen - weil sonst gilt es als "geändert" und Speichern wird aktiv!
    // if (this.number === null) {
    //   this.number = this.description;
    // }
    if (this.column && this.column.meta) {
      if (this.column.meta.format) {
        this.format = this.column.meta.format;

        if (this.format === "#") {
          this.ohneNachkomma = true;
          this.decimals = 2;
          // this.format = "n0";
          this.format = "n2"; // ohne Dezimal gehts nicht
        } else if (this.format === "00.00" || this.format === "0.00") {
          this.decimals = 2;
          this.format = "n2";
        } else if (this.format === "00.0000") {
          this.decimals = 4;
          this.format = "n4";
        }
      } else if (this.column.meta.HHmm) {
        this.format = "HH:mm";
      }
      if (this.number === null) {
        this.number = 0.0;
      }
      if (this.column.meta.minimum) {
        this.min = this.column.meta.minimum;
      }
      if (this.column.meta.maximum) {
        this.max = this.column.meta.maximum;
      }
    }
  }

  protected textChanged(txt: any) {
    const value = txt.value;
    const prev = txt.previousValue;
    let init = this.getInitValue();
    if (init && typeof init === "object") {
      init = init.value;
    }
    if (init === null) {
      init = 0;
    }
    if (init === value) {
      this.valueChanged(this.number, true);
    } else {
      const numberDoc = document.querySelector(".e-float-text");
      if (numberDoc) {
        // bringt einen Error??? wie kann ich den umgehen?
        // numberDoc.style.color = "red";
      }

      this.valueChanged(this.number, false);
    }
  }
  public validate() {
    if (this.disabled) {
      this.errorText = "";
      return true;
    } // disabled Controls sind IMMER valide ;)
    if (this.required) {
      if (this.number) {
        this.errorText = "";
        return true;
      }
      if (this.min || this.max) {
        this.errorText = "min: " + this.min + " max: " + this.max;
        return false;
      } else {
        this.errorText = this.requiredMessage;
        return false;
      }
    }
    if (this.min || this.max) {
      this.minmaxText = "min: " + this.min + " max: " + this.max;
      return true;
    }
    if (this.number || this.number === 0) {
      if (this.max && this.number > this.max) {
        this.errorText = this.$begriffBezeichnung("Web_Maximum");
        return false;
      }
      if (this.min && this.number < this.min) {
        this.errorText = this.$begriffBezeichnung("Web_Mimimum");
        return false;
      }
    }

    this.errorText = "";
    return true;
  }
}
