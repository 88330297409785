var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"col-md-6 col-lg-6"},[(_vm.initialized)?_c('v-card',{staticClass:"pt-0 px-0",attrs:{"height":_vm.contentHeight - 10 + 'px',"loading":_vm.showLoading}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"indeterminate":true,"color":"dvhSlider"}})],1),(_vm.initialized)?_c('v-card-title',{staticClass:"py-2 px-1",class:_vm.titleColor},[_c('div',{staticClass:"display-1 font-weight-regular white--text mr-2 pd2"},[_vm._v(" "+_vm._s(this.ueberschrift)+" ")]),_c('v-subheader',{staticStyle:{"color":"white","float":"right","padding":"0px","font-size":"12px","max-height":"10px"}},[_vm._v(" "+_vm._s(this.monat)+" ")]),_c('v-spacer'),_c('CommonBaseButtons',{attrs:{"deleteVisible":false,"createVisible":false,"refreshVisible":false,"saveVisible":false,"showBackText":false,"backIcon":"mdi-close","backColor":"white"}})],1):_vm._e(),_c('v-card-text',{staticClass:"pa-0"},[_c('v-card',{staticClass:"d-inline-block mx-auto pa-0",attrs:{"color":"lighten-2"}},[(_vm.showCalc)?_c('PzeWfVorgesetzterFreigabenTemplateMonatlFreigabe',{ref:"monatlFreigabe",attrs:{"zeitbereich":this.zeitbereich,"aic":this.editaic,"backOnSave":false}}):_vm._e(),(_vm.showCalc)?_c('v-card-actions',{staticClass:"px-0"},[_c('v-flex',{staticClass:"col-xs-12 col-md-12 col-lg-12 pa-0"},[_c('CalcButton',{attrs:{"modell":"Web_Einzelbewilligung_PZE","color":"success","icon":"done","zeitbereich":_vm.editZeitbereich,"text":_vm.$globalsBezeichnung(
                  _vm.Globals.Begriff,
                  'Web_Einzelbewilligung_PZE',
                  'Freigabe'
                ),"plain":true,"small":true,"modellAic":_vm.editaic,"vecStamm":_vm.vecStamm,"useVecStamm":false,"showDisabled":!_vm.showCalc},on:{"calculated":_vm.onCalculated}}),_c('CalcButton',{attrs:{"showDisabled":!_vm.showCalc,"modell":"Web_ZE_Ablehnung_Einzelbewilligung","color":"error","icon":"clear","plain":true,"small":true,"zeitbereich":_vm.editZeitbereich,"modellAic":_vm.editaic,"vecStamm":_vm.vecStamm,"useVecStamm":false,"text":_vm.$globalsBezeichnung(
                  _vm.Globals.Begriff,
                  'Web_ZE_Ablehnung_Einzelbewilligung',
                  'Ablehnung'
                )},on:{"calculated":_vm.onCalculated}})],1)],1):_vm._e()],1)],1),_c('v-divider'),_c('div',{style:('overflow-y:scroll;height:' + (_vm.contentHeight2 - 130) + 'px')},[(_vm.timeLine)?_c('v-timeline',{attrs:{"align-top":"","dense":true,"clipped":""}},_vm._l((_vm.items),function(item,index){return _c('v-timeline-item',{key:'c' + index,staticStyle:{"padding-bottom":"5px"},attrs:{"color":item.color,"icon":item.dot,"small":"","align-top":"","dense":"","fill-dot":""}},[(_vm.expandPanel)?_c('v-expansion-panels',{staticClass:"timeCard"},[(!item.expand)?_c('v-card',{staticClass:"cardText"},[_c('v-container',[_c('v-card-text',{staticClass:"cardText"},[_c('div',[_vm._v(" "+_vm._s(item.header)+" ")])])],1)],1):_vm._e(),(item.expand)?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"cardText2",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":item.color}},[_vm._v(" mdi-menu-down ")])]},proxy:true}],null,true)},[_c('v-row',{staticClass:"ml-0",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"9"}},[_vm._v(" "+_vm._s(item.header)+" ")]),_c('v-col',{staticClass:"cardText3",attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.title)+"h ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$globalsBezeichnung( _vm.Globals.Begriff, "Web_Tagessaldo", "Tagessaldo" )))])])],1)],1)],1),_c('v-expansion-panel-content',[_c('v-card',[_c('div',{staticClass:"caption"},[_vm._v(_vm._s(item.subtitel))]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.text)+" ")]),(item.showSaldo)?_c('div',{staticClass:"caption success--text"},[_vm._v(" "+_vm._s(_vm.$globalsBezeichnung(_vm.Globals.Begriff, "Soll", "Soll"))+" "+_vm._s(item.soll)+"h ")]):_vm._e(),(item.showSaldo)?_c('div',{staticClass:"caption success--text"},[_vm._v(" "+_vm._s(_vm.$globalsBezeichnung(_vm.Globals.Begriff, "Ist", "Ist"))+" "+_vm._s(item.ist)+"h ")]):_vm._e(),(
                      item.showSaldo &&
                      item.differenz &&
                      item.differenzValue >= 0
                    )?_c('div',{staticClass:"caption success--text"},[_vm._v(" "+_vm._s(_vm.$globalsBezeichnung( _vm.Globals.Begriff, "Web_Differenz", "Differenz" ))+" "+_vm._s(item.differenz)+"h ")]):_vm._e(),(
                      item.showSaldo &&
                      item.differenz &&
                      item.differenzValue < 0
                    )?_c('div',{staticClass:"caption error--text"},[_vm._v(" "+_vm._s(_vm.$globalsBezeichnung( _vm.Globals.Begriff, "Web_Differenz", "Differenz" ))+" "+_vm._s(item.differenz)+"h ")]):_vm._e(),(
                      item.showSaldo &&
                      item.title &&
                      item.tagessaldoValue >= 0
                    )?_c('div',{staticClass:"caption success--text"},[_vm._v(" "+_vm._s(_vm.$globalsBezeichnung( _vm.Globals.Begriff, "Web_Tagessaldo", "Tagessaldo" ))+" "+_vm._s(item.title)+"h ")]):_vm._e(),(
                      item.showSaldo && item.title && item.tagessaldoValue < 0
                    )?_c('div',{staticClass:"caption error--text"},[_vm._v(" "+_vm._s(_vm.$globalsBezeichnung( _vm.Globals.Begriff, "Web_Tagessaldo", "Tagessaldo" ))+" "+_vm._s(item.title)+"h ")]):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","text":"","small":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openBuchungen(_vm.editaic, item.day)}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("zoom_in")])],1)],1)],1)],1)],1):_vm._e()],1):_vm._e(),(!_vm.expandPanel)?_c('v-card',{staticClass:"timeCard"},[_c('v-container',{staticStyle:{"padding":"0px"}},[_c('v-card-text',{staticClass:"cardText"},[_c('div',[_vm._v(" "+_vm._s(item.header)+" ")]),(item.showSaldo)?_c('div',{staticClass:"caption success--text"},[_vm._v(" "+_vm._s(_vm.$globalsBezeichnung(_vm.Globals.Begriff, "Soll", "Soll"))+" "+_vm._s(item.soll)+"h ")]):_vm._e(),(item.showSaldo)?_c('div',{staticClass:"caption success--text"},[_vm._v(" "+_vm._s(_vm.$globalsBezeichnung(_vm.Globals.Begriff, "Ist", "Ist"))+" "+_vm._s(item.ist)+"h ")]):_vm._e(),(
                    item.showSaldo &&
                    item.differenz &&
                    item.differenzValue >= 0
                  )?_c('div',{staticClass:"caption success--text"},[_vm._v(" "+_vm._s(_vm.$globalsBezeichnung( _vm.Globals.Begriff, "Web_Differenz", "Differenz" ))+" "+_vm._s(item.differenz)+"h ")]):_vm._e(),(
                    item.showSaldo &&
                    item.differenz &&
                    item.differenzValue < 0
                  )?_c('div',{staticClass:"caption error--text"},[_vm._v(" "+_vm._s(_vm.$globalsBezeichnung( _vm.Globals.Begriff, "Web_Differenz", "Differenz" ))+" "+_vm._s(item.differenz)+"h ")]):_vm._e(),(
                    item.showSaldo && item.title && item.tagessaldoValue >= 0
                  )?_c('div',{staticClass:"caption success--text"},[_vm._v(" "+_vm._s(_vm.$globalsBezeichnung( _vm.Globals.Begriff, "Web_Tagessaldo", "Tagessaldo" ))+" "+_vm._s(item.title)+"h ")]):_vm._e(),(
                    item.showSaldo && item.title && item.tagessaldoValue < 0
                  )?_c('div',{staticClass:"caption error--text"},[_vm._v(" "+_vm._s(_vm.$globalsBezeichnung( _vm.Globals.Begriff, "Web_Tagessaldo", "Tagessaldo" ))+" "+_vm._s(item.title)+"h ")]):_vm._e(),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(item.subtitel))]),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(item.text))])])],1)],1):_vm._e()],1)}),1):_vm._e()],1)],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }