








































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import Constants from "@/Constants";
import { getKennungValueDisplay, getKennungValue } from "@/DataHelper";
import {
  DialogParameter,
  showDialog,
  showSnackbar,
  scrollIntoView,
  registerForKeyBoardEvent,
} from "@/UIHelper";
import { refreshFullPageContent } from "@/NavigationHelper";
import { getGeoLocation } from "@/GeoHelper";

@Component
export default class Stempelungen extends EditPage {
  private zeitbereichUebergabe: any;
  private style: string = "";
  constructor() {
    super();
    const d = new Date();
    const datumHeute = new Date(d.setHours(0, 0, 0, 0));
    this.zeitbereichUebergabe = {
      von: d,
      bis: d,
      bereich: "Tag",
    };
    // if (this.siteData.zeitbereich) {
    //   this.zeitbereich = this.siteData.zeitbereich;
    // }
    this.query = "WebLetzteStempelung";
    console.log("SoftTerm - letzte Stempelung geladen");
  }
  private anzeige: boolean = true;
  private letzteBuchung: string = "---";
  private letzteBuchungUm: string = "---";
  private letzteBuchungAic: string = "";
  private memo: string = "";
  private abwUhrzeit: Date | null = null;
  private format: string = "HH:mm";
  private enabledTime: boolean = false;
  private begriff: any = null;
  private absolute: boolean = true;
  private timeDate: Date = new Date();
  private showTimeField: boolean = true;
  private showCarousel: boolean = true;
  private uhrzeitBuchung: Date | null = null;
  @Prop({ default: false }) protected keinReiseBeginn!: boolean;
  private onlyRK: boolean = false;
  private checkGeoLocation: boolean = false;
  private response: string = "";

  public mounted() {
    if (Constants.isMobile) {
      this.showCarousel = false;
    }
    if (this.data) {
      const datenAnzeige = getKennungValueDisplay(
        this.data,
        "PHYS ZEITPUNKT",
        this.columns
      );
      if (datenAnzeige) {
        this.anzeige = false;
      }
    }
    if (this.checkBegriff("Koordinaten_dokumentieren")) {
      this.checkGeoLocation = true;
    }
    if (this.checkBegriff("Web_abweichendeUhrzeit")) {
      this.enabledTime = true;
    }
    if (!this.checkBegriff("SoftTerm_Stempelungen")) {
      this.onlyRK = true;
    }
  }

  private scrollMeIntoView(e: Event) {
    scrollIntoView(e);
  }

  private titleTime: string = "Software Terminal";
  private modell: string | null = null;
  private dialog: boolean = false;
  private dialog1: boolean = false;
  private dialog2: boolean = false;
  private dialog3: boolean = false;
  private expand: boolean = false;

  protected initialize() {
    if (this.data) {
      this.letzteBuchung = getKennungValueDisplay(
        this.data,
        "PHYS_STEMPELUNG",
        this.columns
      );
      this.letzteBuchungUm = getKennungValueDisplay(
        this.data,
        "PHYS_ZEITPUNKT",
        this.columns
      );
      this.letzteBuchungAic = getKennungValueDisplay(this.data, "SYSAIC", this.columns);
      this.uhrzeitBuchung = getKennungValue(this.data, "PHYS_ZEITPUNKT", this.columns);
    }
  }

  private dialogBack(arg: any) {
    if (arg) {
      this.letzteBuchung = arg.ergebnis;
    }
    this.dialog3 = false;
    this.reloadData();
  }
  private onCalculating(args: any) {
    // sicherheitshalber erst im nächsten tick ausleeren damit ma nicht gleich ein update im button auslösen..
    this.$nextTick(() => {
      this.memo = "";
      this.uhrzeitBuchung = this.abwUhrzeit;
      this.abwUhrzeit = null;
      this.showTimeField = false;
      this.$nextTick(() => (this.showTimeField = true));
    });
  }
  protected onCalculated(args: any) {
    this.response = JSON.stringify(args);
    if (args.aic) {
      this.uhrzeitBuchung = new Date();
      this.letzteBuchungAic = args.aic;
    } else {
      this.letzteBuchungAic = "";
    }
    this.letzteBuchung = args.text;
    this.letzteBuchung = this.letzteBuchung.slice(0, 20);
    const msgType = args.msgType;
    if (msgType) {
      this.letzteBuchung = msgType;
    }
    this.letzteBuchungUm = args.ergebnis;
    if (args.ergebnis) {
      this.letzteBuchung = "";
    }
    if (this.letzteBuchungUm === "" || this.letzteBuchungUm === undefined) {
      this.letzteBuchung = "";
    }
    this.$nextTick(() => {
      this.memo = "";
      this.abwUhrzeit = null;
      this.showTimeField = false;
      this.$nextTick(() => (this.showTimeField = true));
    });
  }
  public bewDelete(aic: any) {
    const jetzt = new Date();
    if (this.uhrzeitBuchung) {
      const buchung = new Date(this.uhrzeitBuchung);
      const differenz = jetzt.getTime() - buchung.getTime();
      if (differenz > 300000) {
        console.log("löschen der Stempelung nicht möglich");
        showSnackbar({
          text: "Löschen darf nur innerhalb von 5 Minuten durchgeführt werden",
          duration: 40000,
          color: "error",
        });
      } else {
        if (aic) {
          // es gibt fälle das der AIC der Stammsatz ist...
          // wir wissen aber nicht wann und warum - daher hier ein Sicherheitsnetz!
          if (aic !== this.$api.user.aic) {
            this.onDelete(aic, "");
            this.$api.fehlerProtokoll(
              "Stempelung loeschen richtig! Aic: " +
                aic +
                " Aic aus Abfrage: " +
                this.letzteBuchungAic +
                " Abfrage letzteBuchung: " +
                JSON.stringify(this.data) +
                " Response Stempelung: " +
                this.response
            );
            this.letzteBuchung = "";
            this.letzteBuchungUm = "";
            this.letzteBuchungAic = "";
          } else {
            this.$api.fehlerProtokoll(
              "Stempelung loeschen FALSCH! Aic: " +
                aic +
                " Aic aus Abfrage: " +
                this.letzteBuchungAic +
                " Abfrage letzteBuchung: " +
                JSON.stringify(this.data) +
                " Response Stempelung: " +
                this.response
            );
            this.letzteBuchung = "";
            this.letzteBuchungUm = "";
            this.letzteBuchungAic = "";
          }
        }
      }
    }
  }
  private async openBDE() {
    let showFullscreen = false;
    if (this.$isPhone()) {
      showFullscreen = true;
    }
    let keinBDEAntrag = true;
    const varUbergabe: any[] = [];

    const lokalOffset = new Date().getTimezoneOffset() * -1;
    const serverTime = this.$api.user.time;
    const serverOffset = Date.getISOTimeZoneOffsetInMin(serverTime);
    if (this.checkGeoLocation) {
      try {
        const position = await getGeoLocation();
        const latitute = position.latitude;
        const longitude = position.longitude;
        if (latitute !== 0) {
          varUbergabe.push({
            var: "gps",
            type: "gps",
            wert: { lat: latitute, lng: longitude },
            art: 1,
            user: this.$api.user.user,
            perm: false,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (lokalOffset !== serverOffset) {
      const newtimeZone = new Date("2021-01-01").getTimezoneOffset() * -1;
      varUbergabe.push({
        var: "differentTimeZone",
        type: "boolean",
        wert: true,
        art: 1,
        user: this.$api.user.user, // wirklich die user ID
        perm: false,
      });
      varUbergabe.push({
        var: "newtimeZone",
        type: "int",
        wert: newtimeZone,
        art: 1,
        user: this.$api.user.user,
        perm: false,
      });
      // this.$api.setVariable(varUbergabe, "");
    }

    if (this.abwUhrzeit) {
      keinBDEAntrag = false;
      const wert = new Date(this.abwUhrzeit).toISOStringWithTZ();
      varUbergabe.push({
        var: "WebabwUhrzeit",
        type: "string",
        wert,
        art: 1,
        user: this.$api.user!.user,
        perm: false,
      });
      // this.$api.setVariable(varUbergabe, "");
    }
    showDialog({
      data: {
        zeitbereich: this.zeitbereichUebergabe,
        keinBDEAntrag,
        varUbergabe,
      },
      title: this.$globalsBezeichnung(
        this.Globals.Begriff,
        "Web_BDE_Erfassung",
        "BDE Erfassung"
      ),
      titleColor: "dvhBackground",
      titleClass: "white--text",
      width: 400,
      fullscreen: showFullscreen,
      height: 400,
      site: "SoftTermSoftTermBde",
      okButton: false,

      onClose: (dlg: DialogParameter) => {
        const x = dlg?.returnedData;
        if (x) {
          this.letzteBuchungUm = "";
          this.letzteBuchung = x.ergebnis;
          this.letzteBuchungAic = x.aic;
          this.uhrzeitBuchung = this.abwUhrzeit;
        } else {
          setTimeout(() => this.reloadData());
        }
        // this.$api.deleteVariable();
        this.memo = "";
        this.abwUhrzeit = null;
        this.showTimeField = false;
        this.$nextTick(() => (this.showTimeField = true));
        return true;
      },
    });
  }
  private async dienstreiseBeginn(site: string, ende: boolean) {
    let title = "";
    if (ende) {
      title = this.$globalsBezeichnung(
        this.Globals.Begriff,
        "Web_RK_DR_Ende",
        "Dienstreise Ende"
      );
    } else {
      title = this.$globalsBezeichnung(
        this.Globals.Begriff,
        "Web_RK_DR_Beginn",
        "Dienstreise Beginn"
      );
    }
    let showFullscreen = false;
    if (Constants.isMobile || this.$isPhone()) {
      showFullscreen = true;
    }
    const varUbergabe: any[] = [];
    if (this.checkGeoLocation) {
      try {
        const position = await getGeoLocation();
        const latitute = position.latitude;
        const longitude = position.longitude;
        if (latitute !== 0) {
          varUbergabe.push({
            var: "gps",
            type: "gps",
            wert: { lat: latitute, lng: longitude },
            art: 1,
            user: this.$api.user.user,
            perm: false,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (this.abwUhrzeit) {
      const wert = new Date(this.abwUhrzeit).toISOStringWithTZ();
      varUbergabe.push({
        var: "WebabwUhrzeit",
        type: "string",
        wert,
        art: 1,
        user: this.$api.user.user,
        perm: false,
      });
      // this.$api.setVariable(varUbergabe, "");
      showDialog({
        data: { zeitbereich: this.zeitbereichUebergabe, varUbergabe },
        title,
        titleColor: "dvhBackground",
        titleClass: "white--text",
        width: 500,
        fullscreen: showFullscreen,
        height: 400,
        site,
        okButton: false,

        onClose: (dlg: DialogParameter) => {
          const x = dlg?.returnedData;
          if (x) {
            this.letzteBuchung = x.ergebnis;
          }
          // this.$api.deleteVariable();
          this.memo = "";
          this.abwUhrzeit = null;
          this.showTimeField = false;
          this.$nextTick(() => (this.showTimeField = true));
          this.reloadData();
          // ReiseAnzeige refreshen!
          setTimeout(() => refreshFullPageContent());
          return true;
        },
      });
    } else {
      const me = this;
      showDialog({
        data: { zeitbereich: this.zeitbereichUebergabe, varUbergabe },
        title,
        titleColor: "dvhBackground",
        titleClass: "white--text",
        fullscreen: showFullscreen,
        width: 400,
        height: 400,
        site,
        okButton: false,

        onClose: (x: any) => {
          if (x) {
            this.letzteBuchung = x.ergebnis;
            this.memo = "";
            this.abwUhrzeit = null;
            this.showTimeField = false;
            this.$nextTick(() => (this.showTimeField = true));
            // ReiseAnzeige refreshen!
            me.reloadData();
            // variable darf nciht zu schnell gelöscht werden da modell eventuell nicht fertig!
            setTimeout(() => refreshFullPageContent());
            return true;
          } else {
            this.memo = "";
            this.abwUhrzeit = null;
            this.showTimeField = false;
            me.$nextTick(() => (this.showTimeField = true));
            me.reloadData();
            // ReiseAnzeige refreshen!
            setTimeout(() => refreshFullPageContent());
            return true;
          }
        },
      });
    }
  }
  private async ortswechsel(ort: boolean, fahrzeug: boolean) {
    let showFullscreen = false;
    if (this.$isPhone()) {
      showFullscreen = true;
    }
    let title = this.$globalsBezeichnung(
      this.Globals.Begriff,
      "Web_RK_Grenzuebertritt",
      "Grenzübertritt"
    );
    const site = "SoftTermReisenGrenzuebertritt";
    let query = "WEB_RK_Ortswechsel_RZ";
    if (ort) {
      title = this.$globalsBezeichnung(
        this.Globals.Begriff,
        "Web_RK_Ortswechsel",
        "Zwischenstopp"
      );
      // site = "SoftTermReisenOrtswechsel";
      query = "Web_RK_Zwischenstop";
    }
    const varUbergabe: any[] = [];
    if (this.checkGeoLocation) {
      try {
        const position = await getGeoLocation();
        const latitute = position.latitude;
        const longitude = position.longitude;
        if (latitute !== 0) {
          varUbergabe.push({
            var: "gps",
            type: "gps",
            wert: { lat: latitute, lng: longitude },
            art: 1,
            user: this.$api.user.user,
            perm: false,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (fahrzeug) {
      title = this.$globalsBezeichnung(
        this.Globals.Begriff,
        "Web_RK_Fahrzeugwechsel",
        "Fahrzeugwechsel"
      );
      varUbergabe.push({
        var: "Fahrzeugwechsel",
        type: "boolean",
        wert: true,
        art: 1,
        user: this.$api.user.user,
        perm: false,
      });
    }
    if (this.abwUhrzeit) {
      const wert = new Date(this.abwUhrzeit).toISOStringWithTZ();
      varUbergabe.push({
        var: "WebabwUhrzeit",
        type: "string",
        wert,
        art: 1,
        user: this.$api.user.user,
        perm: false,
      });
      // this.$api.setVariable(varUbergabe, "");
      showDialog({
        data: {
          zeitbereich: this.zeitbereichUebergabe,
          query,
          ort,
          fahrzeug,
          varUbergabe,
        },
        title,
        titleColor: "dvhBackground",
        titleClass: "white--text",
        width: 400,
        fullscreen: showFullscreen,
        height: 400,
        site,
        okButton: false,

        onClose: (x: any) => {
          if (x) {
            this.letzteBuchung = x.ergebnis;
          } else {
            setTimeout(() => this.reloadData());
          }
          // this.$api.deleteVariable();
          this.memo = "";
          this.abwUhrzeit = null;
          this.showTimeField = false;
          this.$nextTick(() => (this.showTimeField = true));
          return true;
        },
      });
    } else {
      const me = this;
      // if (fahrzeug) {
      //   this.$api.setVariable(varUbergabe, "");
      // }
      showDialog({
        data: {
          zeitbereich: this.zeitbereichUebergabe,
          query,
          ort,
          fahrzeug,
          varUbergabe,
        },
        title,
        titleColor: "dvhBackground",
        titleClass: "white--text",
        width: 400,
        fullscreen: showFullscreen,
        height: 400,
        site,
        okButton: false,
        onClose: (x: any) => {
          // this.$api.deleteVariable();
          if (x) {
            this.letzteBuchung = x.ergebnis;
            this.memo = "";
            this.abwUhrzeit = null;
            this.showTimeField = false;
            this.$nextTick(() => (this.showTimeField = true));
            return true;
          } else {
            this.memo = "";
            this.abwUhrzeit = null;
            this.showTimeField = false;
            me.$nextTick(() => (this.showTimeField = true));
            me.reloadData();
            return true;
          }
        },
      });
    }
  }
  protected onCancel() {
    this.$emit("back");
  }
}
