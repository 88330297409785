<template>
  <v-card raised width="90%">
    <baseVCalendar query="Web_Planung_Reisen" />

  </v-card>
</template>
<script>
export default {
  data() {
    return {
      aic: 0
    };
  }
};
</script>

<style scoped></style>
