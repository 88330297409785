



















import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";

@Component
export default class BaseGridMask extends EditPage {
  // @Prop({ default: null }) protected zeitbereich!: APIZeitbereich | null;
  // @Prop() protected query!: string;
  @Prop() protected text!: string;
  constructor() {
    super();

    this.showLoading = true;
  }
}
