


































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import {
  InPlaceEditorComponent,
  InPlaceEditorPlugin
} from "@syncfusion/ej2-vue-inplace-editor";
Vue.component(InPlaceEditorPlugin.name, InPlaceEditorComponent);
// import EditComponent from "@/editor/EditComponent";
import EditTextField from "./EditTextField.vue";

@Component
export default class EditInPlace extends EditTextField {
  constructor() {
    super();
  }
  protected type: string = "Text";

  protected text: string = "";

  public mounted() {
    this.text = this.getInitValue();
  }
  private model: any = {
    placeholder: "Platzhalter"
  };
  private textPopupSettings: any = {
    title: "Enter",
    model: {
      animation: {
        open: { effect: "ZoomIn", duration: 1000, delay: 0 }
      }
    }
  };
}
