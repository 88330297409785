

















import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { Globals } from "@/Globals";

@Component
export default class Accordion extends Vue {
  @Prop() private showGrid!: boolean; // Parameter ob Grid oder BaseEditor angezeigt werden soll
  @Prop() private query!: string; // Abfrage für Grid
  @Prop() private showList!: boolean;
  @Prop() private text!: string;
  @Prop() public aic!: number | undefined;

  @Prop({ default: null }) protected zeitbereich!: APIZeitbereich | null;
  private header: string = "";

  public mounted() {

    this.header = this.$begriffBezeichnung(this.text);
  }
}
