

































































































































































































































































































































































import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { getMonthNames } from "@/editor/CalenderHelper";
import { Globals } from "@/Globals";
import CreateList from "../CreateList";
import Constants from "@/Constants";
import { getKZValueDisplay, getKennungValue, getKZValue } from "@/DataHelper";
import { showDialog } from "@/UIHelper";
import { onShowSite } from "@/NavigationHelper";

@Component
export default class VTimeLineMonth extends CreateList {
  @Prop({ default: null }) private day!: Date | null;
  @Prop({ default: 300 }) public contentHeight!: number;
  @Prop() protected expandPanel!: boolean;

  private get monat() {
    if (this.day) {
      return getMonthNames()[this.day.getMonth()];
    }
    return "-";
  }
  private get year() {
    if (this.day) {
      return this.day.getFullYear().toString().substr(-2);
    }
    return "-";
  }
  private timeLine: boolean = false;
  private window: any = 0;

  private editaic: any;
  private innerHeight: number = 199;
  private showDialog: boolean = false;
  private showDialog1: boolean = false;
  private lastRefresh: string | null = null;

  private printBtnVisible: boolean = false;
  private printLohn: boolean = false;
  private zulagenBtnVisible: boolean = false;
  private showDef: boolean = false;
  private titleColor = "dvhBackground";
  private printItems!: PrintItems;
  public mounted() {
    try {
      this.refreshView();
      if (!this.mountedBase()) {
        return;
      }
    } catch (e: any) {
      console.error("mounterror: " + e, e?.stack);
    }
  }

  @Watch("day")
  public refreshView() {
    if (!this.day) {
      this.day = this.$api.zeitbereich.von;
    }
    const dayVon = this.day.firstOfMonth();
    const dayBis = this.day.lastInMonth();
    const me = this;

    this.editZeitbereich = {
      von: dayVon,
      bis: dayBis,
      bereich: "Monat",
    };

    if (this.$api.user.rechte === "Def") {
      this.showDef = true;
      this.zulagenBtnVisible = true;
    }
    // soll StatusAmpel setzen

    this.zulagenLoaded();
    this.statusZeitaufzeichnung();
    this.printLohnLoaded();
    this.reloadData();
  }

  protected dataLoaded(data: any) {
    this.dataLoadedBase(data);
  }

  protected refreshItems() {
    this.items = []; // ausleeren für refresh
    if (this.data.length > 0) {
      this.timeLine = true;
    }
    for (let i = 0; i < this.data.length; i++) {
      const row = this.data[i];
      let dot = "mdi-dot";

      const antrag = getKZValueDisplay(row, "Antrag", this.columns);

      let avatar = getKZValueDisplay(row, "avatar", this.columns);
      const header = getKZValueDisplay(row, "header", this.columns);
      const day = getKZValue(row, "header", this.columns);
      const title = getKZValueDisplay(row, "titel", this.columns);
      const subtitel = getKZValueDisplay(row, "subtitel", this.columns);
      const soll = getKZValueDisplay(row, "Soll", this.columns);
      const ist = getKZValueDisplay(row, "Ist", this.columns);
      const differenz = getKZValueDisplay(row, "Differenz", this.columns);
      const differenzValue = getKennungValue(row, "BERECHNUNG_MASS_3", this.columns);
      const tagessaldoValue = getKennungValue(row, "BERECHNUNG_MASS", this.columns);
      const text = getKZValueDisplay(row, "text", this.columns);
      const icon = getKZValueDisplay(row, "icon", this.columns);
      let color = getKZValueDisplay(row, "color", this.columns);
      let showSaldo = true;
      if (antrag === "1.00") {
        dot = "verified_user";
      }
      if (!color) {
        color = "green";
      }
      if (avatar) {
        const start = avatar.indexOf('"') + 1;
        const len = avatar.lastIndexOf('"') - start;
        avatar = avatar.substr(start, len);
      } else if (!avatar) {
        avatar = "mdi-account-circle";
      }
      if ((soll === 0 && ist === 0) || (soll === "00:00" && ist === "00:00")) {
        showSaldo = false;
      }
      let expand = false;
      if (this.expandPanel) {
        if (text || tagessaldoValue || differenzValue) {
          expand = true;
        }
      }
      this.items.push({
        header,
        title,
        subtitel,
        text,
        soll,
        ist,
        differenz,
        differenzValue,
        tagessaldoValue,
        avatar,
        icon,
        divider: true,
        inset: true,
        color,
        aic: row.aic_Bew_pool,
        menu: false,
        dot,
        showSaldo,
        expand,
        day,
      });
    }
  }
  private onCalculated(args: any) {
    // nach der Berechnung alles neu laden!
    this.$nextTick(() => {
      this.zulagenLoaded();
      this.statusZeitaufzeichnung();
      this.reloadData();
    });
  }

  private onDialogClose() {
    this.showDialog1 = false;
    this.loadAmpel("Web_ZE_Ampel_UestAntrag");
    // this.reloadData();
  }
  private printListLoaded(args: any) {
    // wenn es mindestens ein item gibt dann zeigen wirs an
    this.printBtnVisible = args && args.items && args.items.length > 0;
  }
  private async printLohnLoaded() {
    this.printItems = this.checkBegriff("LV_Pers_Lohn_Gehaltsabrechnung");
    if (this.printItems) {
      const query = "Web_LV_BewilligungLZ";
      const me = this;
      // const vecStamm: any[] = [];
      const response = await this.$api.getQuery(
        query,
        undefined,
        this.queryAIC,
        this.editZeitbereich,
        0,
        false,
        false,
        "",
        0,
        this.vecStamm
      );
      const data = response.data.data;
      if (data.length > 0) {
        this.printLohn = true;
      }
    }
  }
  private openDruckVorschau() {
    const me = this;
    const printItem = this.printItems;
    let fullscreen = false;
    console.log("isPhone: " + this.$isPhone());
    if (Constants.isMobile && this.$isPhone()) {
      fullscreen = true;
    }
    showDialog({
      title: printItem.bezeichnung,
      site: "BaseDruck",
      fullscreen,
      width: 510,
      data: {
        zeitbereich: this.editZeitbereich,
        printItem,
        pers: printItem.pers,
        noSelect: true,
      },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      onClose: () => {
        return true;
        /* nix tun*/
      },
    });
  }
  private statusZeitaufzeichnung() {
    this.loadAmpel("ZE_Ampel_Zeitaufzeichnung", false, true);
  }
  private zulagenLoaded() {
    const zulagenShow = this.checkBegriff("WebAnzeige_monatl_Zulagen");
    if (zulagenShow) {
      const zulagenQuery = "WebAnzeige_monatl_Zulagen";
      const me = this;
      // const vecStamm: any[] = [];
      this.$api.getQuery(
        zulagenQuery,
        (data: any) => me.dataLoadedZulagen(data),
        this.queryAIC,
        this.editZeitbereich,
        0,
        false,
        false,
        "",
        0,
        this.vecStamm
      );
    }
  }
  private dataLoadedZulagen(data: any) {
    if (data.data.error !== undefined) {
      this.$api.onfail(data.data.error);
      console.log(data.data.error);
      alert("ACHTUNG: " + data.data.error);
      this.$emit("back");
      return;
    }
    const datenZulagen = data.data.data;
    this.zulagenBtnVisible = datenZulagen.length > 0;
    this.loadAmpel("Web_ZE_Ampel_UestAntrag");
  }
  private openMonatsSalden() {
    if (!this.day) {
      return;
    }
    let showFullscreen = false;
    if (this.$isPhone()) {
      showFullscreen = true;
    }
    const me = this;
    showDialog({
      title: getMonthNames()[this.day.getMonth()],
      site: "PzePersoenlichWfWorkflowSalden",
      width: 450,
      fullscreen: showFullscreen,
      data: { zeitbereich: this.editZeitbereich },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      onClose: () => {
        return true;
        // hier kein Refresh notwendig!!!
      },
    });
  }
  private openBuchungen(day: any) {
    if (!day) {
      return;
    }
    const me = this;
    const dayIso = new Date(day);
    const dialogZeitbereich = {
      von: dayIso,
      bis: dayIso,
      bereich: "Tag",
    };
    onShowSite({
      title: "",
      site: "SoftTermBuchungen",
      data: { zeitbereich: dialogZeitbereich, goDate: day },
      // titleClass: "white--text",
      callOnLeave: () => {
        return true;
      },
    });
  }
}
