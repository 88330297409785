



















import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";

@Component
export default class EditStamm extends EditPage {
  public showClose: boolean = true;
  public mounted() {
    if (this.siteData) {
      this.showClose = this.siteData.showClose;
    }
    if (!this.query) {
      return;
    }
  }
  private onReloadData() {
    this.siteData.onReloadData = true;
  }
}
