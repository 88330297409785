






























import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import {
  GridPlugin,
  Resize,
  Sort,
  Reorder,
  VirtualScroll
} from "@syncfusion/ej2-vue-grids";
import { reloadApp } from "@/NativeUpdateProvider";

@Component
export default class Security extends Vue {
  private data: any[] = this.$api.Security;
  private editSettings: any = {
    allowEditing: false,
    allowAdding: false,
    allowDeleting: false,
    mode: "Normal"
  };
  private handleAllLogout() {
    // console.log("logout" + " " + this.Benutzer);
    this.$api.logoutall().then((response: any) => {
      reloadApp(true);
    });
    return;
  }
}
// webLog/security
