import AUApi from './api';
import { Capacitor, Plugins } from "@capacitor/core";

const { FileHelperPlugin } = Plugins;

export function showPDF(pdfFile: string) {
    const url = AUApi.Current.fullUrl("pdf3/" + pdfFile, "", false);
    if (Capacitor.getPlatform() !== "android") {
        const res = window.open(url);
    } else {
        return FileHelperPlugin.downloadAndOpenFile({
            url,
            filename: "report.pdf",
            mimeType: "application/pdf"
        });
    }
}
export function showPDF2(pdfFile: string, pdfAic: string, user: string) {
    const url = AUApi.Current.fullUrl("pdf2/" + user + ":" + +pdfAic + "_" + pdfFile
    );
    if (Capacitor.getPlatform() !== "android") {
        const res = window.open(url);
    } else {
        return FileHelperPlugin.downloadAndOpenFile({
            url,
            filename: "report.pdf",
            mimeType: "application/pdf"
        });
    }
}
export function showImage(image: any, pdfAic: string, user: string) {
    let url = AUApi.Current.fullUrl("pdf2/" + user + ":" + +pdfAic + "_" + image.name);
    if (image.datentyp !== "Doku") {
        url = AUApi.Current.fullUrl("image2/" + user + ":" + +pdfAic + "_" + image.name);
    }
    if (Capacitor.getPlatform() !== "android") {
        const res = window.open(url);
    } else {
        return FileHelperPlugin.downloadAndOpenFile({
            url,
            filename: "report.pdf",
            mimeType: "application/pdf"
        });
    }
}
