








































































import { Component, Watch } from "vue-property-decorator";
import AUApi from "@/api";
import { getKZTitle, getKZValue, getKZValueDisplay } from "@/DataHelper";
import Constants from "@/Constants";
import ChartBase from "./ChartBase";
import BaseQueryChart from "./BaseQueryChart";
import { setQuickviewSelectionCallBack } from "../ChartTypesAndFunctions";
import { onShowSite } from "@/NavigationHelper";

declare interface InfoData {
  header: string;
  title: string;
  titleText: string;
  subtitel: string;
  subtitelText: string;
  text: string;
  aic: number;
  show: boolean;

  selected: boolean;
}

@Component
export default class QuickInfo extends BaseQueryChart {
  protected noData: boolean = false;

  protected responsiveOptions?: any;

  protected infoDataTotal: InfoData[] = [];
  protected infoData: InfoData[] = [];
  private showInfo: boolean = false;
  private subFrame!: string;

  public mounted() {
    setQuickviewSelectionCallBack(v => this.onCheckAll(v));
    this.refresh();
  }

  private onCheckAll(val: boolean) {
    this.infoData.forEach(i => {
      i.selected = val;
    });
    this.selectionChanged();
  }

  private get showDebug() {
    return Constants.isDebug;
  }
  public dataLoaded(data: QueryResponse) {
    try {
      this.$emit("buildFilter", data);
      const aics: number[] = [];
      this.infoData = [];
      for (let i = 0; i < data.data.length; i++) {
        const row = data.data[i];
        const header = getKZValueDisplay(row, "header", data.columns); // TODO Kennung
        const title = getKZValueDisplay(row, "titel", data.columns);
        let titleText = "";
        if (title) {
          titleText = getKZTitle(row, "titel", data.columns);
        }
        const subtitel = getKZValueDisplay(row, "subtitel", data.columns);
        let subtitelText = "";
        if (subtitel) {
          subtitelText = getKZTitle(row, "subtitel", data.columns);
        }
        const text = getKZValueDisplay(row, "text", data.columns);
        let aic = getKZValue(row, "modellAic", data.columns);
        const show = false;
        if (!aic || aic === undefined) {
          if (row.aic_Bew_pool) {
            aic = row.aic_Bew_pool;
          } else {
            aic = row.aic_Stamm;
          }
        }
        aics.push(aic);
        const item: InfoData = {
          header,
          title,
          titleText,
          subtitel,
          subtitelText,
          text,
          aic,
          show,
          selected: false
        };
        this.infoDataTotal.push(item);

        this.lastQueryResponse = data;

        if ((data?.data?.length ?? 0) === 0) {
          this.noData = true;
          return;
        }
      }
      const headerData = data.header;
      if (headerData.fomDetail) {
        this.subFrame = headerData.fomDetail.alias ?? headerData.fomDetail.kennung;
      }

      this.$emit("aicsLoaded", aics);
    } finally {
      this.showLoading = false;
    }
  }

  public QvAICsChanged(): void {
    // }

    // @Watch("QvAICs")
    // protected refreshAics() {
    this.infoData = this.infoDataTotal.filter(
      i => this.QvAICs!.indexOf(i.aic) >= 0
    );
  }
  private openFrame(item: ListItems) {
    if (this.subFrame) {
      const aic = item.aic;
      onShowSite({
        title: "",
        site: this.subFrame,
        data: { aic },
        callOnLeave: () => {
          return true;
        }
      });
    }
    // console.log(this.lastQueryResponse);
  }
  private logData() {
    // console.log(this.lastQueryResponse);
  }

  private selectionChanged() {
    this.vector = this.infoData.filter(i => i.selected).map(i => i.aic);
    this.$emit("vectorChanged", this.vector);
  }
}
