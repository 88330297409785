<template>

  <v-card raised width="90%">
    <baseSyncGrid :pagZeilen="20" query="Web_FehlerListe_Stempelungen"/>
    </v-card>
</template>
<script>
export default {
  data() {
    return {
      aic: 0
    };
  }
};
</script>

<style scoped>
</style>
