


















import { Component} from "vue-property-decorator";
import BaseQueryChart from "./BaseQueryChart";

@Component
export default class Tabelle extends BaseQueryChart {
  private tableData?: QueryResponse;
  private initialized = false;
  public mounted() { this.refresh(); }

  public dataLoaded(data: QueryResponse) {
    this.tableData = data;
    this.initialized = true;
  }
}
