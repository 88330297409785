































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
// import { getDeviceInfo } from "@/DeviceInfoPlugin";

@Component
export default class SystemInfo extends Vue {
  private showLoading: boolean = false;
  private infoData: any[] = [];
  private deviceInfo: string = "";
  private deviceInfo2: any = "";

  public mounted() {
    const me = this;
    this.showLoading = true;
    // this.deviceInfo = JSON.stringify(getDeviceInfo());
    // getDeviceInfo(true)
    //   .then((t) => {
    //     me.deviceInfo2 = JSON.stringify(t);
    //   })
    //   .catch((e) => {
    //     me.deviceInfo2 = "Error" + e;
    //   });
    this.getInfos();
  }
  private async getInfos() {
    this.infoData = await this.$api.getInfos();
    this.showLoading = false;
  }
}
