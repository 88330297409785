<template>
  <v-container grid-list-md>
    <v-layout row wrap>
      <v-flex xs12>
        <h1>TestEditor</h1>
        <editComboBox
          title="editComponent"
          :queryColumn="editSimulationColumn"
          :initValue="editComponent"
          v-model="editComponent"
          :fireOnText="true"
        />
      </v-flex>
      <v-flex xs6>
        <h2>Control {{editComponent.aic}}</h2>
        <v-card class="cardpadding" >
          <component
            :is="editComponent.aic"
            :initValue="editValue"
            :queryColumn="editColumn"
            :title="'test' + editComponent.aic"
            v-model="editValue"
            @addValidation="addValidation"
          />
        </v-card>
      </v-flex>
      <v-flex xs6>
        <v-layout row wrap>
          <h2>Simulation</h2>
          <v-flex xs12>
            <v-btn class="md-raised md-primary" @click="resetControl">
              <v-icon>refresh</v-icon>Reset Control
            </v-btn>
            <v-btn class="md-raised md-primary" @click="onValidate">
              <v-icon>star</v-icon>Validieren
            </v-btn>
            <v-btn class="md-raised md-primary" @click="onSave">
              <v-icon>save</v-icon>Speichern
            </v-btn>
          </v-flex>
        </v-layout>
        <h2>Settings</h2>
        <v-card class="cardpadding">
          <v-layout row wrap>
            <v-flex>
              Value:
              {{ editValue }}
              <v-textarea v-model="editValue" />

              <v-btn
                v-for="(val, ix) in editColumn.meta.combo"
                :key="ix"
                @click="editValue = val"
              >Setze {{ val.bezeichnung }}</v-btn>

              <v-checkbox label="required" v-model="editColumn.meta.required"></v-checkbox>
              <v-checkbox label="readonly" v-model="editColumn.meta.readOnly"></v-checkbox>
            </v-flex>
            <v-flex xs6>
              <v-text-field type="number" label="min" v-model="editColumn.meta.minimum" />
            </v-flex>
            <v-flex xs6>
              <v-text-field type="number" label="max" v-model="editColumn.meta.maximum" />
            </v-flex>
            <v-flex xs12>
              <v-text-field type="text" label="tooltip" v-model="editColumn.meta.tooltip" />
            </v-flex>
            <v-flex xs12>
              <v-text-field type="text" label="format" v-model="editColumn.meta.format" />
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";

const requireComponent = require.context(
  // The relative path of the components folder
  ".",
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /Edit.+\.(vue|js)$/
);

const editcomponents = {};
const componentsSelect = [];
let count = 0;
console.log("lade Edit Komponenten:");
requireComponent.keys().forEach(fileName => {
  count++;
  // Get component config
  const componentConfig = requireComponent(fileName);
  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Strip the leading `./` and extension from the filename
      fileName.replace(/^\.\/(.*)\.\w+$/, "$1")
    )
  );

  console.log(fileName + " -> " + componentName);
  componentsSelect.push({ aic: componentName, bezeichnung: componentName });
  // Register component globally
  editcomponents[componentName] =
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig;
});
console.log("habe " + count + " Edit Komponenten geladen");

export default {
  components: editcomponents,
  data() {
    return {
      phone: "",
      editColumn: {
        datatype: "Gruppe",
        meta: {
          required: true,
          minimum: 3,
          maximum: 33,
          format: "HH:mm dd.MM.yyyy",
          combo: [
            {
              bezeichnung: "ledig",
              aic: 5429,
              beschreibung: "hallo ich bin ledig!"
            },
            {
              bezeichnung: "geschieden",
              aic: 5430
            },
            {
              bezeichnung: "verheiratet",
              aic: 5431
            },
            {
              bezeichnung: "verwitwet",
              aic: 5432
            }
          ]
        },
        name: "e330",
        title: "Familienstand",
        type: "vonBis"
      },
      editValue: "2019-01-01Z00:00",
      // {
      //   faktor: 3600,
      //   value: 3600
      // },
      /* {
        bezeichnung: "ledig",
        aic: 5429
      }*/
      editComponent: {
        aic: "EditNone",
        bezeichnung: "EditNone"
      },
      editSimulationColumn: {
        meta: {
          combo: componentsSelect,
        }
      },
      editComponents: componentsSelect,

      validations: [],
      thisIsAnEditPage: true,
      validatorEnabled: true
    };
  },
  computed: {},
  methods: {
    onSave() {
      console.log("onsave...");
    },
    isValueChangeTrackerEnabled() { return this.validatorEnabled; },
    addValidation(v) {
      this.validations.push(v);
    },
    onValidate() {
      for (let i = 0; i < this.validations.length; i++) {
        const val = this.validations[i];
        const res = val.isValid();

        console.log("field " + val.field.column.name + " -> " + res);
      }
    },
    resetControl() {
      this.validations = [];
      const comp = this.editComponent;
      this.editComponent = { aic: "EditNone", bezeichnung: "EditNone" };
      const me = this;
      setTimeout(() => {
        me.editComponent = comp;
      }, 200);
    },
    onChangeControl(val) {
      this.editComponent = val;
    }
  }
};
</script>
<style scoped>
.cardpadding {
  padding: 20px;
}
</style>
