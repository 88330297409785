


































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import PDFJS from "pdfjs-dist";
import pdf from "vue-pdf";

@Component({
  components: { pdf }
})
export default class extends Vue {
  private src: any = null;
  private show = true;
  private loadedRatio = 0;
  private page = 1;
  private numPages = 1;
  private rotate = 0;

  @Prop() public srcurl: any;
  @Prop() public siteData: any;
  private docUrl: any;
  private backAfterPrint: boolean = false;

  public mounted() {
    if (this.srcurl) {
      this.docUrl = this.srcurl;
    }

    if (this.siteData) {
      if (this.siteData.url) {
        this.docUrl = this.siteData.url;
      }
    }
  }

  public updated() {
    if (this.docUrl) {
      this.src = this.$api.fullUrl(
        "pdf2/" + this.$api.user.id + "_" + this.docUrl
      );
    }
  }
  private password(updatePassword: any, reason: any) {
    updatePassword(prompt('password is "test"'));
  }
  private error(err: any) {
    console.log(err);
  }
}
