
















































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditComponent from "@/editor/EditComponent";
import EditPage from "@/components/EditPage";

@Component
export default class ValueChangeTracker extends Vue {
  private parentEditComponent: EditComponent | undefined;
  private parentEditPage: EditPage | undefined;
  private backupData?: string;
  public isBadgeVisible!: boolean;
  public badgeColor = "red";

  @Prop({ default: false })
  public enabled!: boolean;
  @Prop({ default: false })
  public inline!: boolean;
  @Prop()
  public height!: string;

  public created() {
    this.isBadgeVisible = false;
    this.enabled = false;
  }

  public inlineStyle() {
    return this.height ? { height: this.height } : {};
  }
  private isEnabledFromEditPage = true;
  public mounted() {
    this.isBadgeVisible = false;

    this.parentEditComponent = this.getParentVue(
      (x) => (x.$parent as EditComponent).imAEditComponent === true
    ) as EditComponent;

    this.parentEditPage = this.getParentVue(
      (x) => (x.$parent as EditPage).thisIsAnEditPage === true,
      this.parentEditComponent
    ) as EditPage;

    this.isEnabledFromEditPage =
      this.parentEditPage?.isValueChangeTrackerEnabled() ?? true;

    const disabled = this.parentEditComponent?.disableValueChangeTracker;

    if (!disabled && this.parentEditComponent && (this.isEnabledFromEditPage || this.enabled)) {
      this.parentEditComponent.$on("change", this.checkValueHasChanged);
      this.parentEditComponent.$on("mountFinished", (selected?: any) => {
        this.setBackupData(selected ?? this.parentEditComponent!.getInitValue());
        // console.log("backupVal: " + this.backupData);
      });
      this.setBackupData(this.parentEditComponent!.getInitValue());
      // console.log("backupVal: " + this.backupData);
      this.badgeColor = "orange";
    }
  }

  private getParentVue(predicate: (x: Vue) => boolean, x: Vue = this): Vue | undefined {
    do {
      if (predicate(x)) {
        return x.$parent as EditComponent;
      }
      x = x.$parent;
    } while (x);
    // nothing found...
  }

  private getValue(value: any) {
    if (value && typeof value === "string") {
      return value;
    } else if (value) {
      return JSON.stringify(value);
    }

    return undefined;
  }

  public setBackupData(value: any) {
    this.backupData = this.getValue(value);
  }

  public checkValueHasChanged(value: any) {
    try {
      const newVal = this.getValue(value);
      this.isBadgeVisible = newVal !== this.backupData;
      // console.log("isChanged: " + this.isBadgeVisible + " - " + newVal + " - " + this.backupData);
      this.$forceUpdate();
    } catch (error) {
      console.log(error);
    }
  }
}
