






import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { getDisplay } from "@/DataHelper";

@Component
export default class GridButton extends Vue {
  public txt: any = "-";
  public column: any;
  public grid!: Vue;

  public mounted() {
    if (
      this.$options &&
      this.$options.components &&
      this.$options.components.GridButton
    ) {
      const x: any = this.$options.components.GridButton;
      this.column = x.column;
      this.grid = x.grid;
      this.txt = getDisplay(this.data.row, this.column);
    }
  }
  private data: any = { index: -1 };
  private clicked(e: any) {
    this.grid.$emit("showDetailForm", {
      column: this.column,
      row: this.data.row
    });
  }
}
