

















































































































import { Vue, Component, Prop } from "vue-property-decorator";
import {
  DashboardRow,
  getBoardDefinitions,
  DashboardParams,
} from "./ChartTypesAndFunctions";
import { Globals } from "@/Globals";
import { showSnackbar } from "@/UIHelper";
import { AxiosResponse } from "axios";
import { refreshFullPageContent, showSite } from "@/NavigationHelper";
import { getColumnByKennung, getNameColumn } from "@/DataHelper";
import EditPage from "@/components/EditPage";

@Component
export default class AufgabenTemplatedPage extends Vue {
  @Prop({ default: null }) private dashboardNameStart!: string;
  @Prop({ default: false }) private persoenlicheAufgaben!: boolean;
  @Prop() public siteData!: DashboardParams;

  private static lastAufgabeDashboard: string = "default";
  private dashboardName: string = "default";
  private boardDefinitions: DashboardParams[] = [];
  private selcetedDefinition: number = 0;
  private selectedBenutzer: boolean = false;
  private showLoading: boolean = true;
  public showZeitraumInHeader: boolean = true;

  private persoenlich: boolean = false;
  private dashboardDefinition?: DashboardRow[] = [];
  private dialogSave: boolean = false;
  private dialogChange: boolean = false;
  private frageSave: string =
    "Möchten Sie die Einstellungen dauerhaft speichern oder nur für dieses Gerät?";
  private bezeichnung: string = "";
  private frageCopy: string = this.$globalsBegriffMemo(
    this.Globals.Begriff,
    "Kopie",
    "Soll der Datensatz kopiert werden?"
  );

  public mounted() {
    this.persoenlich = this.persoenlicheAufgaben;
    this.refresh();
  }
  public async refresh() {
    try {
      const me = this;
      if (!this.boardDefinitions || this.boardDefinitions.length === 0) {
        this.boardDefinitions =
          (await getBoardDefinitions(this.persoenlich, me, true)) ?? [];
        if (this.boardDefinitions.length > 0) {
          let def = this.boardDefinitions[0]; // default is das erste
          if (AufgabenTemplatedPage.lastAufgabeDashboard) {
            def =
              this.boardDefinitions.find(
                // finden und auswählen
                (d) => d.name === AufgabenTemplatedPage.lastAufgabeDashboard
              ) ?? def; // oder das erste nehmen wenns das alte nimma gibt
          }
          this.dashboardName = def.name;
          this.dashboardDefinition = def.definition;
          this.selcetedDefinition = def.aic;
          this.selectedBenutzer = false;
          if (def.benutzer) {
            this.selectedBenutzer = true;
          }
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.showLoading = false;
    }
  }

  private getMenuButtonColor(args: DashboardParams) {
    return this.dashboardName === args.name ? "green" : "white";
  }

  private loadBoard(args: DashboardParams) {
    this.selectedBenutzer = false;
    if (args.benutzer) {
      this.selectedBenutzer = true;
    }
    this.selcetedDefinition = args.aic;
    this.showLoading = true;
    this.dashboardDefinition = args.definition;
    AufgabenTemplatedPage.lastAufgabeDashboard = this.dashboardName = args.name;
    this.showLoading = false;
  }
  private onSaveData(data: DashboardRow[]) {
    this.dashboardDefinition = data;
    if (this.selectedBenutzer) {
      this.dialogSave = true;
    }
  }
  private async saveBoard(args: DashboardParams, copy: boolean, change: boolean) {
    this.dialogSave = false;
    let bewAic = args?.aic ?? this.selcetedDefinition;
    const response = await this.$api.getQuery(
      "Web_speichernAufgabenDefinition",
      undefined,
      bewAic,
      null,
      0,
      false,
      true
    );
    const header = response.data.header;
    const origData = response.data.data;
    const data = response.data.data;
    const columns = response.data.columns;
    if (change) {
      const bezeichnungCol = getColumnByKennung("AUFTRAGSNAME", "name", columns);
      if (!bezeichnungCol) {
        return;
      }
      const bezCol = bezeichnungCol.name;
      data.map((x: any) => {
        x[bezCol] = args.name;
      });
    } else if (copy) {
      const bezeichnungCol = getColumnByKennung("AUFTRAGSNAME", "name", columns);
      if (!bezeichnungCol) {
        return;
      }
      const bezCol = bezeichnungCol.name;
      data.map((x: any) => {
        x[bezCol] = this.bezeichnung;
      });
      const benutzerCol = getColumnByKennung("BEW_BENUTZER", "user", columns);
      if (!benutzerCol) {
        return;
      }
      const nameColBenutzer = benutzerCol.name;
      const user = this.$api.user.user;
      data.map((x: any) => {
        x[nameColBenutzer] = user;
      });
      bewAic = 0;
    } else {
      const defCol = getColumnByKennung("AUFGABENDEFINITION", "json", columns);
      const nameCol = defCol.name;
      const json = JSON.stringify(this.dashboardDefinition);
      data.map((x: any) => {
        x[nameCol] = json;
      });
    }
    const me = this;
    if (data.length > 0) {
      this.$api.SaveData(
        header,
        0,
        bewAic,
        data[0],
        columns,
        "",
        "",
        (res: any) => {
          if (!res.nothingHappend) {
            // wenn nix passiert is aber weitermachen ;)
            showSnackbar({
              text: this.$globalsBegriffMemo(
                Globals.Begriff,
                "Datensatz_gespeichert",
                "Daten wurden gespeichert"
              ),
              duration: 4000,
            });
            if (copy) {
              this.boardDefinitions = [];
              this.refresh();
            }
          } else {
            showSnackbar(
              this.$globalsBegriffMemo(
                Globals.Begriff,
                "Datensatz_keine_Aenderung",
                "Daten wurden nicht verändert, kein Speichern durchgeführt!"
              )
            );
          }
        },
        (ex: any) => {
          let errorMessage = "";
          if (ex.response) {
            errorMessage = ex.response.data.error;
            if (!errorMessage && ex.response?.status && ex.response.status === 400) {
              errorMessage = ex.response.data.info;
            }
          } else {
            errorMessage = ex;
          }
          this.$root.$emit("alert", {
            text: errorMessage,
            type: "error",
          });
          console.error("ERROR: " + ex);
        },
        0,
        true
      );
    }
  }
  private async deleteBoard(args: DashboardParams) {
    const bewAic = args?.aic ?? this.selcetedDefinition;
    const response = await this.$api.getQuery(
      "Web_speichernAufgabenDefinition",
      undefined,
      bewAic,
      null,
      0,
      false,
      true
    );
    const header = response.data.header;
    if (!bewAic) {
      showSnackbar("keinen Datensatz übergeben!");
      return;
    }
    const result = this.$api.DeleteData(header, bewAic);
    this.boardDefinitions = [];
    this.refresh();
  }
}
