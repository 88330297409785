















































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditZahl from "@/editor/EditZahl.vue";
// import EditComponent from "@/editor/EditComponent";
import { Globals } from "@/Globals"; // könnte man das in die EditComponent rein hängen?

@Component
export default class EditMass extends EditZahl {
  private prefixAnzeige: string = "%";
  private faktor: number = 0;
  private initalized: boolean = false;
  public mounted() {
    this.initalized = false;
    const value = this.getInitValue();
    const einheit = this.column.meta.einheit;
    let anzeige = this.$globalsAic(Globals.Einheit, einheit);
    if (
      this.column &&
      this.column.meta &&
      this.column.meta.einheit &&
      !this.column.meta.originalEinheit
    ) {
      // const einheit = this.column.meta.einheit;
      // const anzeige = this.$globalsAic(Globals.Einheit, einheit);
      if (anzeige && anzeige.kennung) {
        this.prefixAnzeige = anzeige.kennung;
        if (anzeige.kennung === "%") {
          this.format = "p2";
          // bei % darf nicht hochrechnen!
          // value.faktor = 0;
        }
        // this.format = this.format + " " + anzeige.kennung;
      }
    } else if (this.column.meta.originalEinheit) {
      anzeige = this.data.kz;
    }
    if (value && typeof value === "object") {
      this.number = value.value;
      if (value.faktor > 0 && anzeige.kennung !== "%") {
        this.number = value.value / value.faktor;
      }
    } else {
      this.number = this.getInitValue();
    }
    this.initalized = true;
    // einheit aus globalen Tabelle Einheiten holen
  }
  // private numberChanged(txt: any) {
  //   let umrechnung = this.number;
  //   if (this.faktor > 0) {
  //     umrechnung = this.number * this.faktor;
  //   }
  //   this.textChanged(umrechnung);
  // }
}
