var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showMutable)?_c('div',{staticClass:"wrap"},[_c('v-tooltip',{attrs:{"disabled":!_vm.showtooltip,"bottom":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"e-float-input"},'div',attrs,false),on),[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"11"}},[(_vm.sttVon)?_c('v-select',{ref:"combobox",attrs:{"clearable":_vm.clearable,"dense":"","flat":"","solo":"","label":_vm.description,"items":_vm.list,"disabled":!_vm.enabled,"item-text":"bezeichnung","item-value":"aic","loading":_vm.showLoading,"no-data-text":_vm.$globalsBezeichnung(
                  _vm.Globals.Begriff,
                  'Web_keineDaten_gefunden',
                  'keine Daten gefunden'
                ),"filter":_vm.filterFunction,"error-messages":_vm.errorText,"hint":_vm.errorText,"append-icon":_vm.showStichtag ? 'history' : '',"required":_vm.requiredRender()},on:{"change":_vm.selectedValueChanged,"focus":function($event){return _vm.scrollMeIntoView($event)},"click:append":_vm.loadStichtag},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('EditComboBoxItem',{style:('width:' + _vm.menuwith),attrs:{"item":item}})]}}],null,true),model:{value:(_vm.selectedElement),callback:function ($$v) {_vm.selectedElement=$$v},expression:"selectedElement"}}):_vm._e(),(!_vm.sttVon)?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({attrs:{"clearable":_vm.clearable,"dense":"","flat":"","items":_vm.list,"label":_vm.description,"disabled":!_vm.enabled,"item-text":"bezeichnung","item-value":"aic","loading":_vm.showLoading,"no-data-text":_vm.$globalsBezeichnung(
                      _vm.Globals.Begriff,
                      'Web_keineDaten_gefunden',
                      'keine Daten gefunden'
                    ),"error-messages":_vm.errorText,"persistent-hint":"","hint":_vm.hintText,"append-icon":_vm.showStichtag ? 'history' : '',"required":_vm.requiredRender()},on:{"click:append":_vm.loadStichtag},model:{value:(_vm.selectedElement),callback:function ($$v) {_vm.selectedElement=$$v},expression:"selectedElement"}},'v-select',attrs,false),on))]}}],null,true),model:{value:(_vm.showList),callback:function ($$v) {_vm.showList=$$v},expression:"showList"}},[_c('v-card',[_c('v-sheet',{staticClass:"pa-4 primary lighten-2"},[(!_vm.noSearch)?_c('v-text-field',{attrs:{"label":"Suche","flat":"","dark":"","solo-inverted":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),_c('v-card-text',[_c('v-treeview',{staticClass:"hierachieList",attrs:{"dense":"","shaped":"","hoverable":"","open-all":"","items":_vm.items,"item-key":"aic","item-text":"bezeichnung","activatable":"","return-object":"","active":_vm.selected,"selected-color":"success","multiple-active":false,"search":_vm.search},on:{"update:active":_vm.selectedValueChanged},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1):_vm._e()],1),(_vm.showSettings)?_c('v-col',{staticClass:"pa-0",staticStyle:{"cursor":"hand"},attrs:{"cols":"1"},on:{"click":_vm.openSettings}},[_c('v-icon',{attrs:{"text":""}},[_vm._v("more_vert")])],1):_vm._e()],1)],1)]}}],null,false,2206684891)},[_c('span',[_vm._v(_vm._s(_vm.tooltip))])]),_c('ValueChangeTracker')],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }