


























































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import {
  getKennungValueDisplay,
  getKennungTitle,
  getKZValueDisplay,
} from "@/DataHelper";
// import { Globals } from "@/Globals";

@Component
export default class DialogDetailantrag extends EditPage {
  constructor() {
    super();
    this.query = "PZE_Web_TagesSalden";
  }
  private soll: any = "";
  private ist: any = "";
  private saldo: any = "";
  private differenz: any = "";
  private pause: any = "";
  private verfallBeginn: any = "";
  private verfallEnde: any = "";
  private day: string = this.siteData.zeitbereich.von.toLocaleDateString();
  private name: string = "";

  protected initialize() {
    this.soll = getKennungValueDisplay(this.data, "Soll", this.columns);
    this.ist = getKennungValueDisplay(this.data, "Ist", this.columns);
    this.pause = getKennungValueDisplay(this.data, "Pause", this.columns);
    this.saldo = getKennungValueDisplay(this.data, "Saldo", this.columns);
    this.name = getKZValueDisplay(this.data, "name", this.columns);
    this.differenz = getKennungValueDisplay(
      this.data,
      "Differenz",
      this.columns
    );
    this.verfallBeginn = getKennungValueDisplay(
      this.data,
      "VerfallzeitBeginn",
      this.columns
    );
    this.verfallEnde = getKennungValueDisplay(
      this.data,
      "VerfallzeitEnde",
      this.columns
    );
  }
}
