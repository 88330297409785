














































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { refreshFullPageContent, showSite } from "@/NavigationHelper";
import { showDialog, showSnackbar } from "@/UIHelper";

// import Constants from "@/Constants";
@Component
export default class Navigation extends Vue {
  public myDrawer: boolean = false;
  public myMini: boolean = true;

  // so und jetzt...?
  @Prop({ default: true }) protected mini!: boolean;
  @Prop() protected activeStackIndex!: number;
  @Prop({ default: false }) public drawer!: boolean;
  @Prop({ default: null }) protected onClosed!: () => void | null;
  @Prop({ default: false }) protected showAvatar!: boolean;
  @Prop() protected menuItems!: any[];
  @Prop() protected userItems!: any[];
  private username: string = this.$api.user.bezeichnung;
  private avatar: any = this.$api.getUserAvatar();

  @Watch("drawer")
  private drawerWatch(val: boolean, oldval: boolean) {
    if (oldval !== val) {
      this.myDrawer = val;
    }
  }

  @Watch("mini")
  private miniWatch(val: boolean, oldval: boolean) {
    if (oldval !== val) {
      this.myMini = val;
    }
  }
  private wasopen = false;
  private changedMe(val: boolean) {
    // wenn grad geschlossen app informieren
    if (this.myDrawer) {
      this.wasopen = true;
    } else if (!this.myDrawer && this.onClosed && this.wasopen) {
      this.onClosed();
      this.wasopen = false;
    }
  }

  private onShowSite(site: string, data: any, title: string, aic: number) {
    showSite(site, data, title, aic);
  }
  private onUserItemClick(item: any) {
    if (item.action === "mandant") {
      if (this.$api.mandant.aic === item.data.aic) {
        showSnackbar({
          text: "Mandant bereits ausgewählt!",
          duration: 3000,
          color: "success",
        });
      } else {
        showSnackbar({
          text: "Mandant wird gewechselt...",
          duration: 3000,
          color: "success",
        });
        this.$api.changeMandant(item.data).then(() => {
          this.rebuildUserMenu();
          refreshFullPageContent(); // ausm nav helper reloaden

          showSnackbar({
            text: "Mandant erfolgreich gewechselt!",
            duration: 3000,
            color: "success",
          });
        });
      }
    } else if (item.action === "debug") {
      showDialog({
        title: "Debug",
        site: "DefDebug",
        width: 400,

        titleColor: "dvhBackground",
        titleClass: "white--text",
      });
    }
  }
  private rebuildUserMenu() {
    this.userItems[0].subItems = null;
    if (this.$api.user.mandantW) {
      const mandantenMenu = [];
      for (let i = 0; i < this.$api.user.mandantW.length; i++) {
        const mid = this.$api.user.mandantW[i];
        const mandant: any = this.$api.Mandanten.find((m: any) => m.aic === mid);
        if (mandant) {
          let color = "#ccc";
          if (mid === this.$api.mandant.aic) {
            color = "black";
          }
          mandantenMenu.push({
            text: mandant.bezeichnung,
            data: mandant,
            action: "mandant",
            icon: "supervised_user_circle",
            color,
          });
        }
      }
      this.userItems[0].subItems = mandantenMenu;
    }
  }
}
