var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",staticStyle:{"width":"170px"}},[(!_vm.month)?_c('ejs-daterangepicker',{attrs:{"format":_vm.dateFormat,"placeholder":_vm.waterMark,"startDate":_vm.startVal,"endDate":_vm.endVal,"weekNumber":"true","cssClass":_vm.classVal},on:{"change":_vm.valueChanged}},[_c('e-presets',[_c('e-preset',{attrs:{"label":_vm.$globalsBezeichnung(
            _vm.Globals.Begriff,
            'Web_aktuelle_Woche',
            'aktuelle Woche'
          ),"start":_vm.weekStart,"end":_vm.weekEnd}}),_c('e-preset',{attrs:{"label":_vm.$globalsBezeichnung(
            _vm.Globals.Begriff,
            'Web_aktueller_Monat',
            'aktueller Monat'
          ),"start":_vm.monthStart,"end":_vm.monthEnd}}),_c('e-preset',{attrs:{"label":_vm.$globalsBezeichnung(
            _vm.Globals.Begriff,
            'Web_letzter_Monat',
            'letzter Monat'
          ),"start":_vm.lastStart,"end":_vm.lastEnd}}),_c('e-preset',{attrs:{"label":_vm.$globalsBezeichnung(
            _vm.Globals.Begriff,
            'Web_letztes_Jahr',
            'letztes Jahr'
          ),"start":_vm.yearStart,"end":_vm.yearEnd}})],1)],1):_vm._e(),(_vm.month)?_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":"","outlined":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}],null,false,929727072),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"type":"month","no-title":"","scrollable":"","startDate":_vm.startVal,"endDate":_vm.endVal,"locale":_vm.$api.user.locale,"show-week":true},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.menu.save(_vm.date);
          _vm.valueChanged(_vm.date);}}},[_vm._v(" OK ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }