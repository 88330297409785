import { Capacitor } from "@capacitor/core";
import { Browser } from "@syncfusion/ej2-base";
import App from "./App.vue";
declare var ALLConfig: ALLConfigType;

export default class Constants {
    public static App: App | undefined;
    public static MobileWidthThreshold = 800;
    public static BottomMenuVisible = false;
    public static PlatformMac = false;
    public static PlatformAndroid = false;
    public static indexFile: string = "./index.html";
    // public static get isMobile() {
    //     return Capacitor.getPlatform() !== "web" || window.innerWidth < Constants.MobileWidthThreshold;
    // }
    public static get isMobile() {
        return Capacitor.getPlatform() !== "web" || Browser.isDevice;
    }
    public static get platformAndroid() {
        const userAgent = window.navigator.userAgent;
        // const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
        if (/Android/.test(userAgent)) {
            this.PlatformAndroid = true;
        }
        return this.PlatformAndroid;
    }
    public static get platformMac() {
        if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
            this.PlatformMac = true;
        }
        //     const platform = window.navigator.platform;
        //     const userAgent = window.navigator.userAgent;

        //     const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
        //     const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

        //     if (macosPlatforms.indexOf(platform) !== -1) {
        //         this.PlatformMac = true;

        //     } else if (iosPlatforms.indexOf(platform) !== -1) {
        //         this.PlatformMac = true;

        //     }
        return this.PlatformMac;
    }

    public static get isDebug() {
        return ALLConfig.debug ?? false;
    }
}
