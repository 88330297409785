









































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { showPDF2 } from "@/PdfHelper";
import EditComponent from "@/editor/EditComponent";
import { createBase64Image, resizeImage } from "@/FileHelper";
import Constants from "../Constants";
import { Capacitor } from "@capacitor/core";

@Component
export default class EditBild extends EditComponent {
  constructor() {
    super();
  }
  private imageName: string = "";
  private placeholder: string = "Upload your documents";
  private imageFile: File | null = null;
  private loading: boolean = false;
  private base64data: string = "";
  private field: any = this.queryColumn;
  private pdf: boolean = false;
  private pdfFile: any = "";

  public mounted() {
    const x: {
      name: string | null;
      data: string | null;
      type: string | null;
    } = this.getInitValue();
    if (x?.data) {
      // bei pdf nicht laden!
      if (x.type !== "application/pdf") {
        this.base64data = x.data;
      } else {
        this.pdf = true;
        const image: any = x;
        this.pdfFile = image;
      }
    }
    if (x?.name) {
      this.imageName = x.name;
      if (!this.base64data) {
        this.loadImage(x);
      }
    }
  }
  private loadImage(image: any) {
    if (this.pdf) {
      return;
    }
    if (image.datentyp === "Doku") {
      this.base64data = this.$api.fullUrl(
        "pdf2/" + this.$api.user.id + ":" + +image.aic + "_" + image.name
      );
    } else {
      this.base64data = this.$api.fullUrl(
        "image2/" + this.$api.user.id + ":" + +image.aic + "_" + image.name
      );
    }
  }
  private onShowPdf() {
    showPDF2(this.pdfFile.name, this.pdfFile.aic, this.$api.user.id);
  }
  private async fileChanged(file: File) {
    if (file) {
      this.imageName = file.name;
      if (this.imageName.lastIndexOf(".") <= 0) {
        return;
      }
      try {
        const me = this;
        if (file.type !== "application/pdf") {
          let thumbnail;
          // console.log("Android: " + Constants.platformAndroid);
          if (Constants.isMobile && Capacitor.getPlatform() !== "android") {
            thumbnail = await resizeImage(file, 1100, 1100);
            // wenn IOS dann nicht so stark komprimieren!
          } else {
            thumbnail = await resizeImage(file, 1000, 1000);
          }
          // const thumbnail = await resizeImage(file, 1000, 1000);
          const base64 = await createBase64Image(thumbnail);
          this.base64data = base64;
          // me.valueChanged({ data: base64, name: file.name + ".jpg" });
          me.valueChanged({ data: base64, name: file.name });
        } else {
          const base64 = await createBase64Image(file);
          this.base64data = base64;
          me.valueChanged({ data: base64, name: file.name });
        }
      } catch (error) {
        this.loading = false;
        console.log("error reading File:" + error);
        alert("Kann File leider nicht lesen!");
        this.imageFile = null;
      }
    }
  }

  public validate() {
    if (this.required) {
      if (this.imageName) {
        this.errorText = "";
        return true;
      }
      this.errorText = this.requiredMessage;
      return false;
    }
    return true;
  }
  private clear() {
    this.imageName = "";
    this.base64data = "";
  }
}
