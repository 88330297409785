// import of files an components
import Vue from "vue";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";

export function importComponents(requireComponent: any) {
    requireComponent.keys().forEach((f: string) => importComponent(requireComponent, f));
  }

export function importComponent(requireComponent: any, fileName: any) {
    // Get component config
    const componentConfig = requireComponent(fileName);
    // Get PascalCase name of component
    const componentName = upperFirst(
      camelCase(
        // Strip the leading `./` and extension from the filename
        fileName.replace(/^\.\/(.*)\.\w+$/, "$1")
      )
    );

    console.log(fileName + " -> " + componentName);
    // Register component globally
    Vue.component(
      componentName,
      // Look for the component options on `.default`, which will
      // exist if the component was exported with `export default`,
      // otherwise fall back to module's root.
      componentConfig.default || componentConfig
    );
  }
