












































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { getKennungValueDisplay, getKennungValue } from "@/DataHelper";
// import { showSnackbar } from "@/UIHelper";
import { Globals } from "@/Globals";

@Component
export default class EditKmErfassung extends EditPage {
  constructor() {
    super();
    this.query = "Web_RK_NewKMGeldErfassung";
  }
  public disabled: boolean = false;
  public clearable: boolean = false;
  private showDeleteMask: boolean = false;
  private selectFahrzeug: boolean = false;
  private fahrtkosten: boolean = false;

  public mounted() {
    if (this.siteData.bewegungsdaten) {
      this.bewegungsdaten = true;
      this.aic = this.siteData.aicBewegung;
    } else {
      if (this.siteData.aicReise) {
        this.bewegungsdaten = false;
        this.aic = this.siteData.aicReise;
      }
    }
    const Stamm = this.$globalsStt(Globals.Stt, "", "RK_TRAVEL");
    this.jokerStt.push({
      sync: this.siteData.aicReise, // aic Stammsatz
      aic: Stamm.aic, // aic Stammtyp
    });
    this.showLoading = true;
    this.mountedBase();
    if (this.siteData.showDelete) {
      this.showDeleteMask = this.siteData.showDelete;
    }
  }
  protected initialize() {
    this.fahrtkosten = getKennungValue(this.data, "Fahrtkosten", this.columns);
    this.selectFahrzeug = getKennungValueDisplay(
      this.data,
      "RK_TRANSPORTMITTEL",
      this.columns
    );
  }
  private save() {
    const aic = this.siteData.aicReise;
    const me = this;
    this.onSave(0, "", "", aic)?.then(() => {
      this.$nextTick(() => me.$emit("save"));
    });
  }
  public bewDelete() {
    if (this.data && this.data.aic_Bew_pool) {
      this.onDelete(this.data.aic_Bew_pool, "");
      this.$emit("back");
    }
  }
  private checkValue(evt: any) {
    this.fahrtkosten = evt.value.setShow;
  }
}
