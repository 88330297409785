


























































































































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { getMonthNames, getWeekdayNamesShort } from "@/editor/CalenderHelper";
import { Globals } from "@/Globals";
import CreateList from "@/components/CreateList";
import AUApi from "@/api";

import MdChartist from "@/components/MD/chartist.vue";
import BaseList from "@/components/baseList.vue";
import Constants from "@/Constants";
import { getKZValueDisplay, getKennungValue, getKZTitle } from "@/DataHelper";
import { showDialog, showSnackbar } from "@/UIHelper";

@Component
export default class Uebersicht extends CreateList {
  @Prop({ default: null }) private stdAnzeige!: number | null;
  @Prop({ default: null }) private std!: number | null;
  private lokaleStd: number = 0;
  private stdAnzeigeBerechnet: number = 0;
  @Prop({ default: "" }) private vonAnzeige!: string;
  @Prop({ default: "" }) private bisAnzeige!: string;
  private showDialog: boolean = false;
  private verbuchtAnzeige: number = 0.0;
  private verbucht: string = "";
  private diff: string = "";
  private stdAnzeigeString: string = "";
  // private cardWidth: number = 350;

  private diffAnzeige: number = 0.0;
  @Prop({ default: null }) private day!: Date | null;
  @Prop({ default: 300 }) public contentHeight!: number;

  private tag: any = 0;
  private wochenTag: any = 0;
  private monat: string = "-";
  private timeLine: boolean = false;
  private nachErfassung: boolean = false;
  private editaic: any;
  private innerHeight: number = 199;
  private sperre: boolean = false; // dürfen Tage nicht mehr bearbeitet werden- dann wird sperre gesetzt
  private lastRefresh: string | null = null;
  private showBtnNacherfassung: boolean = false;
  private von: string = "";
  private bis: string = "";
  private showChart: boolean = true;
  private isApiBusy: boolean = false;

  private warningBtnVisible: boolean = false;
  private holiday: any = null;

  private backFunction: (() => void) | null = null;

  private titleColor = "dvhBackground";
  constructor() {
    super();
    this.showLoading = true;
  }

  public mounted() {
    this.showChart = true;
    // this.contentHeight = window.innerHeight - 200;
    if (Constants.isMobile) {
      this.showChart = false;
    } else {
      this.mountedBase();
    }
    try {
      this.lokaleStd = this.std ?? 0;
      const days = getWeekdayNamesShort();
      if (!this.day) {
        this.day = this.$api.zeitbereich.von;
      }
      this.von = this.vonAnzeige;
      this.bis = this.bisAnzeige;
      const d = this.day;
      this.wochenTag = days[d.getDay()];
      this.tag = this.day.getDate();
      this.monat = getMonthNames()[this.day.getMonth()];
      const holiday = this.$api.getHoliday(d);

      if (holiday) {
        this.holiday = holiday;
        this.titleColor = "red";
      }

      const me = this;
      this.editZeitbereich = {
        von: this.day,
        bis: this.day,
        bereich: "Tag",
      };
      if (!this.editZeitbereich) {
        return;
      }
      // auf tag eingrenzen
      this.editZeitbereich = {
        von: this.editZeitbereich.von,
        bis: this.editZeitbereich.von,
        bereich: "Tag",
      };
      this.showLoading = false;
      if (!this.mountedBase()) {
        return;
      }
    } catch (e: any) {
      console.error("mounterror: " + e, e?.stack);
    }
  }
  protected refreshItems() {
    const x = this.$refs.chart as MdChartist;
    x?.reloadData();
    const y = this.$refs.list as BaseList;
    y?.reloadData();
    this.items = []; // ausleeren für refresh
    this.verbuchtAnzeige = 0;
    for (let i = 0; i < this.data.length; i++) {
      const row = this.data[i];
      const header = getKZValueDisplay(row, "header", this.columns);
      const differenz: any = getKennungValue(row, "hours", this.columns);

      this.verbuchtAnzeige += differenz;
      let title = getKZValueDisplay(row, "titel", this.columns);
      if (title) {
        const textTitle = getKZTitle(row, "titel", this.columns);
        title = textTitle + ": " + title;
      }
      let title2 = getKZValueDisplay(row, "titel2", this.columns);

      if (title2) {
        const textTitle2 = getKZTitle(row, "titel2", this.columns);
        title2 = textTitle2 + ": " + title2;
      }

      let subtitel = getKZValueDisplay(row, "subtitel", this.columns);
      if (subtitel) {
        const textSubtitel = getKZTitle(row, "subtitel", this.columns);
        subtitel = textSubtitel + ": " + subtitel;
      }
      let subtitel1 = getKZValueDisplay(row, "subtitel1", this.columns);
      if (subtitel1) {
        const textSubtitel1 = getKZTitle(row, "subtitel1", this.columns);
        subtitel1 = textSubtitel1 + ": " + subtitel;
      }
      let text = getKZValueDisplay(row, "text", this.columns);
      if (text) {
        const textTitel = getKZTitle(row, "text", this.columns);
        text = textTitel + ": " + text;
      }
      const icon = getKZValueDisplay(row, "icon", this.columns);
      this.sperre = getKZValueDisplay(row, "sperre", this.columns);

      this.stdAnzeigeBerechnet = getKZValueDisplay(row, "Ist", this.columns);

      this.items.push({
        header,
        title,
        title2,
        subtitel,
        subtitel1,
        text,
        icon,
        differenz,
        divider: true,
        inset: true,
        aic: row.aic_Bew_pool,
        menu: false,
      });
    }
    if (this.stdAnzeigeBerechnet && this.verbuchtAnzeige) {
      this.diffAnzeige = this.stdAnzeigeBerechnet - this.verbuchtAnzeige;
      let val = this.diffAnzeige;
      let valueNegativ = false;
      if (val < 0) {
        val = val * -1;
        valueNegativ = true;
      }
      let hour = Math.floor(val);
      let min = Math.round((val - hour) * 60);
      if (min === 60) {
        hour += 1;
        min = 0;
      }
      this.diff = this.zeroPad(hour, 2) + ":" + this.zeroPad(min, 2);
      if (valueNegativ) {
        this.diff = "- " + this.zeroPad(hour, 2) + ":" + this.zeroPad(min, 2);
      } else {
        this.diff = this.zeroPad(hour, 2) + ":" + this.zeroPad(min, 2);
      }
    } else {
      if (this.verbuchtAnzeige) {
        this.diffAnzeige = this.verbuchtAnzeige;
        const val = this.diffAnzeige;
        let hour = Math.floor(val);
        let min = Math.round((val - hour) * 60);
        if (min === 60) {
          hour += 1;
          min = 0;
        }
        this.diff = "- " + this.zeroPad(hour, 2) + ":" + this.zeroPad(min, 2);
      } else {
        // DiffAnzeige muss UNFormatiert übergeben werden!
        this.diffAnzeige = this.std ?? 0;
      }
    }

    if (this.verbuchtAnzeige > 0) {
      const valVerb = this.verbuchtAnzeige;
      const hourVerb = Math.floor(valVerb);
      const minVerb = Math.round((valVerb - hourVerb) * 60);
      this.verbucht = this.zeroPad(hourVerb, 2) + ":" + this.zeroPad(minVerb, 2);
    }
    if (this.stdAnzeigeBerechnet > 0) {
      const valStd = this.stdAnzeigeBerechnet;
      const hourStd = Math.floor(valStd);
      const minStd = Math.round((valStd - hourStd) * 60);
      this.stdAnzeigeString = this.zeroPad(hourStd, 2) + ":" + this.zeroPad(minStd, 2);
    }
  }
  private zeroPad(num: number, places: number) {
    return String(num).padStart(places, "0");
  }
  // private onDialogClose(item: any) {
  //   item.menu = false;
  //   this.reloadData();
  // }
  private openNew(bewegungsdaten: boolean) {
    this.isApiBusy = AUApi.Current.isBusy;
    if (this.isApiBusy) {
      showSnackbar({
        text: this.$globalsBezeichnung(
          this.Globals.Begriff,
          "Web_Berechnung_laueft:",
          "Berechnung läuft..."
        ),
        duration: 800,
        color: "error",
      });
      return;
    }
    const me = this;
    let uebergabeStd = this.lokaleStd;
    if (this.diffAnzeige && this.stdAnzeigeBerechnet) {
      uebergabeStd = this.diffAnzeige;
    }
    let showFullscreen = false;
    if (Constants.isMobile && this.$isPhone()) {
      showFullscreen = true;
    }
    showDialog({
      title: this.$globalsBezeichnung(
        this.Globals.Begriff,
        "Web_Nacherfassung",
        "Nacherfassung"
      ),
      site: "SoftTermProjektstundenEdit",
      width: 400,
      persistent: true,
      fullscreen: showFullscreen,
      noClose: false,
      data: {
        zeitbereich: this.editZeitbereich,
        stdUebergabe: uebergabeStd,
      },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      onClose: () => {
        me.reloadData();
        return true;
      },
    });
  }
  private openEdit(aic: any) {
    const me = this;
    showDialog({
      title: "bearbeiten",
      site: "SoftTermProjektstundenEdit",
      width: 400,
      data: {
        zeitbereich: this.editZeitbereich,
        aic,
        bewegungsdaten: true,
        showDelete: true,
      },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      onClose: () => {
        me.reloadData();
        return true;
      },
    });
  }

  private warningListLoaded(args: any) {
    if (this.showError) {
      // wenn es mindestens ein item gibt dann zeigen wirs an
      this.warningBtnVisible = args && args.items && args.items.length > 0;
    }
  }
}
