











































































import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class UserMenu extends Vue {
  @Prop() protected groupAic!: any;
  @Prop() protected checked!: any;
  @Prop() protected aicBegriff!: any;
  private closeOnContentClick: boolean = true;
  // private mounted() {
  // }
  private setUserPara() {
    const varUbergabe: any[] = [];
    varUbergabe.push({
      aic: this.groupAic,
      pGruppiert: this.checked
    });
    this.$api.setPersParameter(varUbergabe);
  }
  private deleteUserPara() {
    const aicBegriff = this.aicBegriff;
    this.$api.deletePersParameter(aicBegriff);
    const group = true;
    this.$emit("changeGroup", { group });
  }
}
