












import { Vue, Component, Prop, Provide } from "vue-property-decorator";

@Component
export default class Stichtage extends Vue {
  private preloadedData!: any;
  @Prop({ default: null }) public siteData: any;
  private initalized: boolean = false;
  public mounted() {
    if (this.siteData) {
      this.preloadedData = this.siteData.data;
      this.initalized = true;
    }
  }
}
