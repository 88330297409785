






























import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditComponent from "@/editor/EditComponent";
import { Capacitor } from "@capacitor/core";
import { scanBarcode, BarcodeScanResult } from "@/BarcodeScanWrapper";

@Component
export default class EditTextField extends EditComponent {
  @Prop({ default: false }) public barcodeEnabled!: boolean;
  @Prop({ default: "Scannen Sie einen Barcode" }) public barcodePrompt!: string;

  protected type: string = "text"; // email und passwort gibt es auch
  protected barcodeButtonVisible = false;

  protected text: string = "";

  public mounted() {
    this.text = this.getInitValue();
    if (this.text === undefined) {
      this.text = "";
    }
    this.barcodeButtonVisible = this.barcodeEnabled && Capacitor.platform !== "web";
  }

  private textChanged(txt: any) {
    this.valueChanged(this.text);
  }

  protected async scanBarcode() {
    const data = await scanBarcode();
    if (data.cancelled) {
      return;
    }
    if (data.content) {
      let txt = data.content;
      if (txt.indexOf("\n") >= 0) {
        txt = txt.split("\n")[0];
      }
      this.text = txt;
      this.textChanged(txt);
    }
  }
  public validate() {
    if (this.disabled) {
      this.errorText = "";
      return true;
    } // disabled Controls sind IMMER valide ;)
    if (this.required) {
      if (this.text) {
        this.errorText = "";
        return true;
      }
      this.errorText = this.requiredMessage;
      return false;
    }
    this.errorText = "";
    return true;
  }
}
