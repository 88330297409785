















import { Vue, Component, Prop } from "vue-property-decorator";
@Component
export default class VCardInfo extends Vue {
  @Prop() public zeitbereich!: any;
  @Prop() public variable!: any;
  @Prop() public varName!: any;
  @Prop() public icon!: string;
  @Prop() public color!: string;

  @Prop() public height!: any;
  @Prop() public width!: any;
  @Prop({ default: null }) public queryAIC!: number;
  @Prop() public text!: string;

}
