


















































































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
// import VCardDialog from "@/components/common/VCardDialog.vue";
import EditPage from "../../components/EditPage";
// import EditComponent from "@/editor/EditComponent";
import { getKennungValueDisplay } from "@/DataHelper";

@Component
export default class TagesSalden extends EditPage {
  constructor() {
    super();
    this.query = "PZE_Web_TagesSalden";
  }
  private soll: any = "";
  private ist: any = "";
  private saldo: any = "";
  private differenz: any = "";
  private pause: any = "";
  private verfallBeginn: any = "";
  private verfallEnde: any = "";
  private showAntrag: boolean = true;
  private showBestaetigung: boolean = false;
  private isVorgesetzter: boolean = false;
  private showBelege: boolean = false;
  public mounted() {
    if (this.siteData.sperre === "1.00") {
      this.showAntrag = false;
    }
    if (this.siteData.isVorgesetzter) {
      this.isVorgesetzter = true;
      if (this.checkBegriff("Web_VG_ErfassungBelege")) {
        this.showBestaetigung = true;
      }
    } else {
      if (this.checkBegriff("Web_Bestaetigungen")) {
        this.showBestaetigung = true;
      }
      if (this.checkBegriff("RK_sonstigeBelege")) {
        this.showBelege = true;
      }
    }
  }
  protected initialize() {
    this.soll = getKennungValueDisplay(this.data, "Soll", this.columns);
    this.ist = getKennungValueDisplay(this.data, "Ist", this.columns);
    this.pause = getKennungValueDisplay(this.data, "Pause", this.columns);
    this.saldo = getKennungValueDisplay(this.data, "Saldo", this.columns);
    this.differenz = getKennungValueDisplay(
      this.data,
      "Differenz",
      this.columns
    );
    this.verfallBeginn = getKennungValueDisplay(
      this.data,
      "VerfallzeitBeginn",
      this.columns
    );
    this.verfallEnde = getKennungValueDisplay(
      this.data,
      "VerfallzeitEnde",
      this.columns
    );
  }
}
