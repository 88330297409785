














































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import VCardDialog from "@/components/common/VCardDialog.vue";

@Component
export default class Info extends EditPage {
  // @Prop({ default: null }) protected zeitbereich!: APIZeitbereich | null;
  constructor() {
    super();
  }
}
