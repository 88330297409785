
















import { Vue, Component, Prop, Provide } from "vue-property-decorator";

export interface SliderConfig {
  filterCol: DataColumn;
  min: number;
  max: number;
  range?: number[];
}

@Component
export default class extends Vue {
  @Prop() public config!: SliderConfig;

  private mounted() {
    this.config.range = [this.config.min, this.config.max];
  }
}
