import { ApplicationInsights, eSeverityLevel, ITelemetryItem } from '@microsoft/applicationinsights-web';
import AUApi from './api';

declare var ALLConfig: ALLConfigType;

export default class MyLog {
    public logmessages!: LogMessage[];
    public static Current: MyLog = new MyLog();
    public oldLogFunction: any;
    public oldWarnFunction: any;
    public oldErrorFunction: any;
    private insights?: ApplicationInsights;

    constructor() {
        this.logmessages = [];
    }

    public static initLog() {
        MyLog.Current.Init();
        (window as any).myLog = this;
    }

    public Init() {
        if (typeof console !== "undefined") {
            if (typeof console.log !== 'undefined') { this.oldLogFunction = console.log; }
            if (typeof console.warn !== 'undefined') { this.oldWarnFunction = console.warn; }
            if (typeof console.error !== 'undefined') { this.oldErrorFunction = console.error; }
        }

        console.log = (msg, ...optionalParams: any[]) => MyLog.Current.log(msg, optionalParams);
        console.warn = (msg, ...params: any[]) => MyLog.Current.warn(msg, params);
        console.error = (msg, ...params: any[]) => MyLog.Current.error(msg, params);

        if (ALLConfig.azureLog) {
            const appInsights = new ApplicationInsights(ALLConfig.azureLog);
            appInsights.loadAppInsights();
            appInsights.trackPageView();

            const telemetryInitializer = (envelope: ITelemetryItem) => {
                try {
                    if (envelope?.data) {
                        envelope.data.db = AUApi?.Current?.dB;
                        envelope.data.allUserId = AUApi?.Current?.user?.kennung;
                    } else {
                        envelope.data = { db: AUApi?.Current?.dB, allUserId: AUApi.Current?.user?.kennung };
                    }
                } catch { /* nothing to do here */ }
            };
            appInsights.addTelemetryInitializer(telemetryInitializer);

            this.insights = appInsights;
        }
    }

    public log(message?: any, ...optionalParams: any[]): void {
        try {
            this.oldLogFunction(message, optionalParams);
            this.logit({ type: logType.Debug, message, optionalParams, timestamp: new Date() });
        } catch (error) {
            // oops...
            this.oldErrorFunction(error);
        }
    }
    public warn(message?: any, ...optionalParams: any[]): void {
        try {
            this.oldWarnFunction(message, optionalParams);
            this.logit({ type: logType.Warning, message, optionalParams, timestamp: new Date() });
        } catch (error) {
            // oops...
            this.oldErrorFunction(error);
        }
    }
    public error(message?: any, ...optionalParams: any[]): void {
        try {
            this.oldErrorFunction(message, optionalParams);
            this.logit({ type: logType.Error, message, optionalParams, timestamp: new Date() });
        } catch (error) {
            // oops...
            this.oldErrorFunction(error);
        }
    }

    private logit(item: LogMessage) {
        this.logmessages.push(item);

        let severityLevel = 3;
        switch (item.type) {
            case logType.Debug: severityLevel = 1; break; // eSeverityLevel.Information; break;
            case logType.Warning: severityLevel = 2; break; // eSeverityLevel.Warning; break;
        }

        if (item.optionalParams && item.optionalParams.length) {
            this.insights?.trackTrace({ message: item.message, severityLevel },
                { properties: { type: item.type } });
        } else {
            this.insights?.trackTrace({ message: item.message, severityLevel });
        }
    }

    public clean() {
        this.logmessages.length = 0;
    }

    public flushLog(doAsync: boolean = false) {
        this.insights?.flush(doAsync);
    }
}

export enum logType {
    Debug = 0,
    Warning = 1,
    Error = 2,
}

// tslint:disable-next-line: max-classes-per-file
export class LogMessage {
    public type!: logType;
    public message!: string;
    public optionalParams: any;
    public timestamp!: Date;
}

