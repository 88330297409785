
















import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import CreateList from "../CreateList";
import { getEinheitFromColumn, getKZColumn, getKZTitle, getKZValue } from "@/DataHelper";
@Component
export default class RelativeDestinationQueryChart extends CreateList {
  @Prop({ default: 50 }) public height!: number;
  @Prop({ default: "red" }) public overColor!: string;
  @Prop({ default: "green" }) public underColor!: string;
  @Prop({ default: "blue" }) public remainingColor!: string;
  @Prop({ default: "white" }) public textColor!: string;
  @Prop({ default: "black" }) public maxTextColor!: string;
  @Prop({ default: 20 }) public textWithPercent!: number;

  private value: number = 0;
  private destination: number = 0;
  private unit: string = "";
  private tooltip: string = "";

  public mounted() {
    try {
      const me = this;
      if (!this.mountedBase()) {
        return;
      }
    } catch (e: any) {
      console.error("mounterror: " + e, e?.stack);
    }
  }

  protected refreshItems() {
    const col = getKZColumn("dest", this.columns);
    this.unit = getEinheitFromColumn(col).kennung;
    if (this.data.length > 0) {
      for (let i = 0; i < this.data.length; i++) {
        const row = this.data[i];
        const aic = getKZValue(row, "aic", this.columns, true);
        if (aic === this.aic) {
          this.value = getKZValue(row, "value", this.columns);
          this.destination = getKZValue(row, "dest", this.columns);
          this.tooltip = getKZTitle(row, "dest", this.columns);
        }
      }
    }
  }
}
