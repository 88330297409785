























import { Vue, Component, Prop, Provide } from "vue-property-decorator";

@Component
export default class extends Vue {
  private currentOffset: number = 0;
  @Prop({ default: 3 }) private windowSize!: number;
  private paginationFactor: number = 220;
  private items: any[] = [];

  get atHeadOfList() {
    return (
      this.currentOffset <=
      this.paginationFactor * -1 * (this.items.length - this.windowSize)
    );
  }
  get atEndOfList() {
    return this.currentOffset === 0;
  }
}
