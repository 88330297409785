





















import Constants from "@/Constants";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SmallButtonBar extends Vue {
  private showDebug = false;
  @Prop({ default: true }) public showDark!: boolean;
  @Prop({ default: "" }) public tooltipMounted!: string;
  @Prop({ default: false }) public showInfo!: boolean;
  @Prop({ default: false }) public showPrint!: boolean;
  private mounted() {
    // this.showDebug = Constants.isDebug;
    if (Constants.isMobile) {
      this.showPrint = false;
    }
  }
}
