import CryptoJS from "crypto-js";

export default class Caching {
    private mIsEnabled: boolean = true;
    public get isEnabled(): boolean {
        return this.mIsEnabled;
    }
    public set isEnabled(v: boolean) {
        this.mIsEnabled = v;
    }

    public static Instance: Caching = new Caching();

    public cache: Map<string, CacheEntry> = new Map<string, CacheEntry>();

    private getKey(request: string): string {
        return CryptoJS.MD5(JSON.stringify(request)).toString();
    }

    public cacheRequest(request: any, response: any) {
        if (!response) { return; }
        const key: string = this.getKey(request);
        this.cache.set(key, new CacheEntry(key, response, new Date()));
    }

    public checkCache(request: any): any {
        const key: string = this.getKey(request);
        if (this.cache.has(key)) {
            const cached = this.cache.get(key);
            return cached?.response;
        }
        return null;
    }

    public clearCache() {
        this.cache.clear();
    }

    public checkAsync(request: any, doIt: (r: any) => Promise<any>): Promise<any> {
        const x = Caching.Instance.checkCache(request);
        if (x) {
            return new Promise((resolve, reject) => {
                resolve(x);
                return x;
            });
        } else {
            return doIt(request)
                .then((resp: any) => {
                    Caching.Instance.cacheRequest(request, resp);
                    return resp; // response neu zurückgeben, damit es auch weitergegeben wird...
                });
        }
    }
}

// tslint:disable-next-line: max-classes-per-file
class CacheEntry {
    public key!: string;
    public response!: any;
    public cacheTime!: Date;


    public constructor(key: string, response: any, cacheTime: Date) {
        this.key = key;
        this.response = response;
        this.cacheTime = cacheTime;
    }
}
