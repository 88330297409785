










































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { VarUebergabe } from "@/CalcHelperStatic";

@Component
export default class Monatsabschluss extends EditPage {
  private showVorlaufig: boolean = true;
  private setVorlaufig: boolean = false;
  private varVorlaufig: VarUebergabe[] = [];
  public showZeitraumInHeader: boolean = true;
  private activeTabIndex: number = 0;

  constructor() {
    super();
    this.query = "Web_RK_Firma_Monatsabschluss";
  }
  protected initialize() {
    this.varVorlaufig.push({
      name: "vorlaufig",
      value: this.setVorlaufig,
      type: "boolean",
    });

    if (this.data.length > 0) {
      this.showVorlaufig = false;
    }
  }
  private changeVar() {
    this.varVorlaufig[0].value = this.setVorlaufig;
  }
}
