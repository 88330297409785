



import { Vue, Component } from "vue-property-decorator";
@Component
export default class AufgabenTemplatedPagePersoenlich extends Vue {
  public showZeitraumInHeader: boolean = true;
  // nothing to do here
}
