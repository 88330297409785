








































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { getKennungValueDisplay, getKennungTitle } from "@/DataHelper";
import { showSnackbar } from "@/UIHelper";
import { Globals } from "@/Globals";

@Component
export default class EditKostenersatz extends EditPage {
  constructor() {
    super();
    this.query = "Web_NewManKostensatz";
    if (this.siteData.editQuery) {
      this.query = this.siteData.editQuery;
      this.mainAic = this.aic; // MA AIC zum Speichern übergeben!
    }
  }
  public disabled: boolean = false;
  public clearable: boolean = false;
  private showDeleteMask: boolean = false;
  private kostenart: string = "";
  private showEssen: boolean = false;
  private showKredit: boolean = false;
  private showBezahlt: boolean = false;

  public mounted() {
    const Stamm = this.$globalsStt(Globals.Stt, "", "RK_TRAVEL");
    this.jokerStt.push({
      sync: this.siteData.aicReise, // aic Stammsatz
      aic: Stamm.aic, // aic Stammtyp
    });
    if (this.siteData.editQuery) {
      this.query = this.siteData.editQuery;
      this.mainAic = this.aic; // MA AIC zum Speichern übergeben!
    }
    if (this.siteData.bewegungsdaten) {
      this.bewegungsdaten = true;
      this.aic = this.siteData.aicBewegung;
    } else {
      if (this.siteData.aicReise) {
        this.bewegungsdaten = false;
        this.aic = this.siteData.aicReise;
      } else {
        this.mainAic = this.$api.user.aic;
        this.mainStt = this.header.aic_Stamm; // MA AIC zum Speichern übergeben!
      }
    }
    this.showLoading = true;
    this.mountedBase();
    if (this.siteData.showDelete) {
      this.showDeleteMask = this.siteData.showDelete;
    }
  }
  protected initialize() {
    const bezahlt = getKennungTitle("RK_NOTPAYED", this.columns);
    if (bezahlt) {
      this.showBezahlt = true;
    }
    const kredit = getKennungTitle("RK_FIRMENKREDITKARTE", this.columns);
    if (kredit) {
      this.showKredit = true;
    }
    const essen = getKennungTitle("RK_ANZ_PERS", this.columns);
    if (essen) {
      this.showEssen = true;
    }
  }
  private save() {
    // achtung wird von den Reisen und vom MA direkt auch aufgerufen!
    const aic = this.siteData.aicReise ?? this.$api.user.aic;
    const me = this;
    this.onSave(0, "", "", aic)?.then(() => {
      // const dataSaved = true;
      // this.$emit("onReloadData", { dataSaved });
      this.$nextTick(() => me.$emit("save"));
    });
  }
  public bewDelete() {
    if (this.data && this.data.aic_Bew_pool) {
      this.onDelete(this.data.aic_Bew_pool, "");
      // const dataSaved = true;
      // this.$emit("onReloadData", { dataSaved });
      this.$emit("back");
    }
  }
}
