var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"pr-5",attrs:{"max-width":_vm.maxWidth}},[_c('CommonVCardDialog',{staticClass:"ma-2 pa-0",staticStyle:{"min-width":"350px"},attrs:{"textQuery":"Web_Periodensaldo","text":_vm.$globalsBezeichnung(
        _vm.Globals.Begriff,
        'Web_aktSaldo',
        'akt. Periodensaldo'
      ),"textKennung":"BERECHNUNG_MASS","zeitbereich":this.siteData.zeitbereich}}),_c('CommonVCardDialog',{staticClass:"ma-2 pa-0",staticStyle:{"min-width":"350px"},attrs:{"textQuery":"ZE_Gesamtsaldo_Neu","text":_vm.$globalsBezeichnung(
        _vm.Globals.Begriff,
        'Web_Gesamtsaldo',
        'akt. Gesamtsaldo'
      ),"textKennung":"BERECHNUNG_MASS","zeitbereich":this.siteData.zeitbereich}}),_c('CommonVCardDialog',{staticClass:"ma-2 pa-0",staticStyle:{"min-width":"350px"},attrs:{"query":"","textQuery":"ZE_Alle_Anspruche__In__Tagen","text":_vm.$globalsBezeichnung(_vm.Globals.Begriff, 'Web_Text_Anspruch', 'Ansprüche'),"textKennung":"Tage","text1Kennung":"Abgearbeitet","text1":_vm.$globalsBezeichnung(_vm.Globals.Begriff, 'Web_Stichtag', 'Stichtag'),"zeitbereich":_vm.zeitbereichUrlaub}}),_c('v-card',{staticClass:"ma-2 pa-0",staticStyle:{"min-width":"350px"}},[_c('v-card-text',{staticClass:"pa-0"},[_c('PzePersoenlichWfWorkflowSaldenAntrag',{attrs:{"zeitbereichUebergabe":this.siteData.zeitbereich},on:{"back":function($event){return _vm.$emit('back')}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }