export const enStrings = {
  dropdowns: {
    noRecordsTemplate: "no data found",
    actionFailureTemplate: "could not load data"
  },
  daterangepicker: {
    placeholder: "select a range",
    startLabel: "select a start date",
    endLabel: "select a end date",
    applyText: "apply",
    cancelText: "cancel",
    selectedDays: "selected days",
    days: "days",
    thisWeek: "current week",
    thisMonth: "current month",
    lastMonth_3: "last month",
    lastYear_4: "last year",
    customRange: "custom range",
    today: "today"
  },
  datepicker: {
    placeholder: "select a date",
    today: "today"
  },
  grid: {
    EmptyRecord: "no records",
    GroupDropArea: "Drag a column header here to group the column",
    UnGroup: "Click here to ungroup",
    EmptyDataSourceError: "no data found",
    Item: "item",
    Items: "item",
    Add: "new",
    Delete: "delete",
    Update: "save",
    Cancel: "cancel",
    Edit: "edit",
    Print: "print",
    Columns: "columns",
    ChooseColumns: "choose",
    SelectAll: "all",
    Search: "search"
  },
  pager: {
    currentPageInfo: "{0} of {1} pages",
    totalItemsInfo: "({0} items)",
    firstPageTooltip: "to first page",
    lastPageTooltip: "to last page",
    nextPageTooltip: "to next page",
    previousPageTooltip: "back to last page",
    nextPagerTooltip: "to next pager",
    previousPagerTooltip: "to last Pager"
  },
  schedule: {
    day: "day",
    week: "week",
    workWeek: "workweek",
    month: "month",
    agenda: "agenda",
    weekAgenda: "week agenda",
    workWeekAgenda: "workweek - agenda",
    monthAgenda: "month - agenda",
    today: "today",
    noEvents: "no data",
    emptyContainer: "no data found",
    allDay: "whole day",
    start: "start",
    end: "end",
    more: "more",
    close: "close",
    cancel: "cancel",
    noTitle: "(no title)",
    delete: "delete",
    deleteEvent: "delete entry",
    deleteMultipleEvent: "delete multiple entries",
    selectedItems: "selected item",
    deleteSeries: "delete series",
    edit: "edit",
    editSeries: "edit series",
    editEvent: "edit",
    createEvent: "new",
    subject: "subject",
    addTitle: "new titel",
    moreDetails: "details",
    save: "save",
    editContent: "Do you want to edit only this event or entire series?",
    deleteRecurrenceContent: "Do you want to delete only this event or entire series?",
    deleteContent: "Are you sure you want to delete this event?",
    deleteMultipleContent: "Are you sure you want to delete the selected events?",
    newEvent: "New Event",
    title: "title",
    saveButton: "save",
    cancelButton: "cancel",
    deleteButton: "delete"
  }
};
