







































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { getKennungValueDisplay } from "@/DataHelper";

@Component
export default class WebWorkflowAntragVerfallzeit extends EditPage {
  constructor() {
    super();
    this.query = "Web_Workflow_Antrag_Verfallzeit";
  }
  private anzeige: boolean = true;

  public mounted() {
    const antrag = getKennungValueDisplay(
      this.data,
      "ZE_ANTRAG_BOOL3",
      this.columns
    );
    if (antrag > 0) {
      // derzeit kommt als Ergebnis "abgelehnt.."
      this.anzeige = false;
    }
  }
}
