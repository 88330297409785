import { Globals } from "@/Globals";
import { showDialog, showSnackbar } from "./UIHelper";

export interface SperrResult {
  sperrDatum: Date;
  sperrDate: string;
}

export default class SperreHelper {
  public app: Vue | null = null;
}
export function findSperrDate(query: string, aic: number, app: Vue) {
  try {
    const begriff = app.$globalsKennung(Globals.Begriff, query);
    if (begriff) {
      const persoenlich = begriff.perAbschluss; // fehlt aktuell noch!
      const sperre: any = app.$api.Periodensperre.find((p: any) => {
        // Pürfung auf pers. Sperre einbauen
        // if (p.art === "Generell") { }
        if (persoenlich && p.art === "Vorgesetzt") { return false; }
        // if (p.art === "Allgemein") { }
        if (p.aicBew && p.aicBew.indexOf(begriff.bew) > 0) {
          if (p.vecStamm && p.vecStamm.indexOf(aic) < 0) {
            return false;
          }
          return true;
        }
        return false;
      });
      if (sperre) {
        let abDatum;
        if (sperre.ab) {
          abDatum = new Date(sperre.ab); // ab wann ist die Sperre gültig}
        }
        // const periode = sperre.weiter; // Sperre gültig pro Jahr, Quartal...
        // const today: Date = new Date(new Date().toISOStringWithTZGMT());
        const sperrDatum = new Date(sperre.datum);
        const sperrDate = sperrDatum.toISOStringWithTZGMT();
        // const tage = sperre.tage;

        // if (abDatum && abDatum > today) {
        //   sperrDatum = addWeiter(sperrDatum, periode, tage, false);
        // }
        return { sperrDatum, sperrDate };
      }
    }
  } catch (ex) {
    console.log("Periodensperre hat nicht geklappt", ex);
  }
}
export function findKennungSperre(begriff: string, app: Vue) {
  try {
    if (begriff) {
      const sperre: any = app.$api.Periodensperre.find(p => p.kennung === begriff);
      if (sperre) {
        let abDatum;
        if (sperre.ab) {
          abDatum = new Date(sperre.ab); // ab wann ist die Sperre gültig}
        }
        // const periode = sperre.weiter; // Sperre gültig pro Jahr, Quartal...
        // const today: Date = new Date(new Date().toISOStringWithTZGMT());
        const sperrDatum = new Date(sperre.datum);
        const sperrDate = sperrDatum.toISOStringWithTZGMT();
        // const tage = sperre.tage;
        // if (abDatum && abDatum > today) {
        //   sperrDatum = addWeiter(sperrDatum, periode, tage, false);
        // }
        return { sperrDatum, sperre };
      }
    }
  } catch (ex) {
    console.log("Periodensperre hat nicht geklappt", ex);
  }
}
function addWeiter(sperrDatum: Date, periode: string, tage: number, prev: boolean) {
  if (periode === "Monat") {
    if (prev) {
      sperrDatum = sperrDatum.addMonths(1, false);
    } else {
      sperrDatum = sperrDatum.addMonths(-1, false);
      // sperrDatum = sperrDatum.addDays(-1);
    }
  } else if (periode === "Year") {
    if (prev) {
      sperrDatum = sperrDatum.addMonths(12, false);
    } else {
      sperrDatum = sperrDatum.addMonths(-12, false);
    }
  } else if (periode === "Quartal") {
    if (prev) {
      sperrDatum = sperrDatum.addMonths(3, false);
    } else {
      sperrDatum = sperrDatum.addMonths(-3, false);
    }
  } else if (periode === "Tag") {
    if (prev) {
      sperrDatum = sperrDatum.addDays(tage);
    }
  } else {
    sperrDatum = sperrDatum.addDays(-tage);
  }
  return sperrDatum;
}

