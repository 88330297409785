<template>
  <div style="width: 100%">
    <v-tabs grow>
      <v-tab id="tab-editor">EditControls</v-tab>
      <v-tab id="tab-editrows">Test</v-tab>
      <v-tab id="tab-editrows">Grid</v-tab>
      <v-tab id="tab-editrows">Snackbar</v-tab>
      <v-tab id="tab-editrows">RelativeDestinationChart</v-tab>
      <v-tab id="tab-editrows">Charts</v-tab>
      <v-tab id="tab-editrows">TreeGrid</v-tab>
      <v-tab id="tab-editrows">DynDashboard</v-tab>
      <v-tab-item>
        <testEditor></testEditor>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <h2>Masked TextBox</h2>
          <br />
          <v-text-field v-model="inputMask" label="mask" />
          <br />
          <br />
          <MaskTextField
            label="maskedTextBox"
            prepend-icon="add"
            append-icon="delete"
            :mask="inputMask"
          />

          <br />
          <ul>
            <li>1 - number</li>
            <li>a - letter</li>
            <li>A - letter, forced to upper case when entered</li>
            <li>* - alphanumeric</li>
            <li># - alphanumeric, forced to upper case when entered</li>
            <li>+ - any character</li>
          </ul>
          <br />ursprüngliche Quelle:
          <a href="https://github.com/niksmr/vue-masked-input" target="_blank"
            >https://github.com/niksmr/vue-masked-input</a
          >
        </v-card>
        <v-card>
          <h2>DateLoading</h2>
          <v-text-field v-model="yearToLoad" label="year" />

          <v-btn primary @click="loadHolidays">load holidays</v-btn>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <div>
          <h1>DEMO TEST</h1>
          <ejs-grid
            :dataSource="griddata"
            :editSettings="editSettings"
            :toolbar="toolbar"
            height="273px"
          >
            <e-columns>
              <e-column
                field="OrderID"
                headerText="Order ID"
                textAlign="Right"
                :isPrimaryKey="true"
                width="100"
              ></e-column>
              <e-column
                field="CustomerID"
                headerText="Customer ID"
                width="120"
              ></e-column>
              <e-column
                field="Freight"
                headerText="Freight"
                textAlign="Right"
                editType="numericedit"
                width="120"
                format="C2"
              ></e-column>
              <e-column
                field="OrderDate"
                headerText="Order Date"
                type="date"
                format="yMd"
                width="150"
                :edit="getEditCell({ title: 'xx' })"
              ></e-column>
            </e-columns>
          </ejs-grid>
        </div>
      </v-tab-item>
      <v-tab-item>
        <h1>Snackbars</h1>
        <v-btn @click="showSb">test</v-btn>
        <v-card>
          <v-layout row wrap>
            <v-flex>
              text:
              <v-textarea v-model="snackBarData.text" />
              Style:
              <v-radio-group v-model="snackBarData.style">
                <v-radio label="rounded" value="rounded" />
                <v-radio label="shaped" value="shaped" />
              </v-radio-group>
              Position:
              <v-radio-group v-model="snackBarData.position">
                <v-radio label="left" value="left" />
                <v-radio label="center" value="center" />
                <v-radio label="right" value="right" />
              </v-radio-group>
              Horizontal Position:
              <v-radio-group v-model="snackBarData.position">
                <v-radio label="top" value="top" />
                <v-radio label="bottom" value="bottom" />
              </v-radio-group>
              <v-btn @click="$emit('snackbar', snackBarData)"
                >Show Snackbar</v-btn
              >

            </v-flex>
          </v-layout>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-text-field v-model="realValue" label="value" />
        <v-text-field v-model="destinationValue" label="destination" />
        <v-text-field v-model="unit" label="unit" />

        <MdRelativeDestChart :destination="destinationValue" :value="realValue" :unit="unit"  />
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <v-layout row wrap>
            <v-flex>
        <DashboardChartsCChart query="WEB_Status_GS_Chart_alle_MA" chartType="Line" />
            </v-flex>
            <v-flex>
        <DashboardChartsCChart query="WEB_Status_GS_Chart_alle_MA" chartType="Bar" />
            </v-flex>
            <v-flex>
        <DashboardChartsCChart query="WEB_Status_GS_Chart_alle_MA" chartType="Pie" />
            </v-flex>
          </v-layout>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <TreeGridView></TreeGridView>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <!-- <DashboardDynamicBoard /> -->
          <AufgabenTemplatedPage />  
        </v-card>
      </v-tab-item>
    </v-tabs>
  </div>
</template>
<script>
import testEditor from "../editor/TestEditor.vue";
import Vue from "vue";
import { Page, Toolbar, Edit } from "@syncfusion/ej2-vue-grids";
import {
  getUniqueID,
  createElement,
} from "@syncfusion/ej2-base";

import GridMapper from "../editor/GridMapper.vue";
import { showSnackbar } from '@/UIHelper';

export default {
  components: {
    testEditor,
  },
  data() {
    return {
      unit: "h",
      destinationValue: 100,
      realValue: 200,
      inputMask: "+++++++++",
      yearToLoad: 2000,
      griddata: [
        {
          OrderID: 1,
          CustomerID: 1,
          Freight: 2,
          OrderDate: new Date().toISOString(),
        },
        {
          OrderID: 2,
          CustomerID: 1,
          Freight: 2,
          OrderDate: new Date().toISOString(),
        },
        {
          OrderID: 3,
          CustomerID: 2,
          Freight: 2,
          OrderDate: new Date().toISOString(),
        },
        {
          OrderID: 4,
          CustomerID: 2,
          Freight: 2,
          OrderDate: new Date().toISOString(),
        },
      ],
      snackBarData: {
        text: "test snackbar",
        position: "bottom",
        hPosition: "right",
        color: undefined,
        duration: 3000,
        isMessage: false,
        closedManual: false,
        onClose: (s) => {
          console.log("sb closed...");
        },
        height: undefined,
        style: "rounded",
      },
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Normal",
      },
      toolbar: ["Add", "Edit", "Delete", "Update", "Cancel"],

      selectedRow: null,
      mask: "##:##",
      value: "",
      filterOvr: {
        e584: "DropDown",
        e219: "Text",
        e217: "DropDown",
        e227: "DropDown",
        e217_464: "DropDown",
      },
      filterData: ["value1", "value2", "value3"],
    };
  },
  provide: {
    grid: [Page, Edit, Toolbar],
  },
  props: {},
  methods: {
    getEditCell(column) {
      const pid = getUniqueID("templateParentDiv"); // create a emty div as container
      const iid = getUniqueID("templateParentDiv"); // create a emty div as container
      const ele = createElement("div", { id: pid });

      document.body.appendChild(ele); // append it somewhere

      // https://css-tricks.com/creating-vue-js-component-instances-programmatically/
      // prepare the vue Component for it
      const VueGridMapper = Vue.extend(GridMapper);
      let instance = null; // no current instance
      let rowData = null;
      let syncColumn = null;
      return {
        create(data) {
          console.log("template create()");
          return ele; // return the container element
        },
        read() {
          console.log("template read()");
          // return the value of the instance - but that gets never invoked!!
          return instance.getValue();
        },
        write(val) {
          console.log("template write()"); // mount the component to the container element
          instance = new VueGridMapper({
            // create a new one
            propsData: { column },
          });
          rowData = val.rowData; // remember the data
          syncColumn = val.column; // remember the column

          /* !!! WORKARROUND !!! listen to the event of the component and map it yourself ;) */
          instance.$on("input", (newdata) => {
            console.log("new value for " + syncColumn.field + " " + newdata);
            rowData[syncColumn.field] = newdata; // write the data in the specific field of the data
          });

          instance.$mount(ele); // mount it and pass the container
          instance.setValue(val.rowData[syncColumn.field]); // set the current data in the component
        },
        destroy() {
          try {
            console.log("template destroy()"); // destroy it again...
            instance.$destroy();
            instance = null;
          } catch (ex) {
            console.log("error destroying:", ex);
          }
        },
      };
    },
    loadHolidays() {
      const date = new Date(this.yearToLoad + "-01-01");
      this.$api
        .checkHolidayRange(date)
        .then((holiday) => {
          alert("erfolgreich: " + JSON.stringify(holiday));
        })
        .catch((e) => alert(e));
    },
    rowSelected(item) {
      console.log("item selected " + item.item + " - " + item.column.name);
    },
    onBack() {
      this.selectedRow = null;
    },
    showSb() {
      showSnackbar("haha " + new Date().toISOStringWithTZWithMs());
    }
  },
};
</script>

<style scoped>
</style>
