import { Geolocation } from '@capacitor/geolocation';
import { Capacitor, CapacitorCookies } from "@capacitor/core";
import Constants from "./Constants";


export interface GeoLocation {
    latitude: number;
    longitude: number;
}

export function getGeoLocation(): Promise<GeoLocation> {
    console.log(Capacitor.getPlatform());
    if (Capacitor.getPlatform() === "web") {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
                    if (position) {
                        resolve({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude
                        });
                    } else { reject("WEB: no location found!"); }
                }, (error?: any) => {
                    if (error) {
                        reject("ERROR Function: no location found!" + " " + error);
                    }
                });
            } else { reject("GeloLocation: no location found!"); }
        });
    } else {
        return getLocationFromDevice();
    }
}
async function getLocationFromDevice(): Promise<GeoLocation> {

    let permissions = false;
    try {
        const permissionState = await Geolocation.checkPermissions();
        // irgendeine permission brauch ma
        permissions = permissionState.location === 'granted' || permissionState.coarseLocation === 'granted';
    } catch (error) {
        console.warn("got no permission to location:" + error);
    }
    if (!permissions) {
        try {
            const permissionState = await Geolocation.requestPermissions({
                permissions:
                    ['location', 'coarseLocation']
            });
            permissions = permissionState.location === 'granted' || permissionState.coarseLocation === 'granted';
        } catch (error) {
            // info ausgeben
            console.warn("got no permission to location after asking:" + error);
        }
    }

    if (!permissions) {
        throw new Error("no Permission: no location found!");
    }

    const coordinates = await Geolocation.getCurrentPosition();
    return {
        latitude: coordinates.coords.latitude,
        longitude: coordinates.coords.longitude
    };
}
