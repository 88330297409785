

















import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditComponent from "@/editor/EditComponent";

@Component
export default class EditRadioButtons extends EditComponent {
  private list: any = [];
  private selectedIndex: number = -1;
  private aicMode: boolean = true;
  private text: string = "";

  public mounted() {
    const initValue = this.getInitValue();
    if (initValue) {
      this.data = initValue;
    }
    if (this.column.meta.combo) {
      for (let i = 0; i < this.column.meta.combo.length; i++) {
        const entry = this.column.meta.combo[i];
        if (typeof entry === "object") {
          this.list.push(entry);
          this.aicMode = true;
          if (initValue && initValue.aic === entry.aic) {
            this.selectedIndex = i;
          }
        } else {
          this.list.push({ aic: entry, bezeichnung: entry });
          this.aicMode = false;
          if (initValue && initValue === entry) {
            this.selectedIndex = i;
          }
        }
      }
    }
    this.valueChanged("");
  }
  public validate() {
    if (this.required) {
      if (this.text) {
        return true;
      }
      return false;
    }

    return true;
  }
}
