























































import { Vue, Component, Prop } from "vue-property-decorator";
import BaseSyncSchedule from "../baseSyncSchedule.vue";
import Constants from "@/Constants";
import { Globals } from "@/Globals";
import { onShowSite } from "@/NavigationHelper";

@Component
export default class TemplatesResourceHeader extends Vue {
  @Prop() public row!: any;
  @Prop() public schedule!: BaseSyncSchedule;
  public created() {
    // fix initialisation if icons
    const app = Constants.App;
    if (app) {
      (this.$vuetify as any).icons = (app as any).$vuetify.icons;
    }
  }

  private openStammdaten(aic: number) {
    if (this.checkBegriff("LV_ReiseabrechnungAdmin")) {
      onShowSite({
        title: "",
        site: "StammPerson",
        data: { aic },
        callOnLeave: () => {
          return true;
        },
      });
    }
  }
  protected checkBegriff(kennung: string) {
    const x = this.$globalsKennung(Globals.Begriff, kennung, false);
    return x;
  }
}
