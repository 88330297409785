
































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
@Component
export default class Anwesenheiten extends Vue {
  @Prop() public aic: any;
  @Prop({ default: null }) public siteData: any;
  private showClose: boolean = false;
  public mounted() {
    if (this.siteData.showBack) {
      this.showClose = this.siteData.showBack;
    }
  }
}
