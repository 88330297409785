






import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditComponent from "@/editor/EditComponent";

@Component
export default class EditTitel extends EditComponent {
  protected text: string = "";

  public mounted() {
    this.text = this.column.title;
    if (this.text === undefined) {
      this.text = "";
    }
  }
}
