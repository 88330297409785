




























































































import { Component, Prop } from "vue-property-decorator";
import EditComponent from "@/editor/EditComponent";
import { RenderDayCellEventArgs } from "@syncfusion/ej2-calendars";
import {
  getDateFromInput,
  getMask,
  highlightDays,
  valueToFormatStr,
  getHolidayColorVuetify,
} from "./CalenderHelper";
import { findSperrDate } from "@/SperreHelper";
import ValueChangeTracker from "./ValueChangeTracker.vue";
@Component
export default class EditDateRange extends EditComponent {
  public von?: Date;
  public bis?: Date;

  private pickerDates: string[] = [];
  private showPicker: boolean = false;

  private vonStr: string = ""; // String Anzeige
  private bisStr: string = "";

  private minDate: Date | null = null;

  private vonError: string = "";
  private bisError: string = "";

  public IsAllDay: boolean = true;
  private allDayVisible: boolean = true;
  private showFields: boolean = true;
  private mask: string = "D1.M1.1111";

  private calendarVal: string = "";
  private dateFormat: string = "dd-MM-yyyy";
  private format: string = "dd.MM.yyyy";

  private allDayFormat: string = "dd.MM.yyyy";
  private notAllDayFormat: string = "HH:mm dd.MM.yyyy";
  @Prop({ default: null }) public sperrBegriff!: any | null;

  /* presets */
  private weekStart: any = new Date(
    new Date(
      new Date().setDate(new Date().getDate() - ((new Date().getDay() + 7) % 7))
    ).toDateString()
  );
  private weekEnd: any = new Date(
    new Date(
      new Date().setDate(
        new Date(
          new Date().setDate(new Date().getDate() - ((new Date().getDay() + 7) % 7))
        ).getDate() + 6
      )
    ).toDateString()
  );
  // private monthStart: any = new Date(
  //   new Date(new Date().setDate(1)).toDateString()
  // );
  // private monthEnd: any = new Date(
  //   new Date(
  //     new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0)
  //   ).toDateString()
  // );
  // private lastStart: any = new Date(
  //   new Date(
  //     new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)
  //   ).toDateString()
  // );
  // private lastEnd: any = new Date(
  //   new Date(new Date().setDate(0)).toDateString()
  // );
  // private yearStart: any = new Date(
  //   new Date(new Date().getFullYear() - 1, 0, 1).toDateString()
  // );
  // private yearEnd: any = new Date(
  //   new Date(new Date().getFullYear() - 1, 11, 31).toDateString()
  // );

  // bitte die nächsten x events vom picker ignorieren weil der grad von uns gefeuert wird ;)
  private ignoreNextPickerEvents: number = 2;

  public get selectedDatesText() {
    if (this.pickerDates.length > 1) {
      const args = this.pickerDates;
      let bis = new Date(args[1]);
      let von = new Date(args[0]);
      if (von && bis && von > bis) {
        const x = von;
        von = bis;
        bis = x;
      }
      const diff = von.diffInDays(bis);
      const text = this.$begriffBezeichnung("Tage");
      return diff + " " + text;
    }
    return "--";
  }

  public mounted() {
    this.changing = true;
    this.mountedBase();

    if (this.sperrBegriff) {
      const sperrDate = this.getSperrDate(this.sperrBegriff);
      if (sperrDate) {
        this.minDate = new Date(sperrDate);
      }
    }
    // im data steht es richtig drinenen - auch wenn NEU Zustand ist!
    if (!this.data?.IsAllDay) {
      this.IsAllDay = false;
    }

    if (this.IsAllDay) {
      this.format = this.allDayFormat;
    } else {
      this.format = this.notAllDayFormat;
    }
    this.mask = getMask(this.format);
    const val = this.getInitValue();
    if (val) {
      if (val.IsAllDay === true) {
        if (val.von) {
          val.von = new Date(
            new Date(new Date(val.von).setHours(0)).setMinutes(0)
          ).toISOStringWithTZGMT();
        }
        if (val.bis) {
          val.bis = new Date(
            new Date(new Date(val.bis).setHours(0)).setMinutes(0)
          ).toISOStringWithTZGMT();
        }
      }
      if (val.von) {
        this.von = new Date(val.von);
        this.vonStr = valueToFormatStr(this.von, this.format);
      }
      if (val.bis) {
        this.bis = new Date(val.bis);
        let bisDisplay;
        if (val.von !== val.bis) {
          if (this.IsAllDay) {
            bisDisplay = this.bis = new Date(val.bis).addDays(-1);
          } else {
            bisDisplay = new Date(val.bis);
          }
        } else {
          bisDisplay = this.bis;
        }

        this.bisStr = valueToFormatStr(bisDisplay, this.format);
      }
    }

    if (this.von && this.bis) {
      this.pickerDates = [this.von.toISOStringWithTZ(), this.bis.toISOStringWithTZ()];
    } else if (this.von) {
      this.pickerDates = [this.von.toISOStringWithTZ(), this.von.toISOStringWithTZ()];
    } else {
      this.pickerDates = [];
    }
    // in der Datumsauswahl muss ich auch einen tag wegnehmen - damit es zusammenpasst!

    this.setPickerDates();

    this.ignoreNextPickerEvents = 2;
    this.$nextTick(() => (this.changing = false));
  }

  private setPickerDates(removeFromBis: boolean = false) {
    if (this.von && this.bis) {
      const bis = removeFromBis ? this.bis.addDays(-1) : this.bis;
      this.pickerDates = [
        this.von.toISOStringWithTZ().substr(0, 10),
        bis.toISOStringWithTZ().substr(0, 10),
      ];
    } else if (this.von) {
      this.pickerDates = [
        this.von.toISOStringWithTZ().substr(0, 10),
        this.von.toISOStringWithTZ().substr(0, 10),
      ];
    }
  }

  private allDayChanged(args: any) {
    try {
      // console.log("allDayChanged " + JSON.stringify(args));
      const von = getDateFromInput(this.vonStr, this.format);
      const bis = getDateFromInput(this.bisStr, this.format);

      this.showFields = false;
      this.IsAllDay = args;
      if (this.IsAllDay) {
        this.format = this.allDayFormat;
      } else {
        this.format = this.notAllDayFormat;
      }
      this.mask = getMask(this.format);
      this.vonStr = "";
      this.bisStr = "";
      this.$nextTick(() => {
        this.showFields = true;
        if (
          von.date &&
          von.date.toISOStringWithTZ().substr(0, 16) !== "1970-01-01T00:00"
        ) {
          this.vonStr = valueToFormatStr(von.date, this.format);
        }
        if (
          bis.date &&
          bis.date.toISOStringWithTZ().substr(0, 16) !== "1970-01-01T00:00"
        ) {
          this.bisStr = valueToFormatStr(bis.date, this.format);
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  private iconCss = "";

  private vonChanged(x: any, fire: boolean = true) {
    console.log("von...");
    try {
      const val = getDateFromInput(x, this.format);
      // console.log(x + " -> " + val);
      if (val) {
        if (val.date) {
          this.von = val.date;
          if (this.minDate) {
            if (!this.von || this.von < this.minDate) {
              const minDateStr = this.minDate.toISOStringWithTZ().substring(0, 10);
              val.error = "ab" + " " + minDateStr;
            }
          }
        }
        if (val.error) {
          this.vonError = val.error;
          fire = false; // nicht event feuern weil ungültig...
        } else {
          this.vonError = "";
        }
      }
      if (fire) {
        this.rangeChanged();
      }
    } catch (e) {
      console.error(e);
    }
  }
  private bisChanged(x: any, fire: boolean = true) {
    console.log("bis...");
    try {
      const val = getDateFromInput(x, this.format);
      // console.log(x + " -> " + val);
      if (val) {
        if (val.date) {
          this.bis = val.date;
          //  if (this.maxDate) {
          //   if (this.bis > this.maxDate) {
          //     const maxDateStr = this.maxDate
          //       .toISOStringWithTZ()
          //       .substring(0, 10);
          //     val.error = "bis" + " " + maxDateStr;
          //   }
          // }
        }
        if (val.error) {
          this.bisError = val.error;
          fire = false; // nicht event feuern weil ungültig...
        } else {
          this.bisError = "";
        }
      }

      if (fire) {
        this.rangeChanged();
      }
    } catch (e) {
      console.error(e);
    }
  }

  public validate(): boolean {
    try {
      if (
        (this.vonError && this.vonError.length > 0) ||
        (this.bisError && this.bisError.length > 0)
      ) {
        return false;
      }
      return true;
    } catch (error) {
      console.log(error);
    }
    return false;
  }

  private rangeChanged() {
    let von = null;
    let bis = null;
    if (this.von) {
      von = this.von.toISOStringWithTZ();
    }
    // if (!this.IsAllDay && this.von) {
    //   // bis = von;
    //   bis = this.von.toISOStringWithTZ();
    //   this.vonPicker = this.von;
    //   // this.bisPicker = this.von;
    // }
    if (this.bis) {
      bis = this.bis.toISOStringWithTZ();
    }
    if (this.von && this.bis && this.von > this.bis) {
      this.bisError = "von > bis !";
      // ups abbrechen
      return;
    } else {
      this.bisError = "";
    }

    this.setPickerDates();

    const val = {
      von,
      bis,
      IsAllDay: this.IsAllDay,
    };
    // 13.7.2020 das darf ich nicht machen - sonst hab ich bei MANUELLER eingabe einen Tag zu viel!
    // if (this.IsAllDay && bis) {
    //   val.bis = new Date(bis).addDays(1).toISOStringWithTZ();
    // }

    console.log(JSON.stringify(val));
    this.valueChangedRange(val);
  }

  protected valueChangedRange(val: any) {
    if (val && val.IsAllDay) {
      if (val.von) {
        val.von = new Date(val.von).asLocalDateString();
      }
      if (val.bis) {
        val.bis = new Date(val.bis).addDays(1);
        val.bis = new Date(val.bis).asLocalDateString();
      }
    }
    this.valueChanged(val);
  }

  private pickertitle: string = "Wählen sie ein Start-Datum:";
  private selectinVon: boolean = true;
  public changing = false;

  private closePicker(saveValue: boolean) {
    this.showPicker = false;
    if (saveValue) {
      const von = this.pickerDates.length > 0 ? new Date(this.pickerDates[0]) : undefined;
      const bis = this.pickerDates.length > 1 ? new Date(this.pickerDates[1]) : undefined;
      this.setVonBis(von, bis);
    }
  }
  private pickerPicked(args: any) {
    // if (this.ignoreNextPickerEvents > 0) {
    //   this.ignoreNextPickerEvents--;
    //   return;
    // }
    /*
    if (this.changing) {
      this.changing = false; // ich muss wieder zurücksetzen -
      // weil wenn zuerst manuell und dann über den Picker erfasst wird - braucht er changibg wieder auf False
      return;
    }

    this.changing = true;
    // const von = args.startDate;
    // const bis = args.endDate;
    let von = args.length > 0 ? new Date(args[0]) : null;
    let bis = args.length > 1 ? new Date(args[1]) : null;

    setVonBis(von, bis);
    */
  }

  private setVonBis(von?: Date, bis?: Date) {
    if (von && bis && von > bis) {
      const x = von;
      von = bis;
      bis = x;
    }

    let isodate = "";
    if (this.von) {
      isodate += this.von.toISOStringWithTZ().substring(0, 10); // nur das datum ;)
    }
    if (this.bis) {
      isodate += this.bis.toISOStringWithTZ().substring(0, 10);
    }

    // falls vorhanden Uhrzeit und von geschriebenem übertragen
    if (von) {
      if (this.von) {
        von.setHours(this.von.getHours());
        von.setMinutes(this.von.getMinutes());
      }
    }
    if (bis) {
      if (this.bis) {
        bis.setHours(this.bis.getHours());
        bis.setMinutes(this.bis.getMinutes());
      }
    }

    let isodateval = "";
    if (von) {
      isodateval += von.toISOStringWithTZ();
    }
    if (bis) {
      isodateval += bis.toISOStringWithTZ();
    }
    //    this.showFields = false;
    if (isodate !== isodateval) {
      this.vonStr = von ? valueToFormatStr(von, this.format) : "";
      this.von = von;
      this.bisStr = bis ? valueToFormatStr(bis, this.format) : "";
      this.bis = bis;
    }

    this.showFields = false;
    (this.$refs.von as any).$forceUpdate();
    (this.$refs.bis as any).$forceUpdate();

    this.$nextTick(() => {
      this.showFields = true;
      if (this.von && this.bis) {
        this.valueChangedRange({
          von: this.von.toISOStringWithTZ(),
          bis: this.bis.toISOStringWithTZ(),
          IsAllDay: this.IsAllDay,
        });
      } else if (this.von) {
        this.valueChangedRange({
          von: this.von.toISOStringWithTZ(),
          bis: null,
          IsAllDay: this.IsAllDay,
        });
      } else if (this.bis) {
        this.valueChangedRange(null);
      }
      this.changing = false; // muss zurück gesetzt werden! sonst geht keine Änderung über den DatePicker mehr!
    });
  }

  private getHolidayColorVuetify(args: string) {
    try {
      return getHolidayColorVuetify(new Date(args)) ?? "";
    } catch (err) {
      console.error(err);
    }
  }

  private showTooltip = false;
  private tooltipText = "";
  private onDayHover(day: string) {
    const feiertag = this.$api.getHoliday(day);
    if (feiertag) {
      this.showTooltip = true;
      this.tooltipText = feiertag.bezeichnung;
    }
  }
  private onDayLeave(args: any) {
    this.showTooltip = false;
  }

  private highlightDays(args: RenderDayCellEventArgs): void {
    try {
      return highlightDays(args, this);
    } catch (err) {
      console.error(err);
    }
  }
  protected getSperrDate(begriff: any) {
    const me = this;
    const aic = me.$api.user.aic;
    if (this.query) {
      const t = findSperrDate(this.query, aic, me);
      if (t) {
        const sperrDate = t.sperrDate;
        return new Date(sperrDate);
      }
    }
  }
}
