






















































































































































































































































import EditPage from "@/components/EditPage";
import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";

@Component({
  provide: {},
})
export default class StammAuftragDetail extends EditPage {
  constructor() {
    super();
    this.query = "Web_Stammdaten_Auftrag";
    this.bewegungsdaten = false;
    this.showLoading = true;
  }
  public showZeitraumInHeader: boolean = true;
  private position: string = "Left";
  private showBackdrop: boolean = true;
  private content0 = "...";
  private showDialog: boolean = false;
  private myHight: number = window.innerHeight;

  private sttVon: number = 0;
  private sttBis: number = 0;
  private items: any[] = [];
  public mounted() {
    if (this.siteData.aic !== 0) {
      this.selected = this.siteData.aic;
    }
    this.dialogStt.query = "Web_Neuanlage_Auftrag";
    this.items.push({
      text: "Allgemein",
      icon: "assignment",
      color: "primary",
      step: 1,
    });
    this.items.push({
      text: "Aufteilung",
      icon: "table_rows",
      color: "success",
      step: 2,
    });
    this.items.push({
      text: "Kosten",
      icon: "monetization_on",
      color: "success",
      step: 3,
    });
  }
  public initialize() {
    this.sttVon = this.header.aicStt;
    this.sttBis = this.header.aicStt;
  }
  private valueChangedHierarchie(x: any) {
    this.edit = false;
    this.showData = true;
    this.queryAIC = x[0];
    this.selected = x[0];
    if (this.siteData && this.siteData.aic !== 0) {
      this.siteData.aic = 0;
    }
    this.mountedBase();
  }

  private group: any = null;
  @Watch("group")
  private groupWatch(val: boolean) {
    if (!val) {
      this.drawer = false;
    }
  }
  protected backdrop() {
    const backdropBtn = this.$refs.backdropBtn as any;
    if (backdropBtn.$el.classList.contains("e-active")) {
      backdropBtn.$el.textContent = "True";
      // enable the backdrop property
      this.showBackdrop = false;
    } else {
      backdropBtn.textContent = "False";
      // disable the backdrop property
      this.showBackdrop = true;
    }
  }
  private onBack() {
    this.dialogOpen = false;
  }
  private onDialogClose() {
    this.reloadData();
    this.dialogOpen = false;
  }
}
