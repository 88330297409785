import LocalisatioFunctions from './local';

export enum Globals {
  Begriff,
  Einheit,
  Code,
  Mandanten,
  Stt,
  Druck,
  Layout,
  Ampel,
  Aufgabe
}

export class StaticGlobals {
  public static Current: LocalisatioFunctions;
}
