





import { Vue, Component, Prop } from "vue-property-decorator";
import qrcode from "qrcode-generator";

@Component
export default class QRCodeComponent extends Vue {
  @Prop() public value!: string;

  private get html() {
    if (!this.value) {
      return "<span>no content</span>";
    }
    try {
      const qr = qrcode(0, "H");
      qr.addData(this.value, "Byte");
      qr.make();
      return qr.createImgTag();
    } catch (error) {
      return "<span> Fehler: " + error + "</span>";
    }
  }
}
