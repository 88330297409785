


















































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { getKennungValueDisplay } from "@/DataHelper";
@Component
export default class WorkflowSaldenAntrag extends EditPage {
  // @Prop({ default: null }) protected zeitbereich!: APIZeitbereich | null;
  private dateMitarbeiter: Date | null = null;
  private dateVorgesetzter: Date | null = null;
  private textMitarbeiter: string = "";
  private iconAmpel: string = "";
  constructor() {
    super();

    this.query = "WEB_ErfassungMemo_Freigabe";
    this.showLoading = true;
  }

  public mounted() {
    this.mountedBase();
  }
  protected initialize() {
    this.dateMitarbeiter = getKennungValueDisplay(
      this.data,
      "ZE_FREIGABE_MA",
      this.columns
    );
    this.dateVorgesetzter = getKennungValueDisplay(
      this.data,
      "ZE_FREIGABE_ABLEHNUN",
      this.columns
    );
    this.textMitarbeiter = getKennungValueDisplay(
      this.data,
      "MEMO_MITARBEITER",
      this.columns
    );
    this.iconAmpel = getKennungValueDisplay(this.data, "ampel", this.columns);
    if (!this.iconAmpel) {
      this.iconAmpel = "<img src='/" + 0 + "_Ampel.png' alt='" + 0 + "' />";
    }
  }
}
