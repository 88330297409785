









































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { Globals } from "@/Globals";
import { showSnackbar } from "@/UIHelper";
import { getKennungValue, getColumnByKennung } from "@/DataHelper";
import { formatDate } from "@/editor/CalenderHelper";
import { DatePicker, DatePickerComponent } from "@syncfusion/ej2-vue-calendars";

@Component
export default class BaseEditor extends Vue {
  public thisIsAnEditPage: boolean = true;
  public isValueChangeTrackerEnabled() {
    if (!this.header.aicBew) {
      return true;
    }
    return false;
  }
  @Prop() private inputData!: any; // aus der Tabelle wird die Zeile in "inputData" übergeben
  @Prop() private query!: string;
  @Prop() private aic!: number;
  @Prop() private mainAic!: number; // zum Speichern von neuen Bewegungen..
  @Prop() private mainStt!: number;
  @Prop() private columnsOvr!: DataColumn[];
  @Prop() public preloadedData!: any;
  @Prop({ default: null }) private zeitbereich!: APIZeitbereich | null;
  @Prop() private showBaseButton!: string;
  @Prop() private showBackButton!: boolean;
  @Prop() private showSaveButton!: string;
  @Prop() private showDeleteButton!: boolean;
  @Prop() private showTitle!: boolean;
  @Prop() private maxWidth!: number;
  @Prop() private maxHeight!: number;
  @Prop() private url!: string;
  @Prop({ default: true }) protected backOnSave!: boolean;
  @Prop({ default: null }) private lastValues!: any;
  @Prop({ default: "xs12 md6 lg4 pr-5" }) private fieldCssClass!: string | null;
  @Prop({ default: false }) private bewegungsdaten!: boolean;
  @Prop({ default: false }) private readonly!: boolean;
  private activateSave: boolean = false;
  @Prop({ default: false }) protected newData!: boolean;
  @Prop({ default: false }) private openFromDialog!: boolean;

  private header: any = { bezeichnung: "lade..." };
  private columns: DataColumn[] = [];
  private allColumns: DataColumn[] = [];
  private showLoading: boolean = false;
  private data: any = [];
  private validations: any[] = [];
  private queryAIC!: number;
  private aicBew!: number;
  private aicStamm!: number;
  private stammtyp!: number;
  // private doShowBackButton: boolean = true;
  private doShowSaveButton: boolean = true;
  private doShowBaseButton: boolean = true;
  private initalized: boolean = false;
  private doShowTitle: boolean = true;
  private kennungStichtagCol: string = "STAMMSTICHTAG";

  private backupData: DataColumn[] = [];
  private stichtag: Date | null = null;
  @Prop({ default: false }) private showTimeField: boolean = false;

  public mounted() {
    if (this.aic) {
      this.queryAIC = this.aic;
    }
    if (this.showBaseButton === "false" || this.showBaseButton === "0") {
      this.doShowBaseButton = false;
    }
    if (this.showSaveButton === "false") {
      this.doShowSaveButton = false;
    }
    if (this.showTitle === false) {
      this.doShowTitle = false;
    }
    if (this.columnsOvr) {
      this.columns = this.columnsOvr;
    }
    this.reloadData();
  }

  public reloadData() {
    if (this.preloadedData) {
      this.dataLoaded(this.preloadedData);
      return;
    }
    if (!this.query) {
      showSnackbar(
        this.$globalsBezeichnung(
          Globals.Begriff,
          "Web_keine_Abfrage",
          "keine Abfrage gefunden"
        )
      );
      return;
    }
    this.showLoading = true;
    const me = this;
    const vecStamm: any[] = [];
    this.$api.getQuery(
      // Query
      this.query,
      // Done function
      (data: any) => me.dataLoaded(data),
      this.queryAIC,
      this.zeitbereich,
      0,
      false,
      this.bewegungsdaten,
      "0",
      0,
      vecStamm,
      "",
      this.newData
    );
  }

  public dataLoaded(data: any) {
    // Fehlerbehandlung
    // console.log("BaseEditor 160: " + JSON.stringify(data.data));
    if (data.data.error !== undefined) {
      this.$api.onfail(data.data.error);
      console.log(data.data.error);
      alert("ACHTUNG: " + data.data.error);
      this.$emit("back");
      return;
    }

    this.showLoading = false;
    this.initalized = true;
    for (let i = 0; i < data.data.columns.length; i++) {
      data.data.columns[i].filter = "";
    }

    this.header = data.data.header;
    this.allColumns = data.data.columns;
    this.columns = data.data.columns.filter((c: any) => c.meta.invisible !== true);
    if (this.newData) {
      for (let i = 0; i < this.columns.length; i++) {
        if (
          this.columns[i].datatype === "Austritt" ||
          this.columns[i].datatype === "Stichtag"
        ) {
          this.columns[i].meta.readOnly = true;
        }
      }
    }
    this.data = data.data.data;
    if (this.header?.aicStt) {
      this.stammtyp = this.header.aicStt;
    }
    let data2Backup = this.data;
    if (data2Backup.length > 0) {
      data2Backup = data2Backup[0];
    }
    this.backupData = JSON.parse(JSON.stringify(data2Backup));
    if (!this.header.edit) {
      this.doShowSaveButton = false;
    }
  }
  private timeChanged() {
    this.$api.stichtag = this.stichtag;
  }
  private fixTitle(text: string) {
    if (text.substr(0, 3).toLowerCase() === "web") {
      text = text.substr(4);
      text = text.replace(/_/, " ");
      text = text.replace(/_/, " ");
      return text;
    }
  }
  private getEditValue(field: any) {
    const x = this.data[0];
    if (x) {
      return x[field.name];
    }
    return x;
  }
  private getLastValue(field: any) {
    const x = this.lastValues;
    if (x) {
      return x[field.name];
    }
    return x;
  }
  private getPassValue(field: any) {
    const x = this.data[0];
    const passName = field.meta.ohrSpalte;
    if (x && passName) {
      const passValue = x[passName];
      if (passValue) {
        return passValue.sttBez + ": " + passValue.bezeichnung;
      } else if (passName && field.meta.eig2) {
        return field.meta.eig2.bezeichnung;
      }
    }
  }
  private getStichtagValue(field: any) {
    const x = this.data[0];
    const stichtagName = field.meta.stichtagSpalte;

    if (x && stichtagName) {
      const dateString = x[stichtagName];
      if (dateString) {
        const date = new Date(dateString);
        let stichtagDatum = formatDate(new Date(date), "dd.MM.yyyy");
        if (stichtagDatum === "01.01.1970") {
          stichtagDatum = "Eintritt";
        }
        return stichtagDatum;
      }
    }
  }
  // Daten vorbereiten zum Speichern
  private setValue(evt: any) {
    if (this.data.length === 0) {
      this.data.push({});
    }
    this.data[0][evt.field.name] = evt.value;
  }
  private getEditComponent(field: any) {
    const typeName =
      // richtig formatieren ==> der Compontenname wird hier zusammengesetzt!
      "edit" + field.type.substring(0, 1).toUpperCase() + field.type.substring(1);
    console.log(typeName);
    return typeName;
  }
  private onBack(updateData: any) {
    this.$emit("back");
  }
  private addValidation(v: any) {
    this.validations.push(v);
  }
  private onValidate() {
    let valid: boolean = true;
    for (let i = 0; i < this.validations.length; i++) {
      const val = this.validations[i];
      const res = val.isValid() as boolean;
      valid = valid && res;
      console.log("field " + val.field.column.title + " -> " + res);
    }

    return valid;
  }

  public getDialogData() {
    const x: any = [];
    return this.data;
  }
  private onSave() {
    this.activateSave = false;
    const isvalid = this.onValidate();
    if (!isvalid) {
      showSnackbar({
        text: "Validierung fehlgeschlagen - Bitte prüfen!",
        duration: 4000,
        color: "error",
      });
      return;
    }
    if (!this.stichtag) {
      this.stichtag = getKennungValue(
        this.data[0],
        this.kennungStichtagCol,
        this.columns
      );
    }
    // const nameStichtag = getColumnByKennung(this.kennungStichtagCol, null, this.columns);
    this.aicBew = this.data.aic_Bew_pool ?? this.data.aic_Stamm;
    let x: any = this.data;
    let getData = false;
    // this.aicStamm = this.aic;
    if (x.length && x.length > 0) {
      x = x[0];
      this.aicBew = x.aic_Bew_pool ?? x.aic_Stamm;
      if (x.aic_Stamm) {
        this.aicStamm = x.aic_Stamm;
        getData = true;
      }
      if (this.header.aicStt && !this.header.aicBew) {
        getData = true;
      }
    }
    let saveHaupt = false;
    if (this.mainAic && this.header.aicBew) {
      getData = true;
      this.aicStamm = this.mainAic;
      saveHaupt = true;
    }
    const me = this;
    this.$api.SaveData(
      this.header,
      0,
      this.aicBew,
      x,
      this.columns,
      this.zeitbereich,
      this.backupData,
      (res: any) => {
        if (!res.nothingHappend) {
          // wenn nix passiert is aber weitermachen ;)
          showSnackbar({
            text: this.$globalsBegriffMemo(
              Globals.Begriff,
              "Datensatz_gespeichert",
              "Daten wurden gespeichert"
            ),
            duration: 4000,
          });
        } else {
          showSnackbar(
            this.$globalsBegriffMemo(
              Globals.Begriff,
              "Datensatz_keine_Aenderung",
              "Daten wurden nicht verändert, kein Speichern durchgeführt!"
            )
          );
        }
        // nach dem Speichern kommen die neuen Stichtage mit
        // wie kann ich die an die Componenten übergeben?
        if (this.stichtag) {
          this.initalized = false;
          const update = res.data.data[0];
          for (const key of Object.keys(update)) {
            if (key !== "aic_Stamm") {
              me.data[key] = update[key];
              const column = me.columns.find((col: any) => col.name === key);
              if (column && column.meta.stichtag) {
                // der stichtag kommt als eigenes Data -daher muss ich den gesondert ersetzen!
                const stichtagSpalte = column.meta.stichtagSpalte;
                if (
                  stichtagSpalte &&
                  me.data[stichtagSpalte] !== update[stichtagSpalte]
                ) {
                  me.data[stichtagSpalte] = update[stichtagSpalte];
                  // Stichtag umgesetzt  - wie sag ich es der Komponente?
                }
              }
            }
          }
          me.$api.stichtag = null;
          this.showTimeField = false;
          me.stichtag = null;
          this.$nextTick(() => ((this.initalized = true), (this.showTimeField = true)));
          this.$forceUpdate();
        }
        if (this.backOnSave) {
          let updateData = null;
          if (res && res.data.data) {
            updateData = res.data.data;
          }
          const reloadStamm = this.header.refreshNS ?? false;
          this.$emit("back", updateData, reloadStamm);
        }
        this.$emit("save");
      },
      (ex: any) => {
        let errorMessage = "";
        if (ex.response) {
          errorMessage = ex.response.data.error;
          if (!errorMessage && ex.response?.status && ex.response.status === 400) {
            errorMessage = ex.response.data.info;
            // Alert Message nur wenn es einen echten Fehler gibt!
            this.$root.$emit("alert", {
              text: errorMessage,
              type: "error",
            });
          }
        } else {
          errorMessage = ex;
        }
      },
      this.aicStamm,
      getData,
      saveHaupt,
      this.mainStt,
      this.stichtag
    );
  }
  private setChange() {
    this.activateSave = true;
    this.$emit("changeValue");
  }
}
