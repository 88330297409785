// import { GoogleAuth, Authentication } from '@codetrix-studio/capacitor-google-auth';
import { SetLoginHelper } from './LoginHelper';

/*
let authentication: Authentication | undefined;
let email: string | undefined;
let imageUrl: string | undefined;
let name: string | undefined;
*/
export async function initializeGoogle(config: GoogleConfig) {
    // use hook after platform dom ready
    // GoogleAuth.initialize(config);
}

export async function doGoogleLogin() {
    /*
    try {
        const resp = await GoogleAuth.signIn();
        if (resp.authentication && resp.authentication.accessToken) {
            authentication = resp.authentication;
            email = resp.email;
            imageUrl = resp.imageUrl;
            name = resp.name;

            SetLoginHelper({
                login: doGoogleLogin,
                logout: logoutGoogle,
                getDisplayName: getAccountGoogleDisplayName,
                getUserName: getAccountGoogleName,
                refresh: checkRefreshTokenGoogle,
                getToken
            });

            return true;
        }
    } catch (error) {
        console.error(error);
    }
    */
    return false;
}

async function getAccountGoogleDisplayName() {
    return ""; // name + " (" + email + ")";
}
async function getAccountGoogleName() {
    return ""; // + email;
}


export async function logoutGoogle() {
    /*
    await GoogleAuth.signOut();
    authentication =
        email =
        imageUrl =
        name = undefined;
        */
}

async function checkRefreshTokenGoogle() {
    /*
    const resp = await GoogleAuth.refresh();
    authentication = resp;
    */
}

function getToken() {
    /*
    return new Promise<string>((resolve, reject) => {
        if (authentication) {
            resolve(authentication.accessToken);
        } else {
            reject("not login");
        }
    });
    */
}
