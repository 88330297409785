































































































































































import { showDialog, showSnackbar } from "@/UIHelper";
import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import {
  AufgabeStatusZuordnung,
  AufgabeStatusDruck,
  AufgabeStatusModell,
  AufgabeStatusFrame,
} from "../ChartTypesAndFunctions";
import { onShowSite, refreshFullPageContent } from "@/NavigationHelper";
import Constants from "@/Constants";

@Component
export default class ZuordnungDashboardMenu extends Vue {
  @Prop({ default: false }) public noSelect!: boolean;
  @Prop({ default: false }) public showButtons!: boolean;
  @Prop({ default: false }) public onlyVector!: boolean;
  @Prop({ default: false }) public persoenlich!: boolean;
  @Prop() public zuordnungen!: AufgabeStatusZuordnung[];
  @Prop() private printItems!: AufgabeStatusDruck[];
  @Prop() private modellItems!: AufgabeStatusModell[];
  @Prop() protected exportItems!: AbfrageItems[];
  @Prop() public aic!: number;
  @Prop({ default: "white" }) public color!: string;
  @Prop({ default: -30 }) public right!: number;
  @Prop({ default: 0 }) public top!: number;
  @Prop() public vector?: number[];
  @Prop() public vectorPlanung?: any[];
  @Prop() public vectorTable?: any[]; // aus Tabelle - kann Bew oder Stamnm sein
  @Prop({ default: false }) public ausPlanung!: boolean;
  @Prop({ default: false }) public ausPlanungVG!: boolean;
  @Prop({ default: false }) public dark!: boolean;
  @Prop() public zeitbereich!: APIZeitbereich | null;
  private drucke: AufgabeStatusDruck[] = [];
  private modelle: AufgabeStatusModell[] = [];
  private frame: AufgabeStatusFrame[] = [];
  private showExport: boolean = false;

  public mounted() {
    if (this.zuordnungen && this.zuordnungen.length > 0) {
      this.drucke = this.zuordnungen
        ?.filter((z) => z.gruppe === "Druck")
        .map((z) => z as AufgabeStatusDruck);
      this.drucke = this.drucke.sort(this.dynamicSort("bezeichnung"));
      this.modelle = this.zuordnungen
        ?.filter((z) => z.gruppe === "Modell")
        .map((z) => z as AufgabeStatusModell);
      this.frame = this.zuordnungen
        ?.filter((z) => z.gruppe === "Frame")
        .map((z) => z as AufgabeStatusFrame);
    } else {
      this.drucke = this.printItems;
      this.modelle = this.modellItems;
    }
    debugger;
    if (this.vectorTable && this.vectorTable.length > 0) {
      this.vector = [];
      const vecStamm: any[] = [];
      this.vectorTable.forEach((element) => {
        vecStamm.push(element.aic_Stamm ?? element.aic_Bew_pool);
      });
      this.vector = vecStamm;
      this.onlyVector = true;
    }
  }
  private dynamicSort(property: any) {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return (a: any, b: any) => {
      const result =
        a[property].localeCompare < b[property].localeCompare
          ? -1
          : a[property].localeCompare > b[property].localeCompare
          ? 1
          : 0;
      return result * sortOrder;
    };
  }
  private getStyle() {
    if (this.aic) {
      return {
        // height: 0,
        // position: "absolute",
        // "margin-top": this.top + "px",
        // "margin-right": this.right + "px"
      };
    }
    return {
      padding: 0,
      // height: "40px"
      // display: "inline-block",
      // width: "60%",
    };
  }

  private openDruckVorschau(item: AufgabeStatusDruck) {
    const me = this;
    const printItem = item;
    let aic = this.$api.user.aic;
    // aic kann von den Aufträgen übergeben werden (CChart)
    if (this.aic) {
      aic = this.aic;
    }
    // const aic = this.aic;
    const vecStamm = this.vector ?? []; // this.vecStamm;
    if (this.onlyVector && vecStamm.length === 0 && !this.aic) {
      showSnackbar({
        text: "Bitte wählen Sie zuerst einen Datensatz aus!",
        color: "warning",
      });
      // console.log("keine Satz markiert");
      return;
    }
    let fullscreen = false;
    console.log("isPhone: " + this.$isPhone());
    if (Constants.isMobile && this.$isPhone()) {
      fullscreen = true;
    }
    const pers = item.pers;
    showDialog({
      title: printItem.bezeichnung,
      site: "BaseDruck",
      width: 500,
      fullscreen,
      data: {
        zeitbereich: this.zeitbereich,
        printItem,
        aic,
        vecStamm,
        pers,
        noSelect: this.noSelect,
        vectorPlanung: this.vectorPlanung,
        ausPlanung: this.ausPlanung,
        ausPlanungVG: this.ausPlanungVG,
      },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      onClose: () => {
        return true;
      },
    });
  }
  private openFrame(item: any) {
    const me = this;
    const aic = this.aic;
    const site = item.alias ?? item.kennung;
    onShowSite({
      title: item.name,
      site,
      data: {
        zeitbereich: this.$api.zeitbereich,
        item,
        aic,
        showBack: true,
        toggleUebergabe: item.toggleSight, // hab noch keine Definition... fehlt noch
        name: item.name,
        titel: item.name,
        gotoToday: false,
      },

      callOnLeave: () => {
        setTimeout(() => refreshFullPageContent());
      },
    });
  }
  private onCalculated(args: any) {
    // nach der Berechnung alles neu laden!
    if (this.ausPlanungVG) {
      this.$emit("save");
    } else {
      setTimeout(() => refreshFullPageContent());
    }
  }
}
