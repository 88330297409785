








import { Vue, Component, Prop, Provide } from "vue-property-decorator";
@Component
export default class WorkflowMa extends Vue {
  @Prop() public aic: any;
  public onRefresh() {
    (this.$refs.timelines as any).onRefresh();
  }
}
