























import { Vue, Component, Prop, Provide } from "vue-property-decorator";
// import { Globals } from "@/Globals";
// import CalcButton from "@/components/CalcButton.vue";

@Component
export default class PersWorkflow extends Vue {
  @Prop({ default: null }) private zeitbereich!: APIZeitbereich | null;
  @Prop() private queryAIC: any;
  private success: any = undefined;
  private modellText: string = this.$begriffBezeichnung(
    "ZE_MA_Freigabe_Korrekturantrag"
  );
}
