



















import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { getKennungValueDisplay, getKennungValue } from "@/DataHelper";
@Component
export default class Projektstunden extends EditPage {
  constructor() {
    super();
    this.query = "ZE_Stunden_Projektsstunden";
  }
  // @Prop() protected aic: any;
  protected myHeight: number = window.innerHeight - 200;
  protected day: Date = this.siteData.zeitbereich.von;
  protected vonAnzeige: string = "";
  protected bisAnzeige: string = "";
  protected stdAnzeige: number = 0;
  protected std: any = "";

  public mounted() {
    this.mountedBase();
    if (this.siteData) {
      if (this.siteData.zeitbereich) {
        this.zeitbereich = this.siteData.zeitbereich.von;
        this.day = this.siteData.zeitbereich.von;
      }
    }
  }
  protected initialize() {
    this.stdAnzeige = getKennungValueDisplay(
      this.data,
      "verteilbareStd",
      this.columns
    );
    this.std = getKennungValue(this.data, "verteilbareStd", this.columns);
    this.vonAnzeige = getKennungValueDisplay(
      this.data,
      "FIRST_IN",
      this.columns
    );
    this.bisAnzeige = getKennungValueDisplay(
      this.data,
      "LAST_OUT",
      this.columns
    );
  }
}
