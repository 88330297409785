







































































































































































































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import CreateList from "@/components/CreateList";
import { Globals } from "@/Globals";
// import BaseList from "../baseList.vue";
import { getKZValueDisplay, getKZValue } from "@/DataHelper";
import { showDialog, showSnackbar } from "@/UIHelper";
import Constants from "@/Constants";
import BaseEditor from "../../../components/BaseEditor.vue";
import { calcFunctionNew } from "@/CalcHelperStatic";

@Component
export default class DialogReisen extends CreateList {
  constructor() {
    super();
    this.queryMutable = "Web_RK_Reisezeiten";
    this.showLoading = true;
  }
  private day!: Date | null;
  private timeLine: boolean = false;
  private myHeight: number = 600;
  private bezeichnung: string = "";
  private memo: string = "";
  private showPrint: boolean = false;
  private printItems!: PrintItems[];
  private modell: string = "Web_RK_ReiseBerechnen";
  private showDialog: boolean = false;
  public mounted() {
    if (this.siteData.zeitbereich) {
      this.zeitbereich = this.siteData.zeitbereich;
    }
    if (this.siteData.aic) {
      this.queryAIC = this.siteData.aic;
      const Stamm = this.$globalsStt(Globals.Stt, "", "RK_TRAVEL");
      this.$api.setSyncStamm(Stamm.aic, this.siteData.aic);
    }
    if (this.siteData.zeitbereich.von) {
      this.day = this.siteData.zeitbereich.von;
    }
    this.myHeight = window.innerHeight;
    this.mountedBase();
    this.addDruck();
  }
  private addDruck() {
    const stammTyp = this.$globalsStt(Globals.Stt, "", "RK_TRAVEL");
    if (stammTyp) {
      this.printItems = this.$globalsDruck("Begriff", false, stammTyp);
    }
  }

  protected dataLoaded(data: any) {
    this.dataLoadedBase(data);
  }

  protected refreshItems() {
    this.items = [];
    if (this.data.length > 0) {
      this.timeLine = true;
    }
    for (let i = 0; i < this.data.length; i++) {
      const row = this.data[i];
      this.bezeichnung = getKZValueDisplay(row, "subject", this.columns);
      this.showCalc = getKZValue(row, "showCalcHR", this.columns);
      const title = getKZValueDisplay(row, "abfahrt", this.columns);
      const title2 = getKZValueDisplay(row, "ankunft", this.columns);
      const subtitel = getKZValueDisplay(row, "zeit", this.columns);
      const subtitel1 = getKZValueDisplay(row, "ereignis", this.columns);
      const text = getKZValueDisplay(row, "region", this.columns);
      const differenz = getKZValueDisplay(row, "km", this.columns);
      const Abflatlong = getKZValueDisplay(row, "Abf_latlong", this.columns);
      const Anklatlong = getKZValueDisplay(row, "Ziel_latlong", this.columns);
      const color = getKZValueDisplay(row, "color", this.columns);
      const icon = getKZValueDisplay(row, "icon", this.columns);
      this.items.push({
        title,
        title2,
        subtitel,
        subtitel1,
        text,
        differenz,
        divider: true,
        inset: true,
        aic: row.aic_Bew_pool,
        menu: false,
        Abflatlong,
        Anklatlong,
        icon,
        color,
      });
    }
  }
  private onCalculated(args?: any) {
    this.$emit("back");
    // const x = this.$refs.Reisedaten as any;
    // this.$nextTick(() => (this.$refs.reisedaten as any).onRefresh());

    // x?.reloadData();
    // this.reloadData();
  }
  private openDruckVorschau(item: any) {
    const me = this;
    const printItem = item;
    let aic = this.queryAIC;
    if (this.aic) {
      aic = this.aic;
    }
    const noSelect = true;
    showDialog({
      title: printItem.bezeichnung,
      site: "BaseDruck",
      width: 500,
      data: {
        zeitbereich: this.siteData.zeitbereich,
        printItem,
        aic,
        pers: false,
        noSelect,
      },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      onClose: () => {
        this.showPrint = false;
        return true;
      },
    });
  }
  private openEditReise(aicReise: string) {
    if (!this.day) {
      return;
    }
    let showFullscreen = false;
    if (Constants.isMobile && this.$isPhone()) {
      showFullscreen = true;
    }
    console.log("fullscreen: " + showFullscreen);
    const title = this.day.toLocaleDateString() + ": " + this.bezeichnung;
    const me = this;
    showDialog({
      title,
      site: "SoftTermReisenEditReisen",
      width: 400,
      fullscreen: showFullscreen,
      data: {
        zeitbereich: this.siteData.zeitbereich,
        aic: aicReise,
        bewegungsdaten: true,
        showZeit: true,
      },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      onClose: (x: any) => {
        me.reloadData();
        return true;
      },
    });
  }
  private onReloadData(reloadData: any) {
    this.siteData.onReloadData = reloadData.dataSaved;
    const x = this.$refs.kostenBelege as any;
    x?.reloadData();
    const y = this.$refs.kostenKM as any;
    y?.reloadData();
  }
  private async onDeleteStt(modell: string) {
    const me = this;
    if (modell) {
      const deleteOK = false;
      showSnackbar({
        text: "Wollen Sie den Datensatz wirklich löschen?",
        duration: -1,
        color: "error",
        position: "top",
        isMessage: true,
        closedManual: true,
        onClose: (sb: any) => {
          if (!sb.closedManual) {
            return;
          } else {
            this.onCalc(modell);
          }
        },
      });
    }
  }
  private async onCalc(modell: string) {
    const me = this;
    const res = await calcFunctionNew(
      modell,
      this.queryAIC,
      false,
      me,
      this.zeitbereich,
      []
    );
    this.$emit("backDelete");
  }
  private openBuchungen() {
    const me = this;
    const mitarbeiterAIC = this.siteData.groupID;
    let daysForward = 3;
    let daysBack = 30;
    const vonDate = me.zeitbereich?.von;
    const bisDate = me.zeitbereich?.bis;
    const d = new Date();
    const heute = new Date(d.setHours(0, 0, 0, 0));
    const von = me.zeitbereich?.von.toISOString();
    const bis = me.zeitbereich?.bis.toISOString();
    if (vonDate && bisDate) {
      let diffDays = vonDate.getDate() - bisDate.getDate();
      if (diffDays === 0) {
        diffDays += 1;
      }
      daysForward = diffDays;
      const vonOhneUhrzeit = new Date(vonDate.setHours(0, 0, 0, 0));
      const diffToday = heute.getTime() - vonOhneUhrzeit.getTime();
      const differenceInDays = diffToday / (1000 * 3600 * 24);
      daysBack = differenceInDays + 1;
      // die Tage von Heute bis zum BEGINN der Reise berechnen um die Timeline richtig anzeihen zu können!
    }
    this.$parent.$emit("back");
    this.$emit("back");
    this.$root.$emit("showSite", {
      title: "Buchungen",
      showTitle: false,
      site: "RkAdminBuchungen",
      fullscreen: true,
      data: {
        zeitbereich: me.zeitbereich,
        goDate: von,
        aic: mitarbeiterAIC,
        name: this.siteData.groupName,
        daysForward,
        daysBack,
        admin: true,
      },
      titleColor: "white",
      onClose: () => {
        return true;
      },
    });
  }
}
