<template>
  <div>
    <label>{{description}}</label>
    <p>{{initValue}}</p>
  </div>
</template>
<script>
export default {
  props: ["initValue", "column", "description"],
  data() {
    return {
      data: this.initValue
    };
  }
};
</script>
<style scoped>
</style>
