































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import CalcButton from "@/components/CalcButton.vue";
import VCardDialog from "@/components/common/VCardDialog.vue";

@Component
export default class Salden extends VCardDialog {
  private zeitbereichUebergabe: any;
  private stichtag: Date | null = null;
  private resturlaub: string | undefined = "";
  private showTimeField: boolean = true;
  private timeInvalid: boolean = true;
  @Prop({ default: null }) public siteData: any;
  constructor() {
    super();
    const d = new Date();
    const datumHeute = new Date(d.setHours(0, 0, 0, 0));
    let zeitbereichUrlaub: any;
    if (this.siteData && this.siteData.zeitbereich) {
      zeitbereichUrlaub = {
        von: this.siteData.zeitbereich.von,
        bis: this.siteData.zeitbereich.bis,
        bereich: "Tag"
      };

      const bisZeitZone = zeitbereichUrlaub.bis;
      const bis = new Date(bisZeitZone.setHours(0, 0, 0, 0));
      /* liegt das BIS in der Zukunft - dann darf nur bis HEUTE gerechnet werden */
      if (datumHeute.valueOf() < bis.valueOf()) {
        zeitbereichUrlaub.bis = d;
      }
    } else {
      zeitbereichUrlaub = {
        von: this.$api.zeitbereich.von,
        bis: this.$api.zeitbereich.bis,
        bereich: "Tag"
      };

      const bisAPI = new Date(zeitbereichUrlaub.bis.setHours(0, 0, 0, 0));
      if (datumHeute.valueOf() < bisAPI.valueOf()) {
        const day = new Date(d).addDays(-1);
        zeitbereichUrlaub.bis = day;
      }
    }
    this.zeitbereichUebergabe = zeitbereichUrlaub;
  }
  private validateTimePicker() {
    let datepicker: any = this.$refs.datePicker;
    if (datepicker.length && datepicker.length > 0) {
      datepicker = datepicker[0];
    }
    this.timeInvalid = !datepicker.validate();
  }
  private timeChanged(d: any) {
    try {
      this.validateTimePicker();
      if (!d) {
        this.stichtag = null;
        return;
      }

      if (!this.timeInvalid && d) {
        if (typeof d === "string") {
          this.stichtag = new Date(d);
          return;
        }

        const time = d.value as Date;
        console.log("time changed: " + time.toISOStringWithTZ());
        this.stichtag = time;
      }
    } finally {
      this.$forceUpdate();
    }
  }
  private onCalculating(args: any) {
    this.$nextTick(() => {
      this.stichtag = null;
      this.showTimeField = false;
      this.timeInvalid = true;
      this.$nextTick(() => (this.showTimeField = true));
    });
  }
  private onCalculated(args: any) {
    this.resturlaub = args.ergebnis;
    const msgType = args.msgType;
    if (msgType) {
      this.resturlaub = "";
    }
    this.$nextTick(() => {
      this.stichtag = null;
      this.showTimeField = false;
      this.timeInvalid = true;
      this.$nextTick(() => (this.showTimeField = true));
    });
  }
}
