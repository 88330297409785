



















































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
// import EditPage from "@/components/EditPage";
import { Globals } from "@/Globals";
import { showDialog } from "@/UIHelper";
import { onShowSite, refreshFullPageContent } from "@/NavigationHelper";

@Component
export default class StammListe extends Vue {
  protected items: StammItem[] | null = null;
  public showClose: boolean = true;
  private programme: any[] = [];
  private stammListe: any[] = [];
  private initialized: boolean = false;
  private showDialog: boolean = false;
  private showLoading: boolean = false;
  private noData: boolean = true;
  private selectedProgramm: any = {
    aic: null,
    bezeichnung: this.$globalsBezeichnung(
      this.Globals.Begriff,
      "Web_Bitte_waehlen",
      "Bitte wählen"
    ),
  };
  public mounted() {
    this.programme = this.$api.user.prog;
    if (!this.selectedProgramm.aic) {
      this.selectedProgramm = this.programme[0];
    }
    this.dataLoaded();
  }
  private async dataLoaded() {
    this.showLoading = true;
    if (this.selectedProgramm?.aic) {
      const liste = await this.$api.getStammListe(this.selectedProgramm.aic);
      this.stammListe = liste.data.data;
      this.items = [];
      if (this.stammListe) {
        for (let j = 0; j < this.stammListe.length; j++) {
          const c = this.stammListe[j];
          const begr = this.$globalsStt(Globals.Begriff, c.aic, "", true);
          if (begr) {
            const frame = begr.kennung;
            this.items.push({
              bezeichnung: c.bezeichnung,
              subFrame: frame,
              anzahl: c.anzahl,
              icon: c.icon,
              farbe: c.farbe ?? "grey",
            });
          } else {
            this.items.push({
              bezeichnung: c.bezeichnung,
              subFrame: "",
              anzahl: c.anzahl,
              icon: c.icon,
              farbe: c.farbe ?? "grey",
            });
          }
        }
        this.initialized = true;
        this.showLoading = false;
        this.noData = false;
      } else {
        this.showLoading = false;
        this.noData = true;
      }
    }
    this.showLoading = false;
  }
  protected selectedProgrammChanged(args: any) {
    const prog: any = this.programme.find((m: any) => m.bezeichnung === args);
    this.selectedProgramm = prog;
    this.dataLoaded();
  }
  private editItem(item: any) {
    const navData = {
      query: item.subFrame,
      showClose: false,
      onReloadData: false,
    };
    showDialog({
      title: item.bezeichnung,
      showTitle: true,
      site: "EditStamm",
      data: navData,
      fullscreen: true,
      transition: "dialog-bottom-transition",
      onClose: () => {
        if (navData.onReloadData === true) {
          setTimeout(() => refreshFullPageContent());
        }
        return true;
      },
    });
  }
}
export declare interface StammItem {
  bezeichnung: string;
  subFrame: string;
  anzahl: number;
  icon?: string;
  farbe?: string;
}
