





































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditDateTimePicker from "@/editor/EditDateTimePicker.vue";
import TimePicker, { TimeEntry } from "./TimePicker.vue";
@Component
export default class EditTimePicker extends EditDateTimePicker {
  public selectedText: string = "12:00";
  private date: Date = new Date(
    new Date().toISOStringWithTZ().substring(0, 10) + "Z"
  ); // today

  public mounted() {
    this.inputText = "";
    this.mountedDateTimePicker();
    this.date = new Date(new Date().toISOStringWithTZ().substring(0, 10)); // today

    if (this.currentDateValue) {
      // currentDate ;)
      if (!this.currentDateValue.toISOStringWithTZ) {
        this.currentDateValue = new Date(this.currentDateValue);
      }
      this.date = new Date(
        this.currentDateValue.toISOStringWithTZ().substring(0, 10)
      );
    }

    this.format = "HH:mm";
  }
  public focusMe() {
    (this.$refs.masked as any).focusMe();
  }

  public clearAndFocus() {
    this.showField = false;
    this.inputText = "--:--";
    this.$nextTick(() => {
      this.showField = true;
      this.$nextTick(() => {
        this.focusMe();
      });
    });
  }

  private clickAppendTime() {
    this.selectedText = this.inputText;
    this.clickAppend();
  }

  private changedTime(x: any) {
    let val = null;
    let isEmpty = true;
    let isNotFull = true;
    if (x) {
      isEmpty = x.replace(/\s:-/g, "").length === 0;
      isNotFull = x.indexOf("-") >= 0;
      val = this.getDateFromInput(x, this.format);
    }
    if (isNotFull || isEmpty) {
      if (this.required) {
        this.errorText = this.requiredMessage;
      }
      this.valueChanged(null);
      return;
    }
    if (val) {
      if (this.date) {
        const hour = val.getHours();
        const min = val.getMinutes();
        val = new Date(this.date.valueOf());
        val.setHours(hour);
        val.setMinutes(min);
        val.setSeconds(0);
        val.setMilliseconds(0);
      }
      console.log("EditTimePicker: " + val.toISOStringWithTZ());

      val = this.checkTimeDate(val);

      this.currentDateValue = val;
    }
    if (val) {
      this.showField = false;
      // this.inputText = val.toISOStringWithTZ();
      this.valueChanged(val.toISOStringWithTZ());
      this.showField = true;
    } else {
      this.valueChanged(null);
    }
  }

  private checkTimeDate(val: Date) {
    if (!val) {
      return null;
    }

    if (this.timeDate) {
      console.log("setTimedate: " + val.toISOStringWithTZ());
      let x: any = this.timeDate;
      if (x.toISOStringWithTZ) {
        x = x.toISOStringWithTZ().substring(0, 10);
      }
      x = new Date(x);
      x.setHours(val.getHours());
      x.setMinutes(val.getMinutes());
      val = x; // val muss dann auf das neu berechnet Datum gesetzt werden!
      console.log("fixed setTimedate: " + val.toISOStringWithTZ());
    }
    return val;
  }

  private setTime(item: TimeEntry) {
    let date: Date | null = this.date;
    date = date.addMinutes(item.delta);
    this.currentDateValue = date;
    this.showField = false;
    this.inputText = this.valueToFormatStr(date, this.format);
    const me = this;
    date = this.checkTimeDate(date);
    setTimeout(() => {
      this.errorText = ""; // muss valide sein - is ja ausgesucht ;)
      me.showField = true;
      if (date) {
        console.log("time changed: " + date.toISOStringWithTZ());
        me.valueChanged(date.toISOStringWithTZ());
      } else {
        console.log("time changed: null");
        me.valueChanged(null);
      }
    }, 50);

    this.showPicker = false;
  }
}
