


























import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";

@Component
export default class StempelEdit extends EditPage {
  constructor() {
    super();
    this.query = "Web_TimeLineStempelungen";
    this.bewegungsdaten = true;
    this.showLoading = true;
  }
  private showZeitzone: boolean = false;
  public mounted() {
    const zeitzone = this.checkBegriff("PZE_Web_Zeitzone_Nacherfassen");
    if (zeitzone) {
      this.showZeitzone = true;
    }
    this.mountedBase();
  }
}
