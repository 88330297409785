


































import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";

export interface TimeEntry {
  text: string;
  hour: number;
  min: number;
  delta: number;
}
@Component
export default class TimePicker extends Vue {
  @Prop({ default: false }) public showPicker!: boolean;
  @Prop({ default: "12:00" }) public selectedText!: string;
  @Prop({ default: true }) public enabled!: boolean;
  @Prop({ default: 0 }) public menuLeft!: number;
  @Prop({ default: 0 }) public menuTop!: number;
  @Prop({ default: "HH:mm" }) public format!: string;
  @Prop({ default: new Date() }) public date!: Date;
  @Prop({ default: 2 }) public hourparts!: number; // da einstellen ob 2 oder 4 teile (30 min oder 15 min)

  private showPickerInternal?: boolean;
  private enabledInternal = false;
  public selectedIndex?: number;

  public times: TimeEntry[] = [];

  private zeroPad(num: number, places: number) {
    return String(num).padStart(places, "0");
  }

  @Watch("showPicker")
  private showPickerUpdated() {
    this.showPickerInternal = this.showPicker;
  }

  private mounted() {
    this.showPickerInternal = this.showPicker;
    this.enabledInternal = this.enabled;

    for (let hour = 0; hour < 24; hour++) {
      for (let min = 0; min < 60; min += 60 / this.hourparts) {
        this.times.push({
          text: this.zeroPad(hour, 2) + ":" + this.zeroPad(min, 2),
          hour,
          min,
          delta: hour * 60 + min,
        });
      }
    }
    if (this.selectedText) {
      this.selectItemByText();
    }
  }

  @Watch("selectedText")
  public selectItemByText() {
    let ix = this.times.findIndex((t) => t.text === this.selectedText) ?? -1; // genau suchen
    if (ix < 0) {
      // wenn icht gefunden wenigstens zur stunde hinscrollen
      ix =
        this.times.findIndex(
          (t) => t.text.substring(0, 2) === this.selectedText.substring(0, 2)
        ) ?? -1;
    }

    this.selectedIndex = ix;
    this.setTime(this.times[ix]);
  }

  private setTime(item: TimeEntry) {
    this.$emit("change", item);
  }
}
