


















































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";

@Component
export default class Ueberleitung extends EditPage {
  protected myHeight: number = window.innerHeight - 200;

  protected editZeitbereich = this.siteData.zeitbereich;
  private activeTabIndex: number = 0;
  constructor() {
    super();
    this.query = "Web_UebersichtRK_Ueberleitung";
  }

  public mounted() {
    this.backOnSave = false;
    this.mountedBase();
  }
}
