import AppSnackbars from "@/components/AppSnackBars.vue";
import { Keyboard, KeyboardInfo, KeyboardResize } from "@capacitor/keyboard";
import { Capacitor } from "@capacitor/core";

let app: Vue;

let dialogs: DialogParameter[];
let AppSnackbarsInstance: AppSnackbars;
let pushSnackbar: (data: SnackbarData) => void;
export function setAppSnackbarInstance(snb: AppSnackbars, pn: (data: SnackbarData) => void) {
  AppSnackbarsInstance = snb;
  pushSnackbar = pn;
}

interface KeyboardEventCallbacks<T> {
  element: T;
  keyboardWillShow?: (info: KeyboardInfo) => void;
  keyboardDidShow?: (info: KeyboardInfo) => void;
  keyboardWillHide?: () => void;
  keyboardDidHide?: () => void;
}

let KeyboardEventSubscribedElements: Array<KeyboardEventCallbacks<any>> = [];

export function registerForKeyBoardEvent<T>(subscriber: KeyboardEventCallbacks<T>) {
  KeyboardEventSubscribedElements.push(subscriber);
}
export function unregisterForKeyBoardEvent<T>(element: T) {
  KeyboardEventSubscribedElements = KeyboardEventSubscribedElements.filter(e => e.element !== element);
}

let keyboardVisible: boolean = false;
export function initializeUIHelper(newApp: Vue, dlgs: DialogParameter[]) {
  if (!newApp) { throw new Error("app nicht initialisiert"); }
  app = newApp;
  dialogs = dlgs;
  console.info("UIHelper" + Capacitor.getPlatform());
  if (Capacitor.getPlatform() !== "web") {
    // wenn du die Resizings weg haben magst, dann schalte es hier ein/aus !
    // keyboard plugin doku:
    // https://capacitorjs.com/docs/apis/keyboard#setresizemode
    Keyboard.setResizeMode({ mode: KeyboardResize.Native });

    Keyboard.addListener("keyboardWillShow", (info) => {
      console.log("keyboard will show");
      KeyboardEventSubscribedElements.forEach(e => { if (e.keyboardWillShow) { e.keyboardWillShow(info); } });
    });
    Keyboard.addListener("keyboardDidShow", (info) => {
      console.log("keyboard showed");
      keyboardVisible = true;
      KeyboardEventSubscribedElements.forEach(
        e => invokeWithCatch(() => e.keyboardDidShow ? e.keyboardDidShow(info) : 0));
    });

    Keyboard.addListener("keyboardWillHide", () => {
      KeyboardEventSubscribedElements.forEach(e => invokeWithCatch(e.keyboardWillHide));
    });

    Keyboard.addListener("keyboardDidHide", () => {
      keyboardVisible = false;
      KeyboardEventSubscribedElements.forEach(e => invokeWithCatch(e.keyboardDidHide));
    });
  }
}

function invokeWithCatch(action: (() => any) | undefined) {
  try {
    if (action) { action(); }
  } catch (error) {
    console.log(error);
  }
}

export function scrollIntoView(event: Event) {
  const target = event.currentTarget as HTMLElement;
  setTimeout(() => {
    try {
      // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView?retiredLocale=de
      if (keyboardVisible) { target.scrollIntoView(true); }
    } catch (error) {
      console.error(error);
    }
  }, 800);
}

export function closeDialog(dlg: DialogParameter, dialogResult?: boolean) {
  if (dlg == null) {
    return;
  }
  dlg.dialogResult = dialogResult;
  if (dlg.site && dlg.site === "ModellDialog") {
    if (dlg.data && dlg.data.getReturnData) {
      dlg.returnedData = dlg.data.getReturnData();
    }
  }
  if (dlg.onClose && typeof dlg.onClose === "function") {
    const result = dlg.onClose(dlg);
    if (result === false) {
      return;
    }

  }

  dialogs.pop();
}


export function showDialog(value: DialogParameter) {
  if (!value.titleColor) {
    value.titleColor = "primary";
  }

  if (value.showTitle === undefined) {
    value.showTitle = true;
  }
  // am Handy immer auf persistent true stellen! damit combox auf Android klappt!
  if (Capacitor.getPlatform() !== "web" && value.persistent === undefined) {
    value.persistent = value.fullscreen;
  }
  value.closeDialog = () => {
    closeDialog(value);
  };

  dialogs.push(value);
}

export function showSnackbar(data: string | SnackbarData) {
  if (!pushSnackbar) { return; }
  if (typeof data === "string") {
    pushSnackbar({ text: data });
  } else {
    pushSnackbar(data);
  }
}

export interface DialogParameter {
  title: string;
  titleClass?: string;
  titleColor?: string;
  width?: number;
  height?: number;
  dialogResult?: boolean; /* ok/abbrechen */
  returnedData?: any; /* ok/abbrechen */
  closeDialog?: () => void;
  onClose?: (param: DialogParameter) => boolean;
  site?: string;
  data?: any;
  text?: string;
  okButton?: boolean;
  noClose?: boolean;
  fullscreen?: boolean;
  persistent?: boolean;
  showTitle?: boolean;
  transition?: string;
  reload?: boolean;
}
