






















import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getKZValue } from "@/DataHelper";
import {
  AufgabeStatus,
  AufgabeStatusZuordnungAufgabe,
} from "../ChartTypesAndFunctions";
import AUApi from "@/api";

export declare interface DialogPopupSiteParams {
  zusatzInfo: AufgabeStatusZuordnungAufgabe;
  aufgabenDefinition: QueryResponse;
  aic: number;
  zeitbereich: APIZeitbereich;
  setTitle: (title: string) => void;
  hideTitle: (hide: boolean) => void;
  page: boolean;
}

@Component
export default class DetailsPopup extends Vue {
  @Prop() public siteData!: DialogPopupSiteParams;
  private zusatzInfo?: AufgabeStatusZuordnungAufgabe;
  private aic: number = 0;
  private initialized = false;
  private aufgabenDefinition?: QueryResponse;
  private title: string = "";

  private aufgabe!: ALLAufgabe;
  private zeitbereich?: APIZeitbereich;

  private get showTitle() {
    return this.siteData.page;
  }

  public async mounted() {
    try {
      if (this.siteData) {
        if (this.siteData.zusatzInfo) {
          this.zusatzInfo = this.siteData.zusatzInfo;
          this.zeitbereich = this.siteData.zeitbereich;
        }
        if (this.siteData.aic) {
          this.aic = this.siteData.aic;
        }
        this.aufgabenDefinition = this.siteData.aufgabenDefinition;
      }
      if (!this.zusatzInfo?.aic) {
        console.error("error - no zusatzInfo");
        return;
      } else {
        this.aufgabe = AUApi.Current.Begriff.find(
          (b) => b.gruppe === "Aufgabe" && b.aic === this.zusatzInfo!.aic
        ) as unknown as ALLAufgabe;
        // const response = await this.$api.getStatusAufgaben(this.zusatzInfo.aic);

        // if (response.status > 210) {
        if (!this.aufgabe) {
          alert("keine Daten gefunden!");
        }
        // this.aufgabenStatus = response.data as AufgabeStatus[];

        // if (this.aufgabenStatus.length === 1) {
        //   if (this.aufgabenStatus[0].darstellungKennung === "Liste") {
        //     this.siteData.hideTitle(true);
        //   }
        // }
      }
    } finally {
      this.initialized = true;
    }
  }

  private closeDialog() {
    this.$emit("back");
  }
  private hideDialogTitel() {
    this.siteData.hideTitle(true);
  }
  private setDialogTitle(title: string) {
    if (this.siteData.page) {
      this.title = title;
    } else {
      this.siteData.setTitle(title);
    }
  }
}
