let timer: number;
let lastEvent: number = 0;
let autoAusMS = 0;
const checkInterval = 60 * 1000;
let callbackOnAutoAus: () => void | undefined;

export function initAutoAus(autoAusInMilliSeconds: number | undefined, onAutoAus: () => void) {
    autoAusMS = (autoAusInMilliSeconds ?? 0);
    if (!autoAusMS) {
        window.removeEventListener("mousemove", onUIIteraction);
        // no autologout
        if (timer) {
            clearInterval(timer);
            timer = 0;
        }
        return;
    }

    window.addEventListener("mousemove", onUIIteraction);
    callbackOnAutoAus = onAutoAus;
    timer = setInterval(checkAutoAus, checkInterval);
}

export function checkAutoAus() {
    // nothing to do ?
    if (!lastEvent || !callbackOnAutoAus) { return; }

    if ((new Date().valueOf() - (lastEvent)) > autoAusMS) {
        callbackOnAutoAus();
        clearInterval(timer);
    }
}

export function isAutoAusActive() {
    if (timer && autoAusMS > 0) { return true; }
    return false;
}

export function TestUIInteraction() {
    onUIIteraction();
}

function onUIIteraction() {
    lastEvent = new Date().valueOf();
}
