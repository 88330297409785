































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import PasswortValidator from "@/PasswortValidator";
import { encryptForAD } from "@/ADCrypter";
import CryptoJS from "crypto-js";
import { showSnackbar } from "@/UIHelper";
import { Globals } from "@/Globals";
import EditPage from "@/components/EditPage";

@Component
export default class Password extends EditPage {
  private show: any = false;
  private show1: any = false;
  private show2: any = false;
  private oldPassword: string = "";
  private newPassword: string = "";
  private rePassword: string = "";
  private hash?: string;
  private hashOld?: string;
  private DB: any = this.$api.dB;
  private Kennung: string = this.$api.user.kennung;
  private minLength: number = 0;
  private minDigit: number = 0;
  private minSpecialCharacters: number = 0;
  private minUpperCase: number = 0;
  private minLowerCase: number = 0;
  private hintText: string = "";
  private userAic: any;
  private userArt: any;
  private userDatum: any;
  private errorText: string = "";
  private title: string =
    this.siteData?.title ??
    this.$globalsBezeichnung(this.Globals.Begriff, "Web_PWaendern", "Passwort ändern");
  @Prop() private newTitle!: string;

  public mounted() {
    if (this.newTitle) {
      this.title = this.newTitle;
    }
    this.$api.getUserData(this.DB, this.Kennung, "").then((response: any) => {
      if (response.data) {
        this.minLength = response.data.pwML;
        this.minDigit = response.data.pwMZ;
        this.minSpecialCharacters = response.data.pwMS;
        this.minUpperCase = response.data.pwMG; // Anzahl Großbuchstaben
        this.minLowerCase = response.data.pwMK;
        this.userAic = response.data.aic;
        this.userArt = response.data.art;
        this.userDatum = response.data.datum;
        if (this.minLength > 0) {
          this.hintText =
            this.$globalsBezeichnung(this.Globals.Begriff, "Zeichen", "Mindestlänge") +
            ": " +
            this.minLength;
        }
        if (this.minDigit > 0) {
          this.hintText =
            this.hintText +
            " ," +
            this.$globalsBezeichnung(this.Globals.Begriff, "Web_Ziffern", "Ziffern") +
            ": " +
            this.minDigit;
        }
        if (this.minSpecialCharacters > 0) {
          this.hintText =
            this.hintText +
            "," +
            this.$globalsBezeichnung(
              this.Globals.Begriff,
              "Web_Sonderzeichen",
              "Sonderzeichen"
            ) +
            ": " +
            this.minSpecialCharacters;
        }
        if (this.minUpperCase > 0) {
          this.hintText =
            this.hintText +
            "," +
            this.$globalsBezeichnung(
              this.Globals.Begriff,
              "Web_Grossbuchstaben",
              "Großbuchstaben"
            ) +
            ": " +
            +this.minUpperCase;
        }
        if (this.minLowerCase > 0) {
          this.hintText =
            this.hintText +
            "," +
            this.$globalsBezeichnung(
              this.Globals.Begriff,
              "Web_Kleinbuchstaben",
              "Kleinbuchstaben"
            ) +
            ": " +
            +this.minLowerCase;
        }
      }
    });
  }
  private save() {
    let isValid: boolean = true;
    if (this.oldPassword === this.newPassword) {
      this.errorText = this.$globalsBezeichnung(
        this.Globals.Begriff,
        "Passwort_gleich",
        "Passwort ident - bitte ein neues erfassen!"
      );
      showSnackbar({
        text: this.errorText,
        duration: 4000,
        color: "error",
      });
      return;
    }

    if (this.newPassword === this.rePassword) {
      const res = PasswortValidator.validate(
        this.newPassword,
        this.minLength,
        this.minDigit,
        this.minSpecialCharacters,
        this.minUpperCase,
        this.minLowerCase
      );

      isValid = res.isValid;
      this.errorText = res.message;

      if (!isValid) {
        showSnackbar({
          text: this.errorText,
          duration: 4000,
          color: "error",
        });
        return;
      } else if (this.userArt === 1) {
        const seed = this.newPassword + this.userAic;
        this.hash = CryptoJS.MD5(seed).toString();
        const seedOld = this.oldPassword + this.userAic;
        this.hashOld = CryptoJS.MD5(seedOld).toString();
      } else if (
        this.userArt === 2 ||
        this.userArt === 6 ||
        this.userArt === 7 ||
        this.userArt === 8
      ) {
        const seedConcat = this.userAic + "$" + this.newPassword + "#" + this.userDatum;
        console.log(this.userAic + " " + this.newPassword + " " + this.userDatum);

        this.hash = CryptoJS.MD5(seedConcat).toString();

        const seedOldConcat =
          this.userAic + "$" + this.oldPassword + "#" + this.userDatum;
        this.hashOld = CryptoJS.MD5(seedOldConcat).toString();
      }
      if (!this.hash || !this.hashOld) {
        console.warn("Hash LEER");
        return;
      }
      this.$api.changePW(this.hash, this.hashOld).then((response: any) => {
        if (response) {
          const msgType = response.data.msgType;
          let info = response.data.info;
          const memo = response.data.memo;
          const title = response.data.titel;
          if (memo) {
            info = memo;
          }
          if (info) {
            if (msgType === "Error") {
              showSnackbar({
                text: info,
                color: "error",
              });
            } else if (msgType === "Info") {
              showSnackbar({
                text: info,
                color: "info",
              });
            } else if (msgType === "Warnung") {
              showSnackbar({
                text: info,
                color: "warning",
              });
            }
            return;
          }
        }
      });
      showSnackbar({
        text: this.errorText,
        duration: 4000,
        color: "success",
      });
      this.refresh();
      this.$emit("savePW");
      return;
    } else {
      this.errorText = this.$globalsBezeichnung(
        this.Globals.Begriff,
        "PW_StimmtNicht",
        "Passwort stimmt nicht überein!"
      );
    }
    showSnackbar({
      text: this.errorText,
      duration: 4000,
      color: "error",
    });
    return;
  }

  private refresh() {
    this.oldPassword = "";
    this.newPassword = "";
    this.rePassword = "";
  }
}
