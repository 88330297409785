import AUApi from "./api";
import { Globals } from "./Globals";

// -- Globalisation  ---------------------------------------
// see https://ej2.syncfusion.com/vue/documentation/daterangepicker/globalization/?no-cache=1

// declare var loadCldr: any;
import { loadCldr, L10n, Internationalization } from "@syncfusion/ej2-base";
// import { setCulture } from "@syncfusion/ej2-base";
// import { setCurrencyCode } from "@syncfusion/ej2-base";
// new ES/TS Typescript import for json data (see: https://hackernoon.com/import-json-into-typescript-8d465beded79)
import localNumbers from "cldr-data//main/de/numbers.json";
import currencies from "cldr-data/main/de/currencies.json";
import localCalender from "cldr-data/main/de/ca-gregorian.json";
import localTimeZoneNames from "cldr-data/main/de/timeZoneNames.json";
// load the locale object to set the localized placeholder value
// sollte hier Datei Local_AT verwenden
import { localSettings } from "./localisation/localisationSettings";
// import numberingSystems from "cldr-data/main/de/numberingSystems.json';

export function initLocalisation() {
  // setCurrencyCode("EUR");
  // setCulture("de");
  loadCldr(localNumbers, localCalender, localTimeZoneNames, currencies);
  L10n.load(localSettings);
}



export default class LocalisationFunctions {
  public api: AUApi | undefined;

  private findIt(kennung: string, list: any[]) {
    if (!kennung) {
      return kennung;
    }
    kennung = kennung.toLowerCase();
    if (!this.api || !list) {
      return null;
    }
    const item = list.find(
      (f: any) =>
        (f.kennung && f.kennung.toLowerCase() === kennung) ||
        (f.Kennung && f.Kennung.toLowerCase() === kennung)
    );
    return item;
  }
  private findId(aic: number, list: any[]) {
    if (!this.api || !list) {
      return null;
    }
    const item = list.find((f: any) => f.aic === aic);
    return item;
  }
  private findStt(stt: number, list: any[]) {
    if (!this.api || !list) {
      return null;
    }
    const item = list.find((f: any) => (f.stt === stt || f.aic === stt));
    return item;
  }
  private findSttKennung(stt: string, list: any[]) {
    if (!this.api || !list) {
      return null;
    }
    const item = list.find((f: any) => f.kennung === stt);
    return item;
  }
  private findAmpel(ampel: number, list: any[]) {
    if (!this.api || !list) {
      return null;
    }
    const item = list.find((f: any) => f.system === ampel);
    return item;
  }
  private findGruppe(gruppe: string, list: any[]) {
    if (!this.api || !list) {
      return null;
    }
    const item: any = [];
    if (list.length > 0) {
      const li = list;
      for (let j = 0; j < li.length; j++) {
        const c = li[j];
        if (c.gruppe === gruppe) {
          item.push(c);
        }
      }
      return item;
    }
  }
  // private findSttAic(stt: string, list: any[]) {
  //   if (!this.api || !list) {
  //     return null;
  //   }
  //   const item = list.find((f: any) => f.system === stt);
  //   return item;
  // }

  // 4. add an instance method
  // sucht in der Begriffstabelle nach der Kennung und gibt Bezeichnung zurück
  // wenn eine vorhanden ist
  public begriffBezeichnung(kennung: string) {
    if (this.api) {
      const x: any = this.findIt(kennung, this.api.Begriff);
      if (x) {
        return x.bezeichnung;
      }
    }
    console.warn(
      "$begriffBezeichnung kann keinen Begriff zum kennung '" +
      kennung +
      "' finden (oder Begriffe sind nicht initialisiert) !"
    );
    return kennung;
  }
  public begriffCode(kennung: string) {
    if (this.api) {
      const x: any = this.findIt(kennung, this.api.Begriff);
      if (x) {
        return x.bezeichnung;
      }
    }
    console.warn(
      "$begriffCode kann keinen Begriff zum aic '" +
      kennung +
      "' finden (oder Begriffe sind nicht initialisiert) !"
    );
    return kennung;
  }

  public begriffTooltip(kennung: string) {
    if (this.api) {
      const x: any = this.findIt(kennung, this.api.Begriff);
      if (x) {
        return x.tooltip;
      }
    }
    console.warn(
      "$begriffTooltip kann keinen Begriff zum aic '" +
      kennung +
      "' finden (oder Begriffe sind nicht initialisiert) !"
    );
    return kennung;
  }

  public begriffAic(aic: number) {
    if (this.api) {
      const x: any = this.findId(aic, this.api.Begriff);
      if (x) {
        return x;
      }
    }
    console.warn(
      "$begriffAic kann keinen Begriff zum aic '" +
      aic +
      "' finden (oder Begriffe sind nicht initialisiert) !"
    );
    return aic;
  }

  public globalsKennung(type: Globals, kennung: string, warn: boolean = true) {
    if (this.api) {
      const list = this.getListfromType(type);
      const x: any = this.findIt(kennung, list);
      if (x) {
        return x;
      }
    }
    if (warn) {
      let typeStr = "???";
      switch (type) {
        case 0:
          typeStr = "Begriff";
          break;
        case 1:
          typeStr = "Einheit";
          break;
        case 2:
          typeStr = "Code";
          break;
        case 3:
          typeStr = "Mandanten";
          break;
        case 4:
          typeStr = "Stt";
          break;
        case 6:
          typeStr = "Ampel";
          break;
      }
      console.warn(
        "$globalsKennung kann keinen '" +
        typeStr +
        "' zur kennung '" +
        kennung +
        "' finden (oder Begriffe sind nicht initialisiert) !"
      );
    }
    return null;
  }

  public globalsAic(type: Globals, aic: number) {
    if (aic === undefined) {
      console.warn(
        "aic wurde an globalsAic nicht übergeben"
      );
      return;
    }
    if (this.api) {
      const list = this.getListfromType(type);
      const x: any = this.findId(aic, list);
      if (x) {
        return x;
      }
    }
    console.warn(
      "$begriffAic kann keinen '" +
      type +
      "' zum aic '" +
      aic +
      "' finden (oder Begriffe sind nicht initialisiert) !"
    );
    return aic;
  }
  // Systemabfrage für Stammtyp finden
  public globalsStt(type: Globals, stt?: number, kennung?: string, noFilter?: boolean) {
    if (this.api) {
      let list = this.getListfromType(type);
      if (noFilter) {
        list = list.filter(x => !x.filter);
      }
      if (stt) {
        list = list.filter((y: any) => (y.system === true));
        const x: any = this.findStt(stt, list);
        if (x) {
          return x;
        }
      } else if (kennung) {
        const x: any = this.findSttKennung(kennung, list);
        if (x) {
          return x;
        }
      }
    }
    console.warn(
      "$begriffStt kann keinen '" +
      type +
      "' zum Stammtyp '" +
      stt +
      "' finden (oder Begriffe sind nicht initialisiert) !"
    );
    return null;
  }
  public globalsFrame(type: Globals, pers: boolean, stammtyp?: any, name?: string,
                      standard?: boolean, kennung?: string) {
    if (this.api) {
      const list = this.api.Begriff;
      if (list.length > 0) {
        let x: any = this.findGruppe("Frame", list);
        if (pers) {
          const item: any = [];
          for (let j = 0; j < x.length; j++) {
            const c = x[j];
            if (c.pers === true) {
              if (stammtyp && c.stt === stammtyp.aic) {
                item.push(c);
              } else if (!stammtyp) {
                item.push(c);
              }
            }
          }
          x = item;
        } else if (name) {
          const item: any = [];
          for (let j = 0; j < x.length; j++) {
            const c = x[j];
            if (c.bezeichnung === name) {
              item.push(c);
            }
          }
          x = item;
        } else if (kennung) {
          const item: any = [];
          for (let j = 0; j < x.length; j++) {
            const c = x[j];
            if (c.kennung === kennung || c.alias === kennung) {
              item.push(c);
            }
          }
          x = item;
        } else if (stammtyp) {
          const item: any = [];
          for (let j = 0; j < x.length; j++) {
            // const c = x[j];
            if (x[j].stt && x[j].stt === stammtyp.aic) {
              if (standard) {
                if (x[j].standard) {
                  item.push(x[j]);
                }
              } else {
                item.push(x[j]);
              }
            } else if (!stammtyp) {
              item.push(x[j]);
            }
          }
          x = item;
        }
        return x;
      }
    }
    console.warn("$begriffFrame kann keinen '" + type + "' zum Frame finden'");
    return null;
  }
  public globalsDruck(type: Globals, pers: boolean, stammtyp: any, aic?: boolean, aicDruck?: number,
                      kennungDruck?: string, prog?: number) {
    if (this.api) {
      const list = this.api.Begriff;
      if (list.length > 0) {
        let x: any = this.findGruppe("Druck", list);
        if (kennungDruck) {
          const item: any = [];
          for (let j = 0; j < x.length; j++) {
            const c = x[j];
            if (c.kennung === kennungDruck) {
              item.push(c);
            }
          }
          x = item;
        } else if (prog) {
          const item: any = [];
          for (let j = 0; j < x.length; j++) {
            const c = x[j];
            if (c.prog === prog) {
              item.push(c);
            }
          }
          x = item;
        } else if (aicDruck) {
          const item: any = [];
          for (let j = 0; j < x.length; j++) {
            const c = x[j];
            if (c.aic === aicDruck) {
              item.push(c);
            }
          }
          x = item;
        } else if (pers) {
          const item: any = [];
          for (let j = 0; j < x.length; j++) {
            const c = x[j];
            if (c.pers === true) {
              if (stammtyp && c.stt === stammtyp.aic) {
                item.push(c);
              } else if (!stammtyp) {
                item.push(c);
              }
            }
          }
          x = item;
        } else {
          const item: any = [];
          for (let j = 0; j < x.length; j++) {
            const c = x[j];
            if (aic) {
              if (stammtyp && c.stt === stammtyp) {
                item.push(c);
              }

            } else if (!aic) {
              if (stammtyp && c.stt === stammtyp.aic) {
                item.push(c);
              }
            }
          }
          x = item;
        }
        return x;
      }
    }
    console.warn("$begriffDruck kann keinen '" + type + "' zum Druck finden'");
    return null;
  }
  public globalsLayout(type: Globals) {
    if (this.api) {
      const list = this.api.Begriff;
      if (list.length > 0) {
        const x: any = this.findGruppe("Layout", list);
        return x;
      }
    }
    console.warn("$begriffLayout kann keinen '" + type + "' zum Layout finden'");
    return null;
  }
  public globalsAmpel(type: Globals, aic: number) {
    if (this.api) {
      const list = this.api.Ampel;
      const x: any = this.findId(aic, list);
      // in x steht bezeichnung, nr und aic
      return x;
    }
    console.warn("$begriffAmpel kann keinen '" + type + "' zur Ampel finden'");
    return null;
  }
  public globalsAufgaben(pers: boolean) {
    if (this.api) {
      const list = this.api.Begriff;
      if (list.length > 0) {
        let x: any = this.findGruppe("Aufgabe", list);
        if (pers) {
          const item: any = [];
          for (let j = 0; j < x.length; j++) {
            const c = x[j];
            if (c.pers === true) {
              item.push(c);
            }
          }
          x = item;
        } else {
          const item: any = [];
          for (let j = 0; j < x.length; j++) {
            const c = x[j];
            if (c.pers !== true) {
              item.push(c);
            }
          }
          x = item;
        }
        return x;
      }

    }
    console.warn("$begriffAufgabe keine Aufgabe finden'");
    return null;
  }
  private getListfromType(type: Globals) {
    if (!this.api) {
      throw new Error("API not initialized!!!");
    }
    let list: AllDescribedEntityWithKennung[] = this.api.Begriff;
    if (type === Globals.Einheit) {
      list = this.api.Einheiten;
      // } else if (type === Globals.Eigenschaft) {
      //   list = this.api.Eigenschaft;
    } else if (type === Globals.Code) {
      list = this.api.Code;
    } else if (type === Globals.Mandanten) {
      list = this.api.Mandanten;
    } else if (type === Globals.Stt) {
      list = this.api.Stt;
      // Tabellen vereinheitlicht...
      // } else if (type === Globals.Security) {
      //   list = this.api.Security;
      // } else if (type === Globals.SysAbfrage) {
      //   list = this.api.SysAbfrage;
    }
    return list;
  }
}
