import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';
import { DateRangePicker } from '@syncfusion/ej2-vue-calendars';

Vue.use(Vuetify);
export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
    theme: {
      dark: false, // true - stellt alles um
    }
    // icons: {
    //   fx: "fx"
    // },
    // theme: {
    //   primary: colors.blue.darken4, // Test von Navigation bar
    //   secondary: colors.grey.darken1,
    //   accent: colors.blue.darken4, // Farbe von Checkboxen und Switchbutton
    //   error: colors.red.accent3
    // }
  // }
});
