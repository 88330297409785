








import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import myLog, { LogMessage, logType } from "@/log";

@Component
export default class extends Vue {
  private logMessages: LogMessage[] = [];
  public mounted() {
    this.logMessages = myLog.Current.logmessages.splice(-100);
  }

  private getCss(msg: LogMessage): any {
    if (msg.type === logType.Debug) {
      return "logDebug";
    }
    if (msg.type === logType.Warning) {
      return "logWarning";
    }
    if (msg.type === logType.Error) {
      return "logError";
    }
  }

  private getTS(msg: LogMessage) {
    if (msg.timestamp) {
      return msg.timestamp.toISOStringWithTZ();
    }
    return "???";
  }
}
